<div class="row filters-row" style="margin-top: 10px;">
    <!-- Company Id Filter -->
    <div class="col filters-col">
        <ng-multiselect-dropdown class="multiselect-dropdown" placeholder="{{'analytics.company'|  translate}}" [settings]="dropdownSettings"
            [data]="dropdownCompanies" [(ngModel)]="selectedCompanies" [ngModelOptions]="{standalone: true}"
            (onSelect)=onSelect(selectedCompanies) (onDeSelect)=onSelect(selectedCompanies)>
        </ng-multiselect-dropdown>
    </div>
     <!-- Date Filter -->
    <div class="col filters-col">
        <mat-form-field>
            <mat-label translate>analytics.date</mat-label>
            <input type="text" ngxDaterangepickerMd matInput 
         [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true" 
                [showClearButton]="false"  placeholder="Select please..."
                [autoApply]="false" [closeOnAutoApply]="true" [maxDate]="maxDate" [minDate]="minDate"
                [(ngModel)]="selectedDateRange" 
                [locale]="locale"
                (change)=onSelect(selectedDateRange) />
        </mat-form-field>
    </div>
</div>
<!-- analytics table -->
<div class="content">
    <div *ngFor="let item of allData">
        <mat-card class="analytics">
            <mat-card-title>{{item.company}}</mat-card-title>
            <div class="analytics-content">
                <ngx-charts-number-card [view]="view" [scheme]="colorScheme" [results]="item.data"
                    [cardColor]="cardColor" [valueFormatting]="ValueLabel">
                </ngx-charts-number-card>
            </div>
        </mat-card>
    </div>
</div>
<ng-container *ngIf="!allData ||  allData?.length == 0">
    <ngx-skeleton-loader count="3" appearance="line" [theme]="{'height.px': 150}"></ngx-skeleton-loader>
</ng-container>
