import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn, FormBuilder } from '@angular/forms';

// @Injectable({
//   providedIn: 'root'
// })
// export class ValidationAtLeastOneFieldService {

//   constructor() { }
// }

export const ValidationAtLeastOneFieldService = (validator: ValidatorFn, controls: string[] = null) => (
  group: FormGroup,
): ValidationErrors | null => {
  if (!controls) {
    controls = Object.keys(group.controls)
  }

  const hasAtLeastOne = group && group.controls && controls
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : {
    ValidationAtLeastOneFieldService: true,
  };
};