<div class="add-dialog">
    <div class="btn-close">
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <!-- <div class="select-logo">
      <h1>Select {{type === 'logo' ? 'Logo' : 'Images' }}</h1>
      <input
        class="file-input"
        type="file"
        (change)="fileChangeEvent($event)"
        accept="image/png, image/jpeg"
      />
    </div> -->
    <div class="file-upload">
      <div class="file-select">
        <div class="file-select-button" id="fileName">Select {{type === 'logo' ? 'Logo' : 'Images' }}</div>
        <div class="file-select-name" id="noFile">No file chosen...</div> 
        <input type="file"
        (change)="fileChangeEvent($event)"
        accept="image/png, image/jpeg">
      </div>
    </div>
    <div >
      <image-cropper
        class="image-crop"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="png"
        [resizeToWidth]="type === 'logo' ? '500' : '1000'"
        [resizeToHeight]="type === 'logo' ? '500' : '1000'"
        [cropperMinWidth]="type === 'logo' ? '500' : '1000'"
        [cropperMinHeight]="type === 'logo' ? '500' : '1000'"
        backgroundColor="#ffffff"
  
        [roundCropper]="type === 'logo' ? true : false"
        [containWithinAspectRatio]="type === 'logo' ? true : false"
        
        (imageCropped)="imageCropped($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  
    <!-- <div *ngIf="isImgError">
      <p class="text-danger">{{imgErrorMessage}}</p>
    </div> -->
  
  
    
    <button
      *ngIf="croppedImage"
      mat-raised-button
      color="primary"
      (click)="afterClosed($event)"
      class="btn-save"
      [disabled] = "isImgError"
    >
      Save
    </button>
  </div>
  