import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageRequest, Page } from 'ngx-pagination-data-source'
import { Observable } from 'rxjs'


export interface customQuery {
  search: string;
  need_action: string;
  radius_search: string;
  latitude: string;
  longitude: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService) { }


  get(url, params = {}, isPaginate = false) {
    if (isPaginate) {
      return this.http.get(url, params).pipe(
        catchError(this.handleError)
      );
    }

    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  put(url, data, httpOptions = {}) {
    return this.http.put(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getPdf(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  getApplier() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier/`, httpOptions);
  }

  getMatchList(applierId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`match/appliermatchlist/${applierId}`, httpOptions);
  }
  getMatchListByLatLng(lat,long) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/job-search?latitude=${lat}&longitude=${long}`, httpOptions);
  }

  applyFilter(filterValue = '', ordering = '') {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier/?search=${filterValue}&ordering=${ordering}`, httpOptions);
  }

  updateApplierDetails(id, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/applier/${id}/`, data, httpOptions);
  }

  sendMatches(matchId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`match/matchstatuslist/`, data, httpOptions);
  }

  updateNeedAction(data,agentId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/change-need-action/${agentId}/`, data, httpOptions);
  }

  downloadPdf(applierId) {
    let token = this.authService.getAuthToken();
    let httpOptions = new HttpHeaders({
      'Authorization': token
    });
    return this.getPdf(`match/download-pdf/${applierId}/download/`, { responseType: 'blob' as 'json', headers: httpOptions });
  }

 
  public handleError(errorRes: HttpErrorResponse) {
    console.log("errorRes", errorRes?.statusText)
    if (Object.keys(errorRes?.error)?.length > 0) {
      if(Object.keys(errorRes?.error)[0]?.length > 1 && errorRes?.statusText !== 'Unknown Error'){
        return throwError(Object.keys(errorRes.error)[0] + " " + Object.values(errorRes.error)[0]);
      }
    }
    let message = (errorRes?.statusText) ? errorRes?.statusText : 'etwas ist schief gelaufen!'
    return throwError(message);
  }

  page(request: PageRequest<any>, query: customQuery): Observable<Page<any>> {
    let httpOptions = this.authService.getHTTPOption();
    var ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString()
      if (request.sort.order == 'desc' && ordering !== '') {
        ordering = '-' + ordering;
      }
    } else {
      ordering = '';
    }
    
    const params = {
      params: { page: request.page + 1, 
        ordering: ordering,
        radius_search : (query?.radius_search=='')? "" : query?.radius_search?.toString(),
      latitude: (query?.latitude=='')? "" : query?.latitude?.toString(),
      longitude: (query?.longitude=='')? "" : query?.longitude?.toString(),
         ...query },
      ...httpOptions
    }
    var url = 'accounts/applier/';
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      map(res => {
        return {
          content: res['results'],
          number: request.page,
          size: res['results'].length,
          totalElements: res['count']
        }
      })
    )
  }
}

