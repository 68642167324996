<div *ngIf="applierData">
  <h1 class="drawer-title">{{applierData.name}}</h1>
  <form [formGroup]="applicantForm">
    <table>
      <tr>
        <td translate>home.col_name <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['name'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_education <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings"
            [data]="dropdownEducationList" [(ngModel)]="selectedEducationItems" [ngModelOptions]="{standalone: true}"
            formControlName="education" (onSelect)="checkEducationList()" (onDeSelect)="checkEducationList()">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedEducationItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr *ngIf="isEduOtherShow || selectedEducationItems.length  == 0">
        <td translate>home.education_other <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="education_other" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['education_other'].value}}</td>
      </tr>
      <tr *ngIf="isEduAssistantShow">
        <td translate>home.col_education_assistant <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownEducationSettings" [data]="additionalQuestionList" [(ngModel)]="selectedQuestionItems"
            [ngModelOptions]="{standalone: true}" formControlName="education_assistant">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedQuestionItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr>
        <td translate>home.col_care_type <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings"
            [data]="dropdownCareTypeList" [(ngModel)]="selectedCareTypeItems" [ngModelOptions]="{standalone: true}"
            formControlName="care_type">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedCareTypeItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr>
        <td translate>home.col_shift_hour <span
            *ngIf="(editMode && isShiftOtherItem) ||
          (editMode && ShiftOthersItems !== '' && selectedShiftHoursItems.length !== 0) || (editMode && ShiftOthersItems == '')"
            class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings"
            [data]="dropdownShiftHoursList" [(ngModel)]="selectedShiftHoursItems" [ngModelOptions]="{standalone: true}"
            formControlName="shift_hour" (onSelect)="checkShiftHour()" (onDeSelect)="checkShiftHour()">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedShiftHoursItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr>
        <td translate>home.col_shift_other <span *ngIf="(editMode && isShiftHoursItem)" class="is-validate">*</span>
        </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="shift_other" [(ngModel)]="ShiftOthersItems"
              (ngModelChange)="checkShiftOthers($event)" (focus)="checkShiftOthers($event)"
              (change)="checkShiftOthers($event)" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['shift_other'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_scope <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings" [data]="scopeList"
            [(ngModel)]="selectedScopeItems" [ngModelOptions]="{standalone: true}" formControlName="scope">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedScopeItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr>
        <td translate>home.col_last_2_employers</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="last_2_employers" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['last_2_employers'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_driving_license</td>
        <td *ngIf="editMode">
          <mat-button-toggle-group formControlName="driving_license">
            <mat-button-toggle mat-button value="{{item.item_id}}" *ngFor="let item of licenseList">{{item.item_text}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedDrivingLicanse">{{i.item_text}}</tag>
        </td>
      </tr>

      <tr>
        <td translate>home.col_vaccination_status<span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="vaccination_status">
            <mat-button-toggle value="{{item.item_id}}" *ngFor="let item of dropdownVaccinationTypeList">
              {{item.item_text}}</mat-button-toggle>
          </mat-button-toggle-group>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedVaccinationTypeList">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr *ngIf="applicantForm.controls['vaccination_status'].value == 3">
        <td translate>home.col_vaccination_date</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput [matDatepicker]="picker" formControlName="vaccination_date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['vaccination_date'].value}}</td>
      </tr>


      <tr>
        <td translate>home.col_email <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="email" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['email'].value}}</td>
      </tr>

      <tr>
        <td translate>home.col_phone_number <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="phone_number" />
            <a matSuffix href="https://wa.me/{{applicantForm.controls['phone_number'].value.replaceAll(' ', '')}}?text={{encode(applicantForm.controls['name'].value)}}"
              target="_blank">
              <mat-icon>whatsapp</mat-icon>
            </a>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['phone_number'].value}}
          <a matSuffix style="margin-left: 25px;" href="https://wa.me/{{applicantForm.controls['phone_number'].value.replaceAll(' ', '')}}?text={{encode(applicantForm.controls['name'].value)}}"
          target="_blank">
          <mat-icon style="font-size: 20px;">whatsapp</mat-icon>
        </a></td>
      </tr>

      <tr>
        <td translate>home.col_postcode <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" (ngModelChange)="checkPostCode($event)" (focus)="checkPostCode($event)"
              (change)="checkPostCode($event)" formControlName="postcode" />
            <mat-hint class="postcode-invalid"
              *ngIf="isPostcodeValid && applicantForm.controls['postcode'].value !== ''">
              <p translate>message.postcode_invalid</p>
            </mat-hint>
          </mat-form-field>
          <!-- <mat-error *ngIf="isPostcodeValid && applicantForm.controls['postcode'].value !== ''" style="margin-top: -10px;">
            <p>Die Postleitzahl gibt es in Deutschland nicht</p>
          </mat-error> -->
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['postcode'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_start_date <span *ngIf="editMode" class="is-validate">*</span></td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput [matDatepicker]="picker" formControlName="start_date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['start_date'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_continuing_education </td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown formControlName="continuing_education" class="multiselect-dropdown"
          placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings" [data]="continuingEducation"
            [(ngModel)]="selectedContinuingEducation" [ngModelOptions]="{standalone: true}" (onSelect)="checkContiEducationList()" (onDeSelect)="checkContiEducationList()">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedContinuingEducation">{{i.item_text}}</tag>
        </td>
      </tr>
      
      <tr *ngIf="isContiEduShow" >
        <td translate>home.further_training </td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="continous_education_other" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['education_other'].value}}</td>
      </tr>
      <tr>
        <td translate>home.col_reference_by</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown #multiSelect (onSelect)="checkShowReferenceOther()"
            (onDeSelect)="checkShowReferenceOther()" placeholder="{{ 'home.not_selected' | translate }}" [settings]="dropdownSettings"
            [data]="referencrList" [(ngModel)]="selectedReferencrByItems" [ngModelOptions]="{standalone: true}">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedReferencrByItems">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr *ngIf="showOtherRef">
        <td translate>home.col_other</td>
        <td *ngIf="editMode">
          <mat-form-field appearance="legacy" class="drawer-mat-field">
            <input matInput type="text" formControlName="others" />
          </mat-form-field>
        </td>
        <td *ngIf="!editMode">
          {{applicantForm.controls['others'].value}}</td>
      </tr>

      <tr *ngIf="token">
        <td translate>home.col_token</td>
        <td style="height: 40px;padding:10px 0px">
          <div [cdkCopyToClipboard]="token" class="copy-paste">
            <span>{{token}}</span>
            <mat-icon matSuffix title="{{ 'home.col_token' | translate}}">
              content_copy</mat-icon>
          </div>
        </td>
      </tr>

      <tr *ngIf="extID">
        <td translate>home.extID_token</td>
        <td style="height: 40px;padding:10px 0px">
          <div [cdkCopyToClipboard]="extID" class="copy-paste">
            <span>{{extID}}</span>
            <mat-icon matSuffix title="{{ 'home.extID_token' | translate}}">
              content_copy</mat-icon>
          </div>
        </td>
      </tr>

      <tr>
        <td translate>home.col_agent_user</td>
        <td *ngIf="editMode">
          <ng-multiselect-dropdown formControlName="agent_user" class="multiselect-dropdown" placeholder="{{ 'home.not_selected' | translate }}"
            [settings]="dropdownEducationSettings" [data]="dropdownAgents" [(ngModel)]="selectedAgent"
            [ngModelOptions]="{standalone: true}">
          </ng-multiselect-dropdown>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedAgent">{{i.item_text}}</tag>
        </td>
      </tr>
      <tr *ngIf="companyId && jobId">
        <td translate>Beworbene Versorgung</td>
        <td *ngIf="editMode">
          <!-- <button mat-flat-button class="drawer-btn-save" style="margin-bottom: 15px;" ><a target="_blank" routerLink="/jobs/{{companyId}}/{{jobId}}/0/0"
            translate>Zur Versorgung</a></button> -->
          <a mat-flat-button class="drawer-btn-save" target="_blank" style="margin-bottom: 15px;margin-left: -10px;"
            routerLink="/jobs/{{companyId}}/{{jobId}}/0/0"><span translate>Zur Versorgung</span></a>
        </td>
        <td *ngIf="!editMode">
          <tag *ngFor="let i of selectedAgent">{{i.item_text}}</tag>
        </td>
      </tr>

    </table>
  </form>

  <!-- <footer> -->
  <div *ngIf="editMode" class="footer" #footer>
    <button [disabled]="applicantForm.invalid" mat-flat-button color="primary" style="margin-bottom: 15px;"
      (click)="updateData(true)" class="drawer-btn-save"> <span translate>home.btn_save_and_close</span> </button>
    <button [disabled]="applicantForm.invalid" mat-flat-button color="primary" style="margin-bottom: 15px;"
      (click)="updateData()" class="drawer-btn-save"><span translate>home.btn_save</span></button>

    <button mat-flat-button class="drawer-btn-save" style="margin-bottom: 15px;" (click)="close()"><span
        translate>home.btn_close</span></button>
  </div>
  <!-- </footer> -->
</div>
<ng-container *ngIf="!applierData">
  <ngx-skeleton-loader count="2" appearance="line" [theme]="{'height.px': 100}"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="5" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
</ng-container>
