import { Pipe, PipeTransform } from '@angular/core';
import { AbbreviationModel } from '../models/abbreviation.model';

@Pipe({
  name: 'ShiftAbbreviations'
})
export class AbbreviationsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'shiftHours', 'short');

  }
}


@Pipe({
  name: 'CareTypeAbbreviations'
})
export class CareTypeAbbreviationsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'careType', 'short');

  }
}


@Pipe({
  name: 'EducationAssistentAbbreviations'
})
export class EducationAssistentAbbreviationsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'eduAssistance', 'short');

  }
}

@Pipe({
  name: 'ScopeAbbreviations'
})
export class ScopeAbbreviationsPipe implements PipeTransform {

  transform(value: unknown[], ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'scope', 'short');

  }
}

@Pipe({
  name: 'SpecialRequirementsById'
})
export class SpecialRequirementsPipeById implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'specialJobType', 'short');
  }
}

@Pipe({
  name: 'SpecialCareTypeById'
})
export class SpecialCareTypeById implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'specification', 'short');
  }
}


@Pipe({
  name: 'EducationForJob'
})
export class EducationForJobPipeById implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'educationType', 'short');
  }
}

@Pipe({
  name: 'HistoryMatchChannelAbbreviations'
})
export class HistoryMatchChannelAbbreviationsById implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    // return new AbbreviationModel().getHistoryMatchChannelById(value);
    return new AbbreviationModel().getById(value,'historyMatch', 'short'); 
  }
}


@Pipe({
  name: 'ContinuingEducations'
})
export class ContinuingEducations implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'continuingEdu.', 'short');

  }
}

@Pipe({
  name: 'ReferenceBy'
})
export class ReferenceBy implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    return new AbbreviationModel().getById(value,'reference', 'short');

  }
}
