import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/services/applicant.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { AbbreviationModel } from '../../../models/abbreviation.model';
import { TranslateService } from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  CompanySidebarComponent,
  DialogData,
} from '../../common/company-sidebar/companysidebar.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { encode } from 'querystring';
import { SendWhatsappDialogComponent } from '../../common/send-whatsapp-dialog/send-whatsapp-dialog.component';

@Component({
  selector: 'app-expandable-row',
  templateUrl: './expandable-row.component.html',
  styleUrls: ['./expandable-row.component.scss'],
})
export class ExpandableRowComponent implements OnInit {
  @Input('applierId') applierId: any;
  @Input('lat') lat: any;
  @Input('lng') lng: any;

  INNER_ELEMENT_DATA = [];
  innerDataSource: any = new MatTableDataSource(this.INNER_ELEMENT_DATA);
  selection = new SelectionModel(true, []);
  hover = new SelectionModel(true, []);
  innerColumnsToDisplay = [
    'select',
    'companyname',
    'education',
    'continuing_education',
    'caretype',
    'shift',
    'scope',
    'special_requirements',
    'distanz',
    'action',
  ];
  isSpinner = false;
  markers = [];
  notes: string;
  isSendByEmail: true;
  buttonDisable: boolean = false;
  note_bool: false;
  forwardToCompany: boolean;
  showMap: boolean = false;
  checkStatus: number = 2;
  applier_id: number;
  returnValue: any;
  statusForm: FormGroup;
  applier_phone: string;
  matchStatusTypeList = [
    { id: 2, name: 'Matches versenden (Mail)' },
    { id: 6, name: 'Matches versenden (Whatsapp)' },
    { id: 5, name: 'An Unternehmen weiterleiten' },
    // { id: 7, name: 'Bewerber absagen (whatsapp)' },
    // { id: 8, name: 'Bewerber absagen (mail)' },
    
  ];

  constructor(
    private applicantService: ApplicantService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.forwardToCompany = false;
    this.statusForm = new FormGroup({
      checkStatus: new FormControl(''),
    });

    this.statusForm.patchValue({ checkStatus: '2' });
  }

  ngOnChanges() {
    if (this.applierId) {
      this.isSpinner = true;
      this.loadMatches();
    }
  }

  loadMatches() {
        this.isSpinner = true;
    this.innerDataSource = new MatTableDataSource();
    this.selection.clear();
    this.applicantService
      .getMatchList(this.applierId)
      .subscribe((response: any) => {
        this.applier_phone = response[0]?.applier?.phone_number;
        this.applier_id = response[0]?.applier?.id;

        for (let result of response) {
          let care_type = result.match_care_type.filter(function (n) {
            return result.job.care_type.indexOf(n) !== -1;
          });
          let working_type = result.job.working_type.filter(function (n) {
            return result.applier?.working_type?.indexOf(n) !== -1;
          });
          if (
            result.match_shift &&
            care_type.length != 0 &&
            working_type.length != 0 &&
            result.distance <= 25
          ) {
            this.selection.select(result.id);
          }
        }
        this.innerDataSource = new MatTableDataSource(response);

        this.isSpinner = false;
      });
  }

  selectRow(id) {
    this.selection.toggle(id);
  }

  getMatchStatus(status) {
    return new AbbreviationModel().getById(status,'matchStatus', 'short');
  }

  updateColor(id) {
    this.hover.clear();
    this.hover.select(id);
  }

  updateColorR(id) {
    this.hover.clear();
  }

  getMarkerUrl(id) {
    if (this.hover.isSelected(id)) {
      return this.getMarker('bfbfbf');
    } else if (this.selection.isSelected(id)) {
      return this.getMarker('ff4081');
    } else {
      return this.getMarker('a7a7a7');
    }
  }

  getMarker(hash) {
    return `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${hash}`;
  }

  sendMatches() {
    
    this.buttonDisable = true;
    this.forwardToCompany =
      +this.statusForm.controls['checkStatus'].value == 5 ? true : false;

    let ids = this.selection.selected;
    
    let postData = {
      match_ids: (+this.statusForm.controls['checkStatus'].value === 7 || +this.statusForm.controls['checkStatus'].value === 8) ? [] : ids,
      status: this.statusForm.controls['checkStatus'].value
      ? +this.statusForm.controls['checkStatus'].value
      : 2,
      // status: (+this.statusForm.controls['checkStatus'].value === 7) ? null : this.statusForm.controls['checkStatus'].value,
      note: this.notes === undefined ? '' : this.notes,
      note_bool:
      this.note_bool === undefined ||
      +this.statusForm.controls['checkStatus'].value == 6 ||
      +this.statusForm.controls['checkStatus'].value == 7 ||
      +this.statusForm.controls['checkStatus'].value == 8 
      ? false
      : this.note_bool,
      applier_id : (+this.statusForm.controls['checkStatus'].value === 7 || 
                  +this.statusForm.controls['checkStatus'].value == 8 ) ? this.applier_id : null ,
      channel : (+this.statusForm.controls['checkStatus'].value === 7) ? "whatsapp" : 
      (+this.statusForm.controls['checkStatus'].value === 8 ? "mail" : "")
    };
        this.applicantService.sendMatches(this.applierId, postData).subscribe(
      (res) => {
        
        const snakMessage =
          +this.statusForm.controls['checkStatus'].value === 6
            ? this.translate.instant('message.copied_to_clipboard')
            : this.translate.instant('message.email_sent');
            this.returnValue = "";
        if (+this.statusForm.controls['checkStatus'].value === 6 || +this.statusForm.controls['checkStatus'].value === 7) {
          this.returnValue = res['output'];
          this.addCompanyDialog();
          this.clipboard.copy(this.returnValue);
        }
        this._snackBar.open(snakMessage, '', {
          duration: 5000,
        });

        this.notes = '';
        this.getMatchList();
        this.buttonDisable = false;
      },
      (error) => {
        if (error !== null && error !== 'undefined') {
          this._snackBar.open(error.replace('message', ''), '', {
            duration: 5000,
          });
        } else {
          this._snackBar.open(
            this.translate.instant('message.something_wrong'),
            '',
            { duration: 5000 }
          );
        }
        this.buttonDisable = false;
      }
    );
  }

  getMatchList() {
    this.applicantService
      .getMatchList(this.applierId)
      .subscribe((response: any) => {
        this.innerDataSource = new MatTableDataSource(response);
        this.isSpinner = false;
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.innerDataSource.data = this.innerDataSource.data;
    let ids = [];
    this.innerDataSource.data.map((row) => {
      if (row.id) ids.push(row.id);
    });
    let selected = this.selection.selected;
    let selected_ids = ids.filter(function (id) {
      return selected.includes(id);
    });
    this.selection = new SelectionModel(true, selected_ids);
  }

  addCompanyDialog() {

    const dialogRef = this.dialog.open(SendWhatsappDialogComponent, {
      data: {
        returnValue: this.returnValue,
        applier_phone: this.applier_phone,
        checkStatus: this.statusForm.controls['checkStatus'].value
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

export interface matchResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}


  




