<div *ngIf="applierId" class="bar">
    <form style="margin-top:50px" [formGroup]="historyForm" (ngSubmit)="submitHistory()">
        <!--[formGroup]="historyForm" (ngSubmit)="submitHistory()"-->
        <!--Content field-->

        <mat-form-field style="width:68%">
            <mat-label>Kurzbeschreibung</mat-label>
            <input matInput formControlName="content">
        </mat-form-field>
        <mat-form-field style="width:28%">
            <mat-label translate>history.costum_date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="costum_Date" />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!--Channel field-->
        <mat-form-field style="width:27.5%">
            <mat-label>Kanal</mat-label>
            <mat-select formControlName="channel" class="channel">
                <!--Add this when formgroup is entered-->
                <mat-select-trigger class="mat-select-trigger-match">
                    <mat-icon class="selected-icon-match">{{icn}}</mat-icon>&nbsp;{{chnlnm}}
                </mat-select-trigger>
                <mat-option (onSelectionChange)="changeIconName(chnl.icon, chnl?.name)" *ngFor="let chnl of channels"
                    [value]="chnl.id">
                    <mat-icon>{{chnl.icon}}</mat-icon>
                    {{ chnl.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--Status get from backend-->
        <mat-form-field style="width:27.5%">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status_id">
                <mat-option *ngFor="let stati of status" [value]="stati.id"
                    (click)="updateDate(stati?.due_days,$event)">
                    {{stati?.status_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!--due date-->
        <mat-form-field style="width:27.5%">
            <mat-label>nächste Aktion</mat-label>
            <input matInput formControlName="due_date" [matDatepicker]="picker2" [disabled]="!isDueDate" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary">{{ 'match-history.btn_send' | translate}}</button>
    </form>
    <mat-divider></mat-divider>
    
<app-add-label [matchId]="matchId"></app-add-label>


    <mat-divider></mat-divider>
    <div style="height:8px"></div>
    <mat-card *ngFor="let item of historyData">
        <div class="card-image">
            <mat-icon>{{ item?.channel | HistoryMatchChannelAbbreviations }}</mat-icon>
        </div>
        <div class="content">
            <mat-card-subtitle class="less-margin">
                <span matTooltip="erstellt von">{{ item?.agent_user?.first_name }} {{ item?.agent_user?.last_name
                    }}</span> | <span matTooltip="erstellt am">{{ item?.created_at | date:'dd.MM.yyyy'}}</span> | <span
                    matTooltip="Ablaufdatum">{{ item.due_date | date:'dd.MM.yyyy' }}</span>
            </mat-card-subtitle>
            <mat-card-content class="less-margin">
                {{item?.status?.status_name}} <ng-container *ngIf="item.costum_date">({{item.costum_date | date:'dd.MM.yyyy'}})</ng-container>
            </mat-card-content>
            <mat-card-subtitle class="less-margin">
                {{ item?.content }}
            </mat-card-subtitle>
        </div>

        <div class="card-image right" >
            <div style="display: flex;">
            <mat-icon style="margin-right: 0px;" matTooltip="{{item?.status?.whatsapp_tooltip}}" 
            (click)="sendMatches(7, item.applier[0].applier_id, item?.applier[0]?.applier__phone_number, item?.status?.id)">whatsapp</mat-icon>
            <mat-icon style="margin-right: 0px;" matTooltip="{{item?.status?.mail_tooltip}}" 
            (click)="sendMatches(8, item.applier[0].applier_id, item?.applier[0]?.applier__phone_number , item?.status?.id)">email</mat-icon>
            <mat-icon [matMenuTriggerFor]="menu">delete</mat-icon>
            </div>
            <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                <!--add delete functionality-->
                <button (click)="delete(item.id)" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Löschen</span>
                </button>
            </mat-menu>
        </div>
    </mat-card>
    <ng-container *ngIf="showSpinner">
        <ngx-skeleton-loader count="7" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
    </ng-container>
    <ng-container *ngIf="historyData.length==0">
        <h3 class="drawer-title" style="text-align:center;margin:20px">{{ 'match-history.no_record' | translate}}</h3>
    </ng-container>
</div>
