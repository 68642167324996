<div style="margin: 20px 10px 20px 5px; display: flex;">
  
  <div style="width: 80%;">

    <ng-multiselect-dropdown (onSelect)="queryBy(selectLable)"
        (onDeSelect)="queryBy(selectLable)" class="multiselect-dropdown" placeholder="Label"
        [settings]="dropdownSettings" [data]="labelData" [(ngModel)]="selectLable"
        [ngModelOptions]="{standalone: true}">
      </ng-multiselect-dropdown>
  </div>

  <div style="width: 20%;" *ngIf="checkLabelChange">
    <button mat-flat-button color="primary" style="float: right" (click)="updatelabel()" [disabled]="checkValueChange">Speichern</button>
  </div>
</div>
