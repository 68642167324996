import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageRequest, Page } from 'ngx-pagination-data-source'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class HistoryService {

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  put(url, data, httpOptions = {}) {
    return this.http.put(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  delete(url, httpOptions = {}) {
    return this.http.delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions).pipe(
      catchError(this.handleError)
    );
  }


  public handleError(errorRes: HttpErrorResponse) {
    console.log("errorRes", errorRes?.statusText)
    if (Object.keys(errorRes?.error)?.length > 0) {
      if(Object.keys(errorRes?.error)[0]?.length > 1 && errorRes?.statusText !== 'Unknown Error'){
        return throwError(Object.keys(errorRes.error)[0] + " " + Object.values(errorRes.error)[0]);
      }
    }
    let message = (errorRes?.statusText) ? errorRes?.statusText : 'etwas ist schief gelaufen!'
    return throwError(message);
  }

  addHistoryData(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/statuslog/`, data, httpOptions);
  }

  getHistoryData(applierId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/statuslog/?applier_id=${applierId}`,  httpOptions);
  }

  getHistoryStatus() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/status/`,  httpOptions);
  }

  getHistoryChannel() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/channel/`,  httpOptions);
  }

  deleteHistory(Id) {
    let httpOptions = this.authService.getHTTPOption();
    return this.delete(`accounts/statuslog/${Id}`,  httpOptions);
  }
}

