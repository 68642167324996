import { Component, NgModule, OnInit } from '@angular/core';
import moment from 'moment';
import { NumberChart } from './numberChart';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { JobService } from 'src/app/services/job.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DateAdapter } from '@angular/material/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  dropdownSettings: IDropdownSettings;
  NumberChart: any[];
  view: any[] = [700, 200];
  allData:any[];
  dropdownCompanies: any[];
  selectedCompanies: any[];
  data: any;
  compIds: any;
  selectedDateRange: any = { startDate: moment().subtract(6, 'days'), endDate: moment() };

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  cardColor: string = '#b1d7d4';
  selected: any;
  alwaysShowCalendars: boolean;
  minDate: any = moment('09/01/2021');
  maxDate: any = moment();
  locale: any = {
    applyLabel: 'OK', // detault is 'Apply'
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
  ],
  customRangeLabel: "Zeitspanne",
  format: "DD.MM.YYYY"
  }
  ranges: any = {
    'Heute': [moment(), moment()],
    'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  }
  invalidDates: moment.Moment[] = [];


  constructor(private jobService: JobService, private router: Router,private analyticsService: AnalyticsService) {
    Object.assign(this, { NumberChart });
    this.alwaysShowCalendars = true;
  }
  
  ngOnInit(): void {
    if (localStorage.getItem("agentData") === null && localStorage.getItem("companyData") === null) {
      this.router.navigate(['login']);
    } 
    this.getCompanyUser();
    this.onSelect('');
    // this.aggregateTotal();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true
    };
    
  }
  // Selected Date and CompanyID
  onSelect(e) {
      var startdt = new Date(this.selectedDateRange?.startDate?._d);
      var enddt = new Date(this.selectedDateRange?.endDate?._d);
      var compIds = this.selectedCompanies
      ? this.selectedCompanies.map((x1) => x1.item_id) : ''
      
      this.data = {
        company_ids: compIds.toString(),
        start_date: this.selectedDateRange?.startDate?._d === undefined ? "" : startdt.getFullYear() + '-' + ((startdt || new Date()).getMonth() + 1) + '-' + startdt.getDate(),
        end_date: this.selectedDateRange?.endDate?._d === undefined ? "" : enddt.getFullYear() + '-' + ((enddt || new Date()).getMonth() + 1) + '-' + enddt.getDate()
    }
    if( (this.data?.company_ids != '' ) || (this.data?.startdt != '' || this.data?.enddt != '' )){
      this.allData = [];
      this.aggregateTotal();
    }
  }
  

  ValueLabel(data) {
    if (data.data.name == "Einstellungsquote" && !String(data.data.value).includes('%')) {
      return data.data.value = data.data.value + ' %';
    }
    return data.data.value;
  }
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  // Get analystics Data
  aggregateTotal() {
    this.analyticsService.doAnalytics(this.data).subscribe((res:any) => {
      var totalItem = { company: "Alle / selektierte Unternehmen", data: [{ "name": "Weiterleitungen", "value": 0 }, { "name": "Einstellungen", "value": 0 }, { "name": "Einstellungsquote", "value": 0 }] };
      var totalSum = [0, 0, 0, 0];
      res.analytics.forEach(function (a) {
        
        totalSum[0] += a.data[0].value;
        totalSum[1] += a.data[1].value;
        totalSum[2] += a.data[2].value;
        totalSum[3] += 1;
      });
      totalItem.data[0].value = totalSum[0];
      totalItem.data[1].value = totalSum[1];      
      totalItem.data[2].value = (totalSum[2] == 0 && totalSum[3] == 0) || (totalSum[2] != 0 && totalSum[3] == 0) ? 0 : +((totalSum[2] / totalSum[3]).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.allData = [totalItem, ...res.analytics];
    })
  }

  getCompanyUser() {
    this.jobService.getCompanies().subscribe((res: any) => {
      let Companies = [];
      res.forEach(company =>
        Companies.push(
          { item_id: company.id, item_text: company.name }
        )
      )
      this.dropdownCompanies = Companies;
    });
  }
}
