import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, tap, map } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { PageRequest, Page } from 'ngx-pagination-data-source';
import { Observable } from 'rxjs';

export interface customQuery {
  companies: string;
  search: string;
}

export interface matchQuery {
  companies: string;
  search: string;
  status: string
  need_action: string;
  company_ids: string;
  start_date: string;
  end_date: string;
}

export interface customQueryV2 {
  companies: string | {};
  search: string;
  shift: string | {};
  care_type: string | {};
  agent_user: string | {};
  working_type: string | {};
  education_assistant: string | {};
  education_type: string | {};
  continuing_education: string | {};
  active: string | {};
  radius_search: string | {};
  latitude: string | {};
  longitude: string | {};
}

export interface postingQuery {
  company_ids: string | {};
  pincode: string;
  care_type: string | {};
  posting_templates: string | {};
  budget: string | {};
  start_date: string;
  end_date: string;
}

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}

  get(url, params = {}) {
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(catchError(this.handleError));
  }

  post(url, data, httpOptions = {}) {
    return this.http
      .post(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  put(url, data, httpOptions = {}) {
    return this.http
      .put(
        `${environment.BASE_URL}${environment.PATH}${url}`,
        data,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getCompanies() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company/`, httpOptions);
  }

  getCompaniesByName(companyName, page) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company/?search=${companyName}&page=${page}&pagination=True`, httpOptions);
  }

  getAgent(showOwnLogin) {
    let httpOptions = this.authService.getHTTPOption();
    // return this.get(`accounts/company/`, httpOptions);
    return this.get(
      `accounts/user?role=2&matchdropdown=${showOwnLogin}`,
      httpOptions
    );
  }

  getCompaniesByCompanyUser() {
    const sesstionResult = JSON.parse(localStorage.getItem('companyData'));
    let httpOptions = this.authService.getHTTPOption();
    return this.get(
      `accounts/company?company_id=${sesstionResult?.company_id}`,
      httpOptions
    );
  }

  getUsers() {
    let httpOptions = this.authService.getHTTPOption();
    //TODO: Add role in .env and take it from there
    return this.get(`accounts/user?role=3`, httpOptions);
  }

  getUsersForCompany() {
    const sesstionResult = JSON.parse(localStorage?.getItem('companyData'));
    let httpOptions = this.authService.getHTTPOption();
    //TODO: Add role in .env and take it from there
    return this.get(`accounts/user?role=3`, httpOptions);
    // return this.get(`accounts/user?role=3&company_id=${sesstionResult?.company_id}`, httpOptions);
  }

  getCompanyDetails(companyId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company/${companyId}/`, httpOptions);
  }

  getJobs(companyId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/jobfilter-by-company/${companyId}/`, httpOptions);
  }

  getJobDetails(jobId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`jobs/job/${jobId}/`, httpOptions);
  }

  getApplierDetails(applierId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/applier/${applierId}/`, httpOptions);
  }

  getCompanyPresentation(companyId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company-update/${companyId}/`, httpOptions);
  }

  updateJobDetails(jobId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`jobs/job/${jobId}/`, data, httpOptions);
  }

  updateCompanyDetails(companyId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/company/${companyId}/`, data, httpOptions);
  }

  updateCompanyDetailsV2(companyId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/company/${companyId}/`, data, httpOptions);
    // return this.put(`accounts/companyuser/${companyId}/`, data, httpOptions);
  }

  updateContactUserStatus(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/change-contact-user/`, data, httpOptions);
  }

  updateCompanyPresentation(companyId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`accounts/company-update/${companyId}/`, data, httpOptions);
  }

  addCompanyDetails(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/company/`, data, httpOptions);
  }
  addCompanyDetailsV2(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/companyuser/`, data, httpOptions);
  }

  createUser(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/user/`, data, httpOptions);
  }

  addJobDetails(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`jobs/job/`, data, httpOptions);
  }

  addImages(data){
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/company_image_upload/`, data, httpOptions);
  }

  getLoginUserDetails(userId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/user/${userId}?dropdown=false/`, httpOptions);
    // return this.get(`accounts/user/${userId}`, httpOptions);
  }

  updateUser(userId, data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(
      `accounts/user/${userId}/?dropdown=false/`,
      data,
      httpOptions
    );
  }

  updateJobStatus(data, jobId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`jobs/job-status/${jobId}/`, data, httpOptions);
  }

  public handleError(errorRes: HttpErrorResponse) {
    console.log('errorRes', errorRes?.statusText);
    if (Object.keys(errorRes?.error)?.length > 0) {
      if (
        Object.keys(errorRes?.error)[0]?.length > 1 &&
        errorRes?.statusText !== 'Unknown Error'
      ) {
        return throwError(
          Object.keys(errorRes.error)[0] +
            ' ' +
            Object.values(errorRes.error)[0]
        );
      }
    }
    let message = errorRes?.statusText
      ? errorRes?.statusText
      : 'etwas ist schief gelaufen!';
    return throwError(message);
  }
  match(request: PageRequest<any>, query: matchQuery): Observable<Page<any>> {
    let httpOptions = this.authService.getHTTPOption();
    var ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }
   const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        agent_ids: query?.companies.toString(),
        search: query?.search,
        status_id: query?.status.toString(),
        need_action: query?.need_action == "true" ? "True" : "False" ,
        company_ids: query?.company_ids.toString(),
        start_date: query?.start_date.toString(),
        end_date: query?.end_date.toString(),
      },
      ...httpOptions,
    };
    
    var url = `match/matching`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map((res) => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  pageV2(
    request: PageRequest<any>,
    query: customQueryV2
  ): Observable<Page<any>> {
    let httpOptions = this.authService.getHTTPOption();
    var ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        search: query?.search,
        company_ids: query?.companies.toString(),
        shift:
          query?.shift == '' || query?.shift == undefined
            ? ''
            : query?.shift?.toString(),
        care_type: query?.care_type == '' ? '' : query?.care_type?.toString(),
        agent_user:
          query?.agent_user == '' ? '' : query?.agent_user?.toString(),
        working_type:
          query?.working_type == '' ? '' : query?.working_type?.toString(),
        education_assistant:
          query?.education_assistant == ''
            ? ''
            : query?.education_assistant?.toString(),
        education_type:
          query?.education_type == '' ? '' : query?.education_type?.toString(),
        continuing_education:
          query?.continuing_education == ''
            ? ''
            : query?.continuing_education?.toString(),
        active: query?.active == '' ? '' : query?.active?.toString(),
        radius_search:
          query?.radius_search == '' ? '' : query?.radius_search?.toString(),
        latitude: query?.latitude == '' ? '' : query?.latitude?.toString(),
        longitude: query?.longitude == '' ? '' : query?.longitude?.toString(),
      },
      ...httpOptions,
    };

    var url = `jobs/job`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map((res) => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  posting(
    request: PageRequest<any>,
    query: postingQuery
  ): Observable<Page<any>> {
    let httpOptions = this.authService.getHTTPOption();
    var ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        pincode: query?.pincode,
        company_ids: query?.company_ids.toString(),
        care_types: query?.care_type == '' ? '' : query?.care_type?.toString(),
        budget: query?.budget == '' ? '' : query?.budget?.toString(),
        posting_templates:
          query?.posting_templates == ''
            ? ''
            : query?.posting_templates?.toString(),
        start_date: query?.start_date.toString(),
        end_date: query?.end_date.toString(),
      },
      ...httpOptions,
    };
    var url = `jobs/posting`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map((res) => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }

  page(request: PageRequest<any>, query: customQuery): Observable<Page<any>> {
    let httpOptions = this.authService.getHTTPOption();
    var ordering = '';
    if (request.sort.order.length > 0) {
      ordering = request.sort.property.toString();
      if (request.sort.order == 'desc' && ordering != '') {
        ordering = '-' + ordering;
      }
    }

    const params = {
      params: {
        page: request.page + 1,
        ordering: ordering,
        company_ids: query?.companies.toString(),
        shift: '',
        care_type: '',
        agent_user: '',
        working_type: '',
        education_assistant: '',
        education_type: '',
        search: query?.search,
      },
      ...httpOptions,
    };
    var url = `jobs/job`;
    return this.http
      .get(`${environment.BASE_URL}${environment.PATH}${url}`, params)
      .pipe(
        map((res) => {
          return {
            content: res['results'],
            number: request.page,
            size: res['results'].length,
            totalElements: res['count'],
          };
        })
      );
  }
  delete(url, httpOptions = {}) {
    return this.http
      .delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getJobDelete(jobId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.delete(`jobs/job/${jobId}/`, httpOptions);
  }

  getCompanyUser(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`accounts/get-company-user/`, data, httpOptions);
  }

  getCompanyImage(companyId, imageType) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`accounts/company_image_upload/?company_id=${companyId}&picture_type=${imageType}`, httpOptions);
  }

  deleteImage(companyId: number, imageName: string, imageType: string ){
    let httpOptions = this.authService.getHTTPOption();
    return this.delete(`accounts/company_image_upload/?company_id=${companyId}&image=${imageName}&picture_type=${imageType}`, httpOptions);
  }
}
