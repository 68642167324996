import {
  Component,
  Input,
  EventEmitter,
  ViewChild,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { JobService } from 'src/app/services/job.service';
import { Subscription } from 'rxjs';
import {
  ActivatedRoute,
  Router,
  RouterEvent,
  NavigationEnd,
} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location, NumberSymbol } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import moment from 'moment';
import { ExcelExportDialogComponent } from '../common/excel-export-dialog/excel-export-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MatchComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  companies = [];
  tabId: any;
  isCopy: number;
  isJobUpdated: any;
  currentUrl: string;
  previousUrl: string;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isApplier = false;
  parentCompanyId: 0;
  dropdownCompanies = [];
  companiesList = [];
  status: any = [];
  selectedCompanies = [];
  selectedCities = [];
  ShowFilter: boolean = false;
  selectedDateRange: any; // = { startDate: moment().subtract(6, 'days'), endDate: moment() };
  // sidebar and tab stuff
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // add the datasource
  sorting_order: null;
  search: null;
  match: any;
  curentPage: 0;
  companyId: number;
  jobId: number;
  id: number;
  columnsAll = [
    { key: 'applier_name', name: 'Name des Antragstellers' },
    { key: 'date', name: 'Erstellt' },
    { key: 'company_name', name: 'Unternehmensname' },
    { key: 'caretype', name: 'Pflegeart' },
    { key: 'location', name: 'Stadt' },
    { key: 'agent_name', name: 'Kundenbetreuer' },
    { key: 'account_manager', name: 'Bewerberbetreuer' },
    { key: 'status', name: 'Status' },
    { key: 'created', name: 'Erstellt' },
    { key: 'content', name: 'content' },
    { key: 'action', name: 'Actionsarea' },
  ];
  columnsToDisplay = [
    'applier_name',
    'date',
    'company_name',
    'caretype',
    'location',
    'agent_name',
    'account_manager',
    'status',
    'created',
    'content',
    'action',
  ];
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.match(request, query),
    { property: 'forwarding_date' as keyof Sortable, order: 'desc' },
    {
      companies: '',
      search: '',
      status: '',
      need_action: '',
      company_ids: '',
      start_date: '',
      end_date: '',
    },
    2
  );
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dropdownAgents = [];
  selectedAgent = [];
  needActionChecked = false;
  applierId: number;
  data: any;
  allData: any[];
  minDate: any = moment('2021-09-01T00:00:00-06:00');
  maxDate: any = moment();
  locale: any = {
    applyLabel: 'OK', // detault is 'Apply'
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    customRangeLabel: 'Zeitspanne',
    format: 'DD.MM.YYYY',
  };
  ranges: any = {
    Heute: [moment(), moment()],
    Gestern: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  invalidDates: moment.Moment[] = [];

  constructor(
    private jobService: JobService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private historyMatchService: HistoryMatchService,
    public dialog: MatDialog
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.applierId = +params['applierId'];
        this.companyId = +params['companyId'];
        this.jobId = +params['jobId'];
        this.id = +params['id'];
        this.tabId = +params['tabId'];
      })
    );

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };

    this.getAgentList();
    this.getCompanyUser();
    this.getStatus();
    const sesstionResult = JSON.parse(localStorage.getItem('agentData'));
    this.selectedAgent.push({
      item_id: sesstionResult.id,
      item_text: sesstionResult.first_name + ' ' + sesstionResult.last_name,
    });
    this.dataSource.queryBy({ companies: this.selectedAgent[0].item_id });
  }

  ngAfterViewInit() {
    this.companyId && this.applierId && this.jobId && this.id && this.tabId
      ? this.edit(
          this.companyId,
          this.applierId,
          this.jobId,
          this.id,
          this.tabId
        )
      : '';
    this.cdr.detectChanges();
  }

  searchFilterData(val) {
    const companyId = val.map((x) => x.item_id);
    const searchVal = this.search !== undefined ? this.search : '';
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.match(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        companies: companyId,
        search: searchVal,
        status: '',
        need_action: '',
        company_ids: '',
        start_date: '',
        end_date: '',
      },
      2
    );
  }

  queryBy(by, queryNgModel) {
    var query = {};
    query[by] =
      queryNgModel.length > 0 ? queryNgModel.map((x1) => x1.item_id) : '';
    this.dataSource.queryBy(query);
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }

  getAgentList() {
    this.jobService.getAgent(true).subscribe((res: any) => {
      let agents = [];
      res.forEach((agent) =>
        agents.push({
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        })
      );
      this.dropdownAgents = agents;
    });
  }

  getStatus() {
    if (this.status?.length == 0) {
      // this.showSpinner = true;
      this.historyMatchService.getHistoryMatchStatus().subscribe(
        (response: any) => {
          let status = [];
          var statusArray = response?.results.sort((a,b) => a.id - b.id)
          statusArray.forEach((sta) =>
          status.push({
            item_id: sta.id,
            item_text: sta.status_name,
          })
          );
          this.status = status;
        },
        (errorRes) => {
        }
      );
    }
  }

  onSelect(e) {
    var startdt = new Date(this.selectedDateRange?.startDate?._d);
    var enddt = new Date(this.selectedDateRange?.endDate?._d);
    var compIds = this.selectedCompanies
      ? this.selectedCompanies.map((x1) => x1.item_id)
      : '';

    let start_date =
      this.selectedDateRange?.startDate?._d === undefined
        ? ''
        : startdt.getFullYear() +
          '-' +
          ((startdt || new Date()).getMonth() + 1) +
          '-' +
          startdt.getDate();
    let end_date =
      this.selectedDateRange?.endDate?._d === undefined
        ? ''
        : enddt.getFullYear() +
          '-' +
          ((enddt || new Date()).getMonth() + 1) +
          '-' +
          enddt.getDate();

    this.dataSource.queryBy({ start_date: start_date, end_date: end_date });
  }

  startClose() {
    this.location.replaceState('/forwardings');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getAgentList();
    this.getCompanyUser();
  }

  //edit sidebar stuff
  edit(
    companyId: number,
    applierId: number,
    jobId: number,
    id: number,
    tabid: number
  ) {
    this.selectedTab.select(tabid);
    (this.companyId = companyId),
      (this.applierId = applierId),
      (this.jobId = jobId),
      (this.id = id),
      (this.tabId = tabid);
    this.isApplier = false;
    this.isNewCompany = false;
    this.location.go(
      '/forwardings/' +
        companyId +
        '/' +
        applierId +
        '/' +
        jobId +
        '/' +
        id +
        '/' +
        tabid
    );
    this.drawer.open();
  }

  getCompanyUser() {
    this.jobService.getCompanies().subscribe((res: any) => {
      let Companies = [];
      res.forEach((company) =>
        Companies.push({ item_id: company.id, item_text: company.name })
      );
      this.dropdownCompanies = Companies;
      this.companiesList = Companies;
    });
  }

  checkIsJobUpdated($event) {
    this.getAgentList();
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  getActionNeeded() {
    if (!this.needActionChecked) {
      this.dataSource.queryBy({ need_action: '' });
    } else {
      this.dataSource.queryBy({ need_action: 'true' });
    }
  }

  exportDialog() {
    const dialogRef = this.dialog.open(ExcelExportDialogComponent, {
      data: {
        dataSource: this.dataSource['query']._value,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}
