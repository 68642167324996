import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileViewGuard implements CanActivate {
  isMobile = false;
  constructor(private deviceService: DeviceDetectorService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.isMobile = this.deviceService.isMobile();


      if (this.isMobile === false && window.location.pathname.includes('mobile')) {
        // this.router.navigate(['mobile']);
        return false
      } 

    return true;
  }
  
}
