// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  // BASE_URL: (window.location.hostname == "localhost") ? "https://healthcareapi.kanhasoftdev.com/" : "https://healthcareapi.kanhasoftdev.com/",
  BASE_URL: (window.location.hostname == "localhost") ? "http://37f5-103-239-146-251.ngrok.io/" : "https://healthcareapi.kanhasoftdev.com/",
  PATH: "api/v1/",
  MS_clientId: "549c2cf7-e3a6-493b-8962-52ab8c96660d",
  MS_redirectUri: (window.location.hostname == "localhost") ? "http://localhost:4200/" : "https://healthcare.kanhasoftdev.com/",
  MS_authority: `https://login.microsoftonline.com/a94e7ae5-d13f-46c7-812b-f72905ea09dd`,
  MS_postLogoutRedirectUri: '/',
  AGM_CORE_API_KEY: "AIzaSyBX6DHcLUxuJXplzLRv8LavTq5BChfAaF8"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
