import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CompanyAuthService {

  returnUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router) { }


  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError),
      // catchError(this.handleError<CompanyAuthService[]>('companyResult', [])),
      tap(resData => {
        if (resData) {
          this.handleAuthentication(resData);
        }
      })
    );
  }

  postv2(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError),
    );
  }

  /*  company login with pagic link */
  doCompanySignIn(postData) {
    return this.postv2('accounts/company-login/', postData);
  }

  /* New register company */
  doCompanySignUp(postData) {
    return this.postv2('accounts/companyuser/', postData);
  }

  /* get login user company details */
  getLoginCompanyData(postData): Observable<any> {
    return this.post(`accounts/company-user-api-token/`, postData);
    // return this.post(`accounts/user/`,postData);
  }

  getLoginCpmpanyJobList(postData) {
    let httpOptions = this.getHTTPOption();
    return this.post(`accounts/get-company-token/`, postData, httpOptions);
  }

  public handleAuthentication(resData) {
    let expiresIn = 3600;
    //We are configuring expiration time to 1 day
    let expirationTime = new Date().getTime() + 24 * expiresIn * 1000;

    if (resData.user) {
      let applicantData;
      applicantData = {
        id: resData?.user.id,
        email: resData?.user.email,
        first_name: resData?.user.first_name,
        last_name: resData?.user.last_name,
        completed_profile: resData?.user.completed_profile,
        role: resData?.user.role,
        token: 'Token ' + resData?.token,
        company_id: resData?.company_id,
        social_account: resData?.social_account ? resData?.social_account[0] : null
      };

      applicantData = { ...applicantData, expiresIn: expirationTime }
      this.autoLogout(24 * expiresIn * 1000);
      localStorage.setItem('companyData', JSON.stringify(applicantData));
    }
  }
 
  public handleError(errorRes: HttpErrorResponse) {
    console.log("errorRes", errorRes?.statusText)
    if (Object.keys(errorRes?.error)?.length > 0) {
      if(Object.keys(errorRes?.error)[0]?.length > 1 && errorRes?.statusText !== 'Unknown Error'){
        return throwError(Object.keys(errorRes.error)[0] + " " + Object.values(errorRes.error)[0]);
      }
    }
    let message = (errorRes?.statusText) ? errorRes?.statusText : 'etwas ist schief gelaufen!'
    return throwError(message);
  }


  getHTTPOption(isMultiPartForm = false) {
    const token = this.getAuthToken();
    let headers_object;
    if (token) {
      if (isMultiPartForm) {
        headers_object = new HttpHeaders({ 'Authorization': token, "Accept": "application/json" });
      } else {
        headers_object = new HttpHeaders({ 'Authorization': token });
      }
      return {
        headers: headers_object
      };
    }
    return null;
  }

  getAuthToken() {
    let localData = this.getUserData();
    if (localData) {
      return localData.token;
    }
    this.router.navigate(['/login']);
  }


  getUserData() {
    // let userData = JSON.parse(localStorage.getItem('agentData'));
    let userData = JSON.parse(localStorage.getItem('companyData'));
    // let companyData = JSON.parse(localStorage.getItem('companyData'));
    if (userData) {
      let expireTime = userData?.expiresIn;
      if (new Date().getTime() > expireTime) {
        localStorage.removeItem('companyData');
        localStorage.removeItem('agentData');
        this.router.navigate(['/login']);
      }
      return userData;
    }
    let url = window.location.href;

    //TODO:replace below flow with authGuard.
    let isRegister = url.substring(url.lastIndexOf('/') + 1) === 'register';
    let isResetPasswordUrl = url.includes('reset-password')
    let isPublicUrl = url.includes('public')
    let isCompanyLogin = url.includes('company-login')
    if (!isRegister && !isResetPasswordUrl && !isPublicUrl && isCompanyLogin) {
      this.router.navigate(['/login']);
    }
  }

  autoLogout(expirationDuration: number) {
    setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  logout() {
    localStorage.removeItem('companyData');
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 1000);
  }


}
