import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/services/applicant.service';
import { PaginationDataSource } from 'ngx-pagination-data-source'
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator'; 
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  // encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('drawer') drawer: MatSidenav;
  @Input('matSortDirection') direction: SortDirection;
  @Input() hasBackdrop: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  subscriptions: Array<Subscription> = [];

  dataSource = new PaginationDataSource(
    (request, query) => this.applicantService.page(request, query), { property: '' as keyof Sortable, order: 'desc' },
    { search: '', need_action: '', radius_search: '', latitude: '', longitude: '' }, 2
  )

  columnsAll = [{key:'name', name:"Name"}, {key:'date', name:"Datum"}, {key:'education',name:"Ausbildung"}, {key:'continous_education',name:"Weiterbildungen"}, {key:'care_type',name:"Pflegeart"},{key:'shift_hour',name:"Schichten"},{key:'scope',name:"Arbeitsumfang"},{key:'location',name:"Standort"},{key:'status',name:"Satus"},{key:'reference',name:"Referenz von"},{key:'action',name:"Actionsarea"}];
  columnsToDisplay = ['name', 'date', 'education', 'continous_education', 'care_type', 'shift_hour', 'scope', 'location', 'status','reference', 'action'];
  opened: boolean;
  needActionChecked = false;
  applicantForm: FormGroup;
  selectedApplier: any;
  currentTime: any;
  selectedTab = new SelectionModel(false, [0]);
  expandedElement = null;
  sorting_order: null;
  currentPage:any;
  isMobile = false;
  isActiveproximity = false;
  isAddressActive = false;
  applierId: number;
  tabId: number;
  lat: any = '';
  lng: any = '';
  options = {
    types: ['geocode'],
    componentRestrictions: {
      country: ['de'],
    }
  };

  constructor(
    private translate: TranslateService,
    private applicantService: ApplicantService,
    private _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    private cdr: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.applierId = +params['applierId'];
        this.tabId = +params['tabId'];
      })
      );
  }


  ngAfterViewInit() {
    if(this.applierId && this.tabId !== null){
      this.toggle(this.applierId , this.tabId)
    }
    this.cdr.detectChanges();
  }

  startClose() {
    this.location.go('/applicants');
  }
  

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc'
    })
  }

  // open the navebar and load applier
  toggle(applierId: number, tab: number) {
    this.selectedTab.select(tab);
    this.selectedApplier = applierId;
    this.currentTime = new Date().getTime();
    this.location.go('/applicants/' + applierId + '/' + tab );
    this.openDrawer();
  }

  // download applicant pdf profile
  download(row) {
    const applyerCityName = row.name + '_' + row.city.replace('_', ' ');
    this.applicantService.downloadPdf(row.id).subscribe((response: any) => {
      let downloadURL = window.URL.createObjectURL(response);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Kurzprofil_${applyerCityName.split(' ').join('_')}.pdf`;
      link.click();
    });
  }

  download_file(upload_file) {
    const link = document.createElement('a');
    link.href = upload_file;
    link.click();
    link.remove();
  }

  getActionNeeded() {
    if (!this.needActionChecked) {
      this.dataSource.queryBy({ need_action: '' })
    } else {
      this.dataSource.queryBy({ need_action: 'true' })
    }
  }

  getActiveProximity() {
    if (!this.isActiveproximity) {
      this.dataSource.queryBy({ radius_search: '' })
      this.dataSource.queryBy({ latitude: '', longitude: '' })
      this.isAddressActive = false;
    } else {
      this.dataSource.queryBy({ radius_search: 'true' })
      this.isAddressActive = true;
    }
  }

  AddressChange(address: any) {
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    if (!this.isAddressActive) {      
      this.dataSource.queryBy({ latitude: '', longitude: '' })
    } else {      
      this.dataSource.queryBy({ latitude: this.lat, longitude: this.lng })
    }
  }

  updateAndClose($event) {
    this._snackBar.open(this.translate.instant('message.aplication_update'), '', {
      duration: 5000
    });
    this.update($event);
    this.closeDrawer();
  }
 
  update($event) {
    this._snackBar.open(this.translate.instant('message.aplication_update'), '', {
      duration: 5000
    });
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  handleError() {
    this._snackBar.open(this.translate.instant('message.something_wrong'), '', {
      duration: 5000
    });
  }

  closeDrawer() {
    this.drawer.close();
  }

  openDrawer() {
    this.drawer.open();
  }

  refreshTable() {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  // all stuff with expandable Row

  toggles(event, row) {
    const agentToogle = { "need_action": event.checked }
    this.applicantService.updateNeedAction(agentToogle, row.id).subscribe((response: any) => {
      this.dataSource.fetch(this.paginator.pageIndex);
    }, (errorRes) => {
      console.log(errorRes)
      this._snackBar.open(errorRes, "OK", { duration: 5000 });
    });
  }
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}
