<div class="content">
    <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title style="text-align: center !important;">Login with</mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            <button mat-raised-button class="btn-signin" color="primary" (click)="login()">Sign in with Microsoft</button>
        </mat-card-content>
      </mat-card>
    </div>
    
    
    
    <!-- <div class="content">
        <mat-accordion class="example-headers-align" >
            <mat-expansion-panel [expanded]= "true" [disabled]="true" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Personal data
                </mat-panel-title>
                <mat-panel-description>
                  Type your name and age
                  <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
          
              <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput>
              </mat-form-field>
          
              <mat-form-field>
                <mat-label>Age</mat-label>
                <input matInput type="number" min="1">
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
     </div> -->
    
    
    
      