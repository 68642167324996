import shiftHoursData from './abbreviations/shiftHoursType.json';
import careTypeData from './abbreviations/careType.json';
import matchStatusData from './abbreviations/matchStatusType.json';
import specificationData from './abbreviations/specificationType.json';
import specialJobTypeData from './abbreviations/specialRequirementJobType.json';
import educationTypeData from './abbreviations/educationType.json';
import additionalQueData from './abbreviations/additionalQuestionType.json';
import referenceData from './abbreviations/referenceType.json';
import eduAssistanceData from './abbreviations/educationAssistanceType.json';
import historyMatchChannelData from './abbreviations/historyMatchChannel.json';
import continuingEduData from './abbreviations/continuingEducationType.json';
import statisData from './abbreviations/statisType.json';
import vaccinationTypeData from './abbreviations/vaccinationType.json';
import licenseType from './abbreviations/licenseType.json';
import scopeData from './abbreviations/scopeType.json';

export interface abbreviation {
  id: number;
  fields: AbbreviationFields;
}

export interface AbbreviationFields {
  short_name: string;
  full_name: string;
}

export interface SelectList {
  item_id: number;
  item_text: string;
}

export class AbbreviationModel {
  public getList(type: string): any {
    let data: abbreviation[] = [];
    if (type == 'shiftHours') {
      data = shiftHoursData;
    } else if (type == 'careType') {
      data = careTypeData;
    } else if (type == 'matchStatus') {
      data = matchStatusData;
    } else if (type == 'specification') {
      data = specificationData;
    } else if (type == 'specialJobType') {
      data = specialJobTypeData;
    } else if (type == 'educationType') {
      data = educationTypeData;
    } else if (type == 'additionalQue.') {
      data = additionalQueData;
    } else if (type == 'reference') {
      data = referenceData;
    } else if (type == 'eduAssistance') {
      data = eduAssistanceData;
    }
    // else if (type == 'historyMatch'){
    //   data = historyMatchChannelData;
    // }
    else if (type == 'continuingEdu.') {
      data = continuingEduData;
    } else if (type == 'status') {
      data = statisData;
    } else if (type == 'vaccinationType') {
      data = vaccinationTypeData;
    } else if (type == 'licenseType') {
      data = licenseType;
    } else if (type == 'scope') {
      data = scopeData;
    }
    return data;
  }
   
     public getById(id:any,type:string,field='short'):string {
       let data:abbreviation[] = this.getList(type);
       for (let el of data) {
         if (el.id == id) {
           if (field=='short'){
             return el.fields.short_name;
           }else {
             return el.fields.full_name;
           }
         }
       }
       return '';
     }
   
     public getSelectList(type:string,notShow:number[]=[]):SelectList[] {
       let list = this.getList(type);
       let returnList: SelectList[] = []
       for (let item of list) {
         let obj:SelectList = {
           item_id: item.id,
           item_text: item.fields.short_name,
         };
         if (notShow.length==0 || !notShow.includes(item.id)) {
           returnList.push(obj);
         }
       }
       return returnList;
     }

  
  /**
   * get history matche channel
   */
  protected historyMatchChannel = [
    {
      'id': 1,
      'icon': 'whatsapp',
      'name': 'WhatsApp'
    },
    {
      'id': 2,
      'icon': 'phone',
      'name': 'Telefon'
    },
    {
      'id': 3,
      'icon': 'mail',
      'name': 'E-mail'
    }
  ];

  /**
   * get history matche channel
   */

  getHistoryMatchChannelById(history) {
    for (let hist of this.historyMatchChannel) {
      if (hist.id == history) {
        return hist.icon;
      }
    }
  }

  getHistoryMatchChannel() {
    return this.historyMatchChannel;
  }
}
