<div class="example-element-detail">
  <!-- match table section -->
  <section *ngIf="innerDataSource.data.length > 0" class="match-section">
    <mat-table cdkDropList [dataSource]="innerDataSource" class="match-table" matSort
      [cdkDropListData]="innerDataSource.data" (cdkDropListDropped)="drop($event)">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-icon *ngIf="selection.selected.length != 0" (click)="selection.clear()">close</mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <mat-checkbox style="padding-right: 0px" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row.id) : null" [checked]="selection.isSelected(row.id)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyname">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_companyname
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <span style="margin: 10px">{{ element?.company?.name }} ({{ element?.job?.postcode }}
            {{ element?.job?.city }})</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="education">
        <mat-header-cell *matHeaderCellDef translate>home.col_education</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <ng-container *ngFor="let ct of element.job.education_type">
            <tag *ngIf="ct != 4" [active]="element.applier?.education?.includes(ct)">
              {{ ct | EducationForJob }}</tag>
            <tag *ngIf="ct == 4" [active]="
                element.applier?.education_assistant <=
                element?.job?.education_assistant
              ">
              {{
              element?.job.education_assistant
              | EducationAssistentAbbreviations
              }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caretype">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_caretype</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <tag *ngFor="let ct of element.job.care_type" [active]="element.match_care_type.includes(ct)">{{ ct |
            CareTypeAbbreviations }}</tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shift">
        <mat-header-cell *matHeaderCellDef translate>home.col_shift</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <!-- <span *ngFor="let shift of element.job.shift" class="tag"
            [class.active]="element.applier.shift_hour.includes(shift)">{{shift|ShiftAbbreviations}}</span> -->
          <tag *ngFor="let shift of element.job.shift" [active]="element?.applier?.shift_hour?.includes(shift)">
            {{ shift | ShiftAbbreviations }}</tag>
          <tag *ngIf="element.job.shift_other">{{
            element.job.shift_other
            }}</tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scope">
        <mat-header-cell *matHeaderCellDef translate>home.col_employment</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <tag *ngFor="let wt of element?.job.working_type" [active]="element?.applier?.working_type?.includes(wt)">
            {{ wt | ScopeAbbreviations }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="continuing_education">
        <mat-header-cell *matHeaderCellDef translate>home.col_continuing_educations</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <tag *ngFor="let continueEdu of element?.job.continuing_education" [active]="
              element?.applier.continuing_education
                ? element?.applier.continuing_education.includes(continueEdu)
                : false
            ">
            {{ continueEdu | ContinuingEducations }}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="special_requirements">
        <mat-header-cell *matHeaderCellDef translate>home.col_special_req</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <!-- <span *ngFor="let sr of element?.job?.special_requirements" class="tag">{{sr | SpecialRequirementsById}}</span>
          <span *ngFor="let sr of element?.job?.specification_care_type" class="tag second">{{sr | SpecialCareTypeById}}</span>  -->
          <tag *ngFor="let sr of element?.job?.special_requirements">{{
            sr | SpecialRequirementsById
            }}</tag>
          <tag *ngFor="let sr of element?.job?.specification_care_type" [second]="true" [color]="secondary">
            {{ sr | SpecialCareTypeById }}</tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="distanz">
        <mat-header-cell *matHeaderCellDef translate>
          home.col_distance
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          {{ element?.distance | number: "1.1-1" }}&#8202;km
          <ng-container *ngIf="element?.duration">
            ({{ element?.duration * 1000 - 3600 * 1000 | date: "H:mm" }})
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>
          <mat-icon *ngIf="!showMap" (click)="showMap = true" class="action-icon" matTooltip="Karte anzeigen">visibility
          </mat-icon>
          <mat-icon *ngIf="showMap" (click)="showMap = false" class="action-icon" matTooltip="Karte ausblenden">
            visibility_off</mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
          <mat-icon *ngIf="element.status==1"
            matTooltip="{{getMatchStatus(element.status)}} {{element?.updated_at | date:'dd.MM.yyyy'}}"
            class="action-icon">link</mat-icon>
          <mat-icon *ngIf="element.status == 2" matBadge="mail" class="assistant-badge" matBadgeSize="small" matTooltip="{{ getMatchStatus(element.status) }} {{
              element?.updated_at | date: 'dd.MM.yyyy'
            }}" class="action-icon">playlist_add_check</mat-icon>
            <mat-icon *ngIf="element.status==6" matBadge="whatsapp" class="assistant-badge" matBadgeSize="small"
            matTooltip="{{getMatchStatus(element.status)}} {{element?.updated_at | date:'dd.MM.yyyy'}}"
            class="action-icon">playlist_add_check</mat-icon>
          <mat-icon *ngIf="element.status == 5" matTooltip="An Unternehmen weitergeleitet  {{
              element?.updated_at | date: 'dd.MM.yyyy'
            }}" class="action-icon">
            reply
          </mat-icon>
          <mat-icon *ngIf="element?.company?.sales" matTooltip="Sales stelle">shopping_cart</mat-icon>
          <mat-icon *ngIf="element.job.note" matTooltip="Extern: {{ element.job.note }}" class="action-icon">info
          </mat-icon>
          <mat-icon *ngIf="element.job.internal_note" matTooltip="Intern: {{ element.job.internal_note }}"
            class="action-icon">info_outline</mat-icon>
          <a *ngIf="element.job.url" href="{{
              element.job.url.startsWith('http') == false ||
              element.job.url.startsWith('https') == false
                ? 'https://' + element.job.url
                : element.job.url
            }}" target="_blank">
            <mat-icon class="action-icon">ios_share</mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="innerColumnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: innerColumnsToDisplay" cdkDrag
        (dblclick)="selection.toggle(element.id)" (mouseenter)="updateColor(element.id)"
        (mouseleave)="updateColorR(element.id)" [class.hover]="hover.isSelected(element?.id)"
        [class.jobmatch]="element?.applier?.jobId == element.job.id"></mat-row>
    </mat-table>

    <div style="margin-left: 5%; margin-top: 2%">
      <mat-form-field style="width: 90%">
        <mat-label>Anmerkung</mat-label>
        <textarea matInput rows="3" [(ngModel)]="notes"></textarea>
      </mat-form-field>

      <div class="toggle-row">
        <form [formGroup]="statusForm">
          <mat-button-toggle-group name="fontStyle" formControlName="checkStatus" aria-label="Font Style">
            <mat-button-toggle value="{{ item.id }}" *ngFor="let item of matchStatusTypeList">
              {{ item.name }}</mat-button-toggle>
          </mat-button-toggle-group>
        </form>
      </div>
      <div class="toggle-row" style="min-height: 30px;">
        <div *ngIf="statusForm.controls['checkStatus'].value == 2 || statusForm.controls['checkStatus'].value == 5">
          <span>Agent</span>
          <mat-slide-toggle style="margin: 0px 10px" [(ngModel)]="note_bool" class="example-margin">
          </mat-slide-toggle>
          <span *ngIf="
              statusForm.controls['checkStatus'].value == 2 ||
              statusForm.controls['checkStatus'].value == undefined
            " translate>home.applier
          </span>
          <span *ngIf="statusForm.controls['checkStatus'].value == 5" translate>home.company
          </span>
        </div>
      </div>


    </div>
    <div class="actionBar">
      <button *ngIf="statusForm.controls['checkStatus'].value == 5" mat-raised-button type="button"
        class="forward_to_company_btn" color="forward_to_company_btn" (click)="sendMatches()"
        [disabled]="selection.selected.length == 0 || buttonDisable">
        <span translate>home.forward_to_company_btn</span>
      </button>
      <button *ngIf="
          statusForm.controls['checkStatus'].value == 2 ||
          statusForm.controls['checkStatus'].value == 6 ||
          statusForm.controls['checkStatus'].value == undefined
        " mat-raised-button type="button" color="primary" (click)="sendMatches()"
        [disabled]="selection.selected.length == 0 || buttonDisable">
        <span translate>home.btn_send_match</span>
      </button>
      <button *ngIf="
          statusForm.controls['checkStatus'].value == 7 || 
          statusForm.controls['checkStatus'].value == 8" mat-raised-button type="button" color="warn"
        (click)="sendMatches()" [disabled]="buttonDisable">
        <span translate>home.btn_reject_applicants</span>
      </button>
    </div>
  </section>

  <!-- map section -->
  <section *ngIf="innerDataSource.data.length > 0 && showMap" class="map-section">
    <agm-map [zoom]="9" [latitude]="lat" [longitude]="lng">
      <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false" [iconUrl]="getMarker('28605B')">
      </agm-marker>
      <agm-marker *ngFor="let m of innerDataSource.filteredData" [latitude]="m.job.latitude"
        [longitude]="m.job.longitude" (markerClick)="selectRow(m.id)" [iconUrl]="getMarkerUrl(m.id)"
        (mouseOver)="updateColor(m.id)" (mouseOut)="updateColorR(m.id)">
      </agm-marker>
    </agm-map>
  </section>
</div>
<div *ngIf="innerDataSource.data.length == 0" style="width: 100%">
  <div *ngIf="isSpinner" class="example-element-detail">
    <section class="match-section">
      <div style="margin-top: 2%">
        <ngx-skeleton-loader count="5" appearance="line" [theme]="{ 'height.px': 50 }"></ngx-skeleton-loader>
      </div>
    </section>
    <section class="map-section" style="width: 500px">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 300 }"></ngx-skeleton-loader>
    </section>
  </div>
  <ngx-skeleton-loader *ngIf="isSpinner" count="1" appearance="line" [theme]="{ 'height.px': 50 }">
  </ngx-skeleton-loader>
  <h1 *ngIf="!isSpinner" style="text-align: center; margin-top: 10px">
    {{ "home.no_match_found" | translate }}
  </h1>
</div>