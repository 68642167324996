<mat-sidenav-container
  class="container"
  style="
    position: fixed;
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
  "
>
  <mat-sidenav class="sidebar" mode="side" opened [disableClose]="true">
    <div class="search-results"
    infiniteScroll
   
    (scrolled)="onScroll()"
    [scrollWindow]="false">
      <form [formGroup]="companyForm">
        <mat-form-field class="search-field" appearance="fill">
          <mat-label translate>company.company_name</mat-label>
          <input
            matInput
            type="text"
            formControlName="search"
            [(ngModel)]="names"
            (input)="getCompanyUser()"
          />
        </mat-form-field>
      </form>
      <div
        
      >
        <mat-selection-list
          value
          #company
          [multiple]="false"
          [formControl]="comName"
          [(ngModel)]="selectedOptions"
        >
          <mat-list-option
            *ngFor="let company of companies"
            [value]="company?.id"
            [selected]="company.id === company[0]?.id"
            (click)="selectedCompany(company, company.id)"
          >
            {{ company.name }}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <ng-container *ngIf="!companyDetails">
        <ngx-skeleton-loader
          count="10"
          appearance="line"
          [theme]="{ 'height.px': 30 }"
        ></ngx-skeleton-loader>
      </ng-container>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-company-detail
      *ngIf="companies"
      [companyDetails]="companyDetails"
      (refreshCompany)="refreshCompany($event)"
    ></app-company-detail>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- <ng-container *ngIf="!companies">
    <ngx-skeleton-loader count="10" appearance="line" [theme]="{'height.px': 30}"></ngx-skeleton-loader>
  </ng-container> -->
