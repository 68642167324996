import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HistoryService } from 'src/app/services/history.service';
import { History } from 'src/app/models/history.model';
import { TranslateService } from '@ngx-translate/core';
import { AbbreviationModel } from 'src/app/models/abbreviation.model';
import { ApplicantService } from 'src/app/services/applicant.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanySidebarComponent, DialogData } from '../company-sidebar/companysidebar.component';
import { SendWhatsappDialogComponent } from '../send-whatsapp-dialog/send-whatsapp-dialog.component';
@Component({
  selector: 'app-history-sidebar',
  templateUrl: './history-sidebar.component.html',
  styleUrls: ['./history-sidebar.component.scss'],
})
export class HistorySidebarComponent implements OnInit {
  @Input() applierId: any;
  @Input() currentTime: any;
  @Output() doClose = new EventEmitter<any>();
  @Output() refreshApplier = new EventEmitter<any>();
  status: any;
  icn: string;
  chnlnm: string;
  showSpinner = false;
  historyForm: FormGroup;
  channels: any = [];
  statis: any;
  now: any = new Date();
  historyData: any;
  returnValue: string;
  // costum_date: any;

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private historyService: HistoryService,
    private translate: TranslateService,
    private applicantService: ApplicantService,
    private clipboard: Clipboard,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // make a API Call for that
    this.initForm(); 
  }

  initForm() {
    this.historyForm = this._formBuilder.group({
      content: [''],
      channel_id: ['', Validators.required],
      status_id: ['', Validators.required],
      // costum_Date: ['']
    });
    this.getHistory();
    this.getStatus();
  }

  ngOnChanges(): void {
    this.initForm();
    this.getHistory();
    this.getChannel();
    this.getStatus();
  }

  close() {
    this.doClose.emit();
  }

  clearDate(event) {
    event.stopPropagation();
    // this.historyForm.value.costum_Date = null;
    this.historyForm.patchValue({costum_Date: null});
  }

  submitHistory() {
    var newdate = new Date(this.historyForm.value.costum_Date);
    // this.costum_date = newdate.getFullYear() + '-' + ((newdate || new Date()).getMonth() + 1) + '-' + newdate.getDate()
    
    
    if (this.historyForm.valid == true) {
      const postData = new History(
        this.historyForm.value.content,
        this.applierId,
        this.historyForm.value.status_id,
        this.historyForm.value.channel_id,
        // this.costum_date
        
      );
      this.showSpinner = true;
      this.historyService.addHistoryData(postData).subscribe(
        (response: any) => {
          this.getHistory();
          this.refreshApplier.emit();
          this.historyForm.reset();
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  getHistory() {
    if (typeof this?.applierId !== 'undefined') {
      this.showSpinner = true;
      this.historyService.getHistoryData(this.applierId).subscribe(
        (response: any) => {
          this.historyData = response?.results;
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  getStatus() {
    if (typeof this?.applierId !== 'undefined') {
      this.showSpinner = true;
      this.historyService.getHistoryStatus().subscribe(
        (response: any) => {
          this.status = response?.results;
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  getChannel() {
    if (typeof this?.applierId !== 'undefined') {
      this.showSpinner = true;
      this.historyService.getHistoryChannel().subscribe(
        (response: any) => {
          this.channels = response?.results;
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  changeIconNname(icon, name) {
    this.icn = icon;
    this.chnlnm = name;

    // this.statis = [
    //   { id: 1, name: 'leer/Eingang' },
    //   { id: 2, name: 'Rückfrage gestellt' },
    //   { id: 3, name: 'Vorschläge gesendet' },
    //   { id: 4, name: 'Bewerber weitergeleitet' },
    //   { id: 5, name: 'keine Matches (k.M.)' },
    //   { id: 6, name: 'ungeeignet' },
    // ];

    const historyStatis = 
      new AbbreviationModel().getList('status');
    let statArr = [];
    for (let statis of historyStatis) {
      let statObj = {
        id: Number(statis.id),
        name: statis.fields.short_name,
      };
      statArr.push(statObj);
    }
    this.statis = statArr;
    

  }

  compare(str) {
    if (this.now >= Date.parse(str)) {
      return true;
    } else {
      return false;
    }
  }
  delete(id) {
    // delete the posting
    // refresh match history
    this.showSpinner = true;
    this.historyService.deleteHistory(id).subscribe(
      (response: any) => {
        this.getHistory();
        this._snackBar.open(
          this.translate.instant('history.delete_record'),
          'OK',
          { duration: 5000 }
        );
        this.showSpinner = false;
      },
      (errorRes) => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  sendMatches(channel_id: number, applier_id: number, applier_phone: string , status_id: number) {
      let postData = {
        match_ids: [],
        status: null,
        note: '',
        note_bool: false ,
        applier_id : applier_id ,
        channel : (channel_id === 7) ? "whatsapp" : 
        (channel_id === 8 ? "mail" : ""),
        status_id : status_id,
        model_name:"Status"
      };
    
    this.applicantService.sendMatches(applier_id, postData).subscribe(
      (res) => {        
        this.returnValue = "";
        if (channel_id === 7 && res['output']) {
          this.returnValue = res['output'];
          this.addCompanyDialog(channel_id, applier_phone);
          this.clipboard.copy(this.returnValue);
        }
        const snakMessage =
          (channel_id === 7 && res['output'])
            ? this.translate.instant('message.copied_to_clipboard')
            : ((channel_id === 8) ? this.translate.instant('message.email_sent') : 
            'enthalten nicht verfügbar');
        this._snackBar.open(snakMessage, '', {
          duration: 5000,
        });
      },
      (error) => {
        if (error !== null && error !== 'undefined') {
          this._snackBar.open(error.replace('message', ''), '', {
            duration: 5000,
          });
        } else {
          this._snackBar.open(
            this.translate.instant('message.something_wrong'),
            '',
            { duration: 5000 }
          );
        }
        // this.buttonDisable = false;
      }
      );
  }
  addCompanyDialog(channel_id: number, applier_phone: string) {

    const dialogRef = this.dialog.open(SendWhatsappDialogComponent, {
      data: {
        returnValue: this.returnValue,
        applier_phone: applier_phone,
        checkStatus: channel_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

}


  





