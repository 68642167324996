import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient,
    private router: Router, private authService: AuthService) { }

  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions);
  }

  doAnalytics(postData) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post('accounts/analytics/', postData, httpOptions);
  }
}
