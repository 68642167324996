
<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer #drawer class="notification-sidenav" id="notification-sidenav" position="end" [mode]="'side'" (closedStart)="startClose()">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)">
      <mat-tab label="Bewerber">
        <app-applier-sidebar *ngIf="drawer.opened && selectedTab?.selected[0] == 0"
        [applierId]="selectedApplier" (updateAndClose)="updateAndClose($event)" (update)="update($event)"
          (doClose)="closeDrawer()" (handleError)="handleError()"></app-applier-sidebar>
      </mat-tab>
      <mat-tab label="Verlauf">
        <app-history-sidebar *ngIf="drawer.opened && selectedTab?.selected[0] == 1" [applierId]="selectedApplier" [currentTime]="currentTime" (refreshApplier)="refreshTable()"
          (doClose)="closeDrawer()"></app-history-sidebar>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <!-- Filter Start  -->
  <div>
    <div  class="row filters-row" style="margin-top: 10px;">
      <div *ngIf="!isAddressActive" class="col filters-col">
        <mat-form-field>
          <mat-label translate>home.search_placeholder</mat-label>
          <input matInput (input)="dataSource.queryBy({search: input.value})"
            placeholder="{{ 'home.search_placeholder_value' | translate}}" #input>
        </mat-form-field>
      </div>

      <div *ngIf="isAddressActive" class="col filters-col">
        <div class="col filters-col">
          <mat-form-field appearance="legacy">
            <mat-label translate>home.search_placeholder</mat-label>
            <input matInput ngx-google-places-autocomplete [options]='options' [placeholder]="'home.search_placeholder_value' | translate" #input
              (onAddressChange)="AddressChange($event)" />
            <!-- <mat-icon matSuffix>search</mat-icon> -->
          </mat-form-field>
        </div>
      </div>

      <div class="col filters-col filter-need-action">
        <mat-checkbox [(ngModel)]="isActiveproximity" (change)="getActiveProximity()" style="margin-top: 15px;">
           {{ 'jobs.activate_proximity' | translate}}
        </mat-checkbox>
      </div>

      <div class="col filters-col filter-need-action">
        <mat-checkbox [(ngModel)]="needActionChecked" (change)="getActionNeeded()" translate>{{'home.search_need_action' |
          translate}}</mat-checkbox>
      </div>
    </div>
   <!--Start with table-->
    <mat-table mat-table [dataSource]="dataSource" multiTemplateDataRows
      class="mat-elevation-z8 table-hover table-applicant" matSort (matSortChange)="sortBy($event)" matSortActive="date"
      matSortStart="asc" matSortDisableClear>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" style="text-align: end;">
          <mat-slide-toggle class="action-icon" [checked]="row.need_action" (change)="toggles($event, row)"
            color="primary">
          </mat-slide-toggle>
          <mat-icon class="action-icon" (click)="toggle(row?.id,1)" >history</mat-icon>
          <mat-icon class="action-icon" matBadge="2" matBadgeHidden="{{row?.upload_file ? false : true}}" matBadgeSize="small" [matMenuTriggerFor]="menu" >download</mat-icon>
          <mat-menu #menu="matMenu" xPosition="before" yPosition="below"  >
            <button mat-menu-item (click)="download(row)">
              <!-- <mat-icon>content_copy</mat-icon> -->
              <span translate>home.short_profile</span>
            </button>
            <button *ngIf="row?.upload_file" mat-menu-item (click)="download_file(row?.upload_file)">
              <!-- <mat-icon>share</mat-icon> -->
              <span translate>home.resume</span>
            </button>
            <!-- <button mat-menu-item (click)="delete(element?.id)">
              <mat-icon class="delete-icon">delete</mat-icon>
              <span class="delete-icon">Job löschen</span>
            </button> -->
          </mat-menu>
          <mat-icon  class="action-icon" (click)="toggle(row?.id,0)" >edit</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header="name"> {{'home.col_name' |
          translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"> 
          <tag *ngIf="element.duplicate" class="table-badge" [active]="true">Duplikat</tag>{{element?.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header="created_at">
          {{'home.col_date' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"> {{element?.created_at |
          date:'dd.MM.yyyy HH:mm'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="education">
        <mat-header-cell *matHeaderCellDef translate> home.col_education </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <ng-container *ngIf="element?.education?.length > 0">
            <ng-container *ngFor="let edu of element?.education">
              <tag *ngIf="edu!=4">{{edu|EducationForJob}}</tag>
              <tag *ngIf="edu==4">{{edu|EducationForJob}}
                {{element?.education_assistant|EducationAssistentAbbreviations }}</tag>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="element?.education?.length == 0">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="continous_education">
        <mat-header-cell *matHeaderCellDef translate> home.col_continuing_educations </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <tag *ngFor="let ce of element?.continuing_education" [color]="'secondary'">
            {{ ce |ContinuingEducations}}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="care_type">
        <mat-header-cell *matHeaderCellDef translate> home.col_care_type </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <ng-container *ngIf="element?.care_type?.length > 0">
            <tag *ngFor="let ct of element?.care_type">
              {{ct | CareTypeAbbreviations}}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.care_type?.length == 0">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shift_hour">
        <mat-header-cell *matHeaderCellDef translate> home.col_shift_hour </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <ng-container *ngIf="element?.shift_hour?.length > 0">
            <tag *ngFor="let shift of element?.shift_hour">
              {{shift | ShiftAbbreviations}}
            </tag>
          </ng-container>
          <ng-container *ngIf="element.shift_other != null && element.shift_other !=''">
            <tag>{{element.shift_other}}
            </tag>
          </ng-container>
          <ng-container *ngIf="element?.shift_hour?.length == 0&&element?.shift_others==''">
            <tag>{{ 'message.not_available' | translate }}</tag>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scope">
        <mat-header-cell *matHeaderCellDef translate> home.col_scope </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <tag *ngFor="let wt of element?.working_type" [color]="'secondary'">
            {{wt|ScopeAbbreviations}}
          </tag>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header="city">
          {{'home.col_location' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          {{element?.location}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>
          {{'home.col_status' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <mat-icon class="social-media">{{ element?.status_log[0]?.channel?.icon}}</mat-icon>{{
          element?.status_log[0]?.status?.status_name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reference">
        <mat-header-cell *matHeaderCellDef translate> Referenz von </mat-header-cell>
        <mat-cell *matCellDef="let element" [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
          <tag *ngFor="let wt of element?.reference_by" [color]="'secondary'">
            {{wt | ReferenceBy}}
          </tag>
        </mat-cell>
      </ng-container>


      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <app-expandable-row *ngIf="element == expandedElement" [applierId]="element.id" [lat]="element.latitude"
              [lng]="element.longitude"></app-expandable-row>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: columnsToDisplay;" id="{{ element.id}}_match"
        [class.statusNeed_action]="element?.need_action && element?.status_log.length !=0"
        class="{{element?.need_action ? 'isActionNeeded example-element-row':'example-element-row' }} {{ element.id}}_match">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <div class="mat-elevation-z8 paginator-row" *ngIf="dataSource.page$  | async">
      <div class="progress-bar">
        <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
        </mat-progress-bar>
      </div>
      <mat-paginator *ngIf="dataSource.page$ | async as page" [length]="page.totalElements" [pageSize]="10"
        [showFirstLastButtons]="true" [hidePageSize]="true" (page)="dataSource.fetch($event.pageIndex)"
        [pageIndex]="page.number" (page)="currentPage= ($event.pageIndex == 0) ? 1 : $event.pageIndex;">
      </mat-paginator>
    </div>
  </div>
  <div *ngIf="((dataSource.loading$ | async) !== false ) && !currentPage" class="skeleton-loader">
    <ngx-skeleton-loader count="11" appearance="line" [theme]="{'height.px':35}"></ngx-skeleton-loader>
  </div>
  <app-row-selecter [uniqueId]="'applierTable'"[allRows]="columnsAll" [selectedRows]="columnsToDisplay"></app-row-selecter>
</mat-drawer-container>
