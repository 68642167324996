import { Component, Input, Output, OnInit, AfterViewInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PostingsService } from '../../../services/postings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { DateAdapter } from '@angular/material/core';
import { JobService } from 'src/app/services/job.service';
const codes = require('german-postal-codes');
@Component({
  selector: 'app-job-posting-sidebar',
  templateUrl: './job-posting-sidebar.component.html',
  styleUrls: ['./job-posting-sidebar.component.scss']
})
export class JobPostingSidebarComponent implements OnInit {

  @Input('jobId') jobId: number;
  @Input('postcode') postcode: number;
  postingFormGroup: FormGroup;
  showSpinner = false;
  isEdit = false;
  budgets: any = [{ value: 0, name: "Kein Budget" }, { value: 1, name: "Hoher Klickpreis" }, { value: 2, name: "Mittlerer Klickpreis" }, { value: 3, name: "Geringer Klickpreis" },  { value: 4, name: "Prescreen" }]
  // please get them from API in ngOnInit
  templates: any;
  selectedTemplateText: string = '';
  postings = [{ id: "1", template: { id: "1", type: "Intensiveplege", name: "Bestes Template", title: "aasdf" }, budget: 2, created_at: "2012-12-30", title: "", plz: "71134" }];
  isPostcodeValid = false;
  postcodeitem = '';


  constructor(private jobService: JobService, private postingsService: PostingsService, private router: Router, private cdr: ChangeDetectorRef,
    private translate: TranslateService, private dateAdapter: DateAdapter<Date>, private _formBuilder: FormBuilder, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initForm();
    this.cdr.detectChanges();
  }

  initForm() {
    this.postings = [{ id: "1", template: { id: "1", type: "Intensiveplege", name: "Bestes Template", title: "aasdf" }, budget: 2, created_at: "2012-12-30", title: "", plz: "71134" }]

    this.postingFormGroup = this._formBuilder.group({
      id: [this.jobId],
      job_id: [this.jobId],
      title: [''],
      template_id: ['', Validators.required],
      postcode: [this.postcode, Validators.required],
      posting_date: [new Date(), Validators.required],
      costume_title_toogle: [false],
      budget: [0, Validators.required],
    });
    this.cdr.detectChanges();
    this.getTemplate();
    this.getPostingDataForJob();

    if (this.postcode) {
      var target = codes.find(temp => temp == this.postcode)
      if (target)
        this.isPostcodeValid = false;
      else
        this.isPostcodeValid = true;
    }
  }

  getTemplate() {
    this.showSpinner = true;
    this.jobService.getJobDetails(this.jobId).subscribe((res: any) => {
      const careType = res?.care_type?.toString();
      this.postingsService.getTemplateData(careType).subscribe((response: any) => {
        this.templates = response;
      }, (errorRes) => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      });
    });

  }

  getPostingDataForJob() {
    this.showSpinner = true;
    this.postingsService.getPostingDataForJob(this.jobId).subscribe((response: any) => {
      this.postings = response?.results;
    }, (errorRes) => {
      this.showSpinner = false;
      this._snackBar.open(errorRes, 'OK', { duration: 5000 });
    });
  }

  checkPostCode(event: any) {
    if (this.postingFormGroup.value.postcode !== 'undefined' && this.postingFormGroup.value.postcode) {
      var target = codes.find(temp => temp == this.postingFormGroup.value.postcode)
      if (target)
        this.isPostcodeValid = false;
      else
        this.isPostcodeValid = true;
    }
  }

  submitPostings() {
    if (this.postingFormGroup.valid == true) {
      this.postingFormGroup.value.job_id = this.jobId;
      this.showSpinner = true;
      if (!this.isEdit) {
        this.addPostings();
      } else {
        this.editPostings();
        this.cdr.detectChanges();
      }
    }
  }

  editPostings() {
    var newdate = new Date(this.postingFormGroup.value.posting_date);
    this.postingFormGroup.value.posting_date = newdate.getFullYear() + '-' + ((newdate || new Date()).getMonth() + 1) + '-' + newdate.getDate();
    this.postingFormGroup.value.job_id = this.jobId;
    this.postingFormGroup.value.title = (this.postingFormGroup.value.costume_title_toogle == true) ? this.postingFormGroup.value.title : "";
    this.postingsService.editPostingData(this.postingFormGroup.value).subscribe((response: any) => {
      this.initForm();
      this.showSpinner = false;
      this.isEdit = false;
    }, (errorRes) => {
      this.showSpinner = false;
      this._snackBar.open(errorRes, 'OK', { duration: 5000 });
    });
  }

  addPostings() {
    this.postingFormGroup.value.job_id = this.jobId;
    var newdate = new Date(this.postingFormGroup.value.posting_date);
    this.postingFormGroup.value.posting_date = newdate.getFullYear() + '-' + ((newdate || new Date()).getMonth() + 1) + '-' + newdate.getDate();
    this.postingFormGroup.value.title = (this.postingFormGroup.value.costume_title_toogle == true) ? this.postingFormGroup.value.title : "";
    this.postingsService.addPostingData(this.postingFormGroup.value).subscribe((response: any) => {
      this.initForm();
      this.showSpinner = false;
    }, (errorRes) => {
      this.showSpinner = false;
      this._snackBar.open(errorRes, 'OK', { duration: 5000 });
    });
  }

  edit(data) {
    this.cdr.detectChanges();
    this.selectedTemplateText = data?.template.title
    
    this.isEdit = true;
    this.postingFormGroup.patchValue({
      id: data.id,
      job_id: this.jobId,
      title: data?.title,
      posting_date: data?.posting_date,
      template_id: data?.template?.id,
      postcode: data?.postcode,
      budget: data?.budget,
      costume_title_toogle: (data?.title !== '') ? true : false
    });
    this.cdr.detectChanges();
    
    if (this.postingFormGroup.value.postcode) {
      var target = codes.find(temp => temp == this.postingFormGroup.value.postcode)
      if (target === undefined) {       
        this.isPostcodeValid = true;
      } else {
        this.isPostcodeValid = false;
      }
    }
  }

  delete(id) {
    this.postingsService.getPostingDelete(id).subscribe((response: any) => {
      this.getPostingDataForJob();
      this._snackBar.open(this.translate.instant('message.posting_deleted'), 'OK', { duration: 5000 });
      this.showSpinner = false;
    }, (errorRes) => {
      this.showSpinner = false;
      this._snackBar.open(errorRes, 'OK', { duration: 5000 });
    });
    this.cdr.detectChanges();

  }

  getSelectedName(event: any) {
    this.selectedTemplateText = event;
    this.postingFormGroup.patchValue({
      title: (this.postingFormGroup.value.costume_title_toogle == true) ? this.selectedTemplateText : this.postingFormGroup.value.title,
    });
    this.cdr.detectChanges();
  }

  setTitle(event: any) {
    this.postingFormGroup.patchValue({
      title: (event.checked == true && this.postingFormGroup.value.title == '') ? this.selectedTemplateText : this.postingFormGroup.value.title,
      costume_title_toogle: event.checked,
    });
    this.cdr.detectChanges();
  }
}
