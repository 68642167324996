import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router,RouterModule } from "@angular/router";
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isLoginPage = false;
  isAjgentLogin = false;
  isACuserLogin = false;
  @Output() isLogin = new EventEmitter<any>();
  modeselect = '';
  isMobile = false;
  constructor(private msalService: MsalService, private router: Router,private route:ActivatedRoute,
    private translateConfigService: TranslateConfigService, private deviceService: DeviceDetectorService) {
    if (sessionStorage.getItem('lang') === 'null') {
      this.modeselect = 'de';
      sessionStorage.setItem('lang', 'de');
    } else {
      this.modeselect = sessionStorage.getItem('lang');
      sessionStorage.setItem('lang', sessionStorage.getItem('lang'));
    }
    this.translateConfigService.changeLanguage(this.modeselect);
    this.router.events.subscribe((event) => {
      if(this.router.routerState.snapshot.url == "/login" || this.router.routerState.snapshot.url == "/register" || this.router.routerState.snapshot.url.includes("/login") || this.router.routerState.snapshot.url.includes("/alogin")){
        this.isLoginPage = false;
      }else{
          this.isLoginPage = true;
      }
      if (localStorage.getItem("agentData") && localStorage.getItem("agentData") != null && localStorage.getItem("agentData") != null) {
        this.isAjgentLogin = true;
      }
      if (localStorage.getItem("companyData") && localStorage.getItem("companyData") != null && localStorage.getItem("companyData") != null) {
        this.isACuserLogin = true;
      }

    });
    
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  changeLang(event) {
    this.translateConfigService.changeLanguage(event.value);
  }

  logout() {

    this.isLogin.emit(false);
    if (localStorage.getItem("agentData") && localStorage.getItem("agentData") != null && localStorage.getItem("agentData") != null) {
      localStorage.removeItem('msalAccount');
      localStorage.removeItem('agentData');
      this.isAjgentLogin = false;
      setTimeout(() => {
        this.logout();
        this.msalService.logout();
        this.router.navigate(['alogin']);
      }, 100);
    } else if (localStorage.getItem("companyData")) {
      this.isACuserLogin = false;
      localStorage.removeItem('companyData');
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['login']);
      // this.router.navigate([this.router.url]);
    }

  }

}
