<div class="bar">
    <form style="margin-top:50px" [formGroup]="postingFormGroup" (ngSubmit)="submitPostings()">
        <mat-form-field [class.show]="costumTitle.checked" class="costum-title">
            <input matInput formControlName="title" placeholder="Titel einfügen">
        </mat-form-field>
        <div class="date-field-con">
            <mat-form-field class="template">
                <mat-label translate>postiongs.template_name</mat-label>
                <!-- (selectionChange)="getSelectedName($event)" -->
                <mat-select formControlName="template_id">
                    <mat-option *ngFor="let template of templates" (click)="getSelectedName(template.title)"
                        [value]="template.id">
                        {{template.type | CareTypeAbbreviations}} | {{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="posting_date">
                <mat-label translate>postiongs.posting_date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="posting_date" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <mat-form-field class="plz">
            <mat-label translate>postiongs.Zip_code</mat-label>
            <input matInput formControlName="postcode" type="text" (ngModelChange)="checkPostCode($event)"
                (focus)="checkPostCode($event)" (change)="checkPostCode($event)">
                <mat-hint *ngIf="isPostcodeValid && postingFormGroup.controls['postcode'].value !== ''" class="postcode">
                    <strong translate>message.postcode_invalid</strong>
                </mat-hint>
        </mat-form-field>
        <!-- <mat-error  class="invalid-post">
            <strong>Die</strong>
        </mat-error> -->
        <!-- <mat-label translate>postiongs.Zip_code</mat-label> -->

        <mat-form-field class="budget">
            <mat-label translate>postiongs.budget</mat-label>
            <mat-select formControlName="budget">
                <mat-option *ngFor="let budget of budgets" [value]="budget.value">
                    {{ budget.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-slide-toggle [checked]="[postingFormGroup?.value?.title != '' ? true : false]" #costumTitle
            formControlName="costume_title_toogle" (change)="setTitle($event)" postingForm.value.title class="toggle"
            matTooltip="Costum Titel">
        </mat-slide-toggle>
        <button type="submit" mat-flat-button color="primary" class="submit-btn">{{ (isEdit ? 'postiongs.btn_edit' :
            'postiongs.btn_send') |translate }}</button>

    </form>
    <mat-divider style="margin-top: 20px;"></mat-divider>
    <mat-card class="table-data" *ngFor="let post of postings;">
        <div class="content">
            <ng-container *ngIf="post.title!=''">
                {{post.title}}<br>
            </ng-container>
            <ng-container *ngIf="post.title==''">
                {{post.template.title}}<br>
            </ng-container>
            {{post.postcode}} | {{post.template.name}} | {{post.created_at | date:'dd.MM.yyyy'}} | {{post.budget}}
        </div>
        <div class="card-image">
            <mat-icon (click)="edit(post)">edit</mat-icon>
            <mat-icon [matMenuTriggerFor]="menu">delete</mat-icon>
            <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                <button mat-menu-item (click)="delete(post.id)">
                    <mat-icon>delete</mat-icon>
                    <span translate>postiongs.btn_delete</span>
                </button>
            </mat-menu>
        </div>
    </mat-card>
    <ng-container *ngIf="!postings || !templates">
        <ngx-skeleton-loader count="7" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
    </ng-container>
    <ng-container *ngIf="postings.length==0">
        <h3 class="drawer-title" style="text-align:center;margin:20px">{{ 'postiongs.no_record' | translate}}</h3>
    </ng-container>
</div>
