import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService } from 'src/app/services/job.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
})
export class CompanyHomeComponent implements OnInit {
  companies: Array<any> = [];
  selectedCompanyId: any;
  screenHeight : number;
  companyForm: FormGroup;
  companyDetails: string = '';
  search = new FormControl();
  comName = new FormControl();
  names: string = '';
  mymodel: string = '';
  selectedOptions: number[];
  companyId: number;
  page: number = 1;
  url: string;
  nextPage: string;
  noOfItemsToShowInitially: number = 5;
  itemsToLoad: number = 5;
  // itemsToShow = this.items.slice(0, this.noOfItemsToShowInitially);
  // No need to call onScroll if full list has already been displayed
  isFullListDisplayed: boolean = false;

  constructor(
    private jobService: JobService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
      this.screenHeight = (window.innerHeight - 10);
  }

  ngOnInit(): void {
    this.companyForm = this._formBuilder.group({
      search: [],
    });
    this.getCompanyUser();
  }

  scrollHandler() {
    
  }

  getCompanyUser() {
    if(this.nextPage === null){
      return 
    }

    var cName = this.companyForm.value.search
      ? this.companyForm.value.search
      : '';
    if (cName.length == 0 || cName.length > 2) {
      this.jobService.getCompaniesByName(cName, this.page).subscribe((response: any) => {
        this.nextPage = response.next
        if(this.page > 1){
          this.companies =  this.companies.concat(response.results)
        } else{
          this.companies = response.results;
        }
        if (this.route.params['_value'].id) {
          this.route.params.subscribe((params) => {
            this.companyId = +params['id'];
          });
        } else {
          this.companyId = this.companies[0]?.id;
        }
        this.companyDetails = this.companies[0];
        this.selectedOptions = this.companyId
          ? [this.companyId]
          : [this.companies[0]?.id];
        this.location.go('companies/' + this.companyId);
      });
    }
  }

  selectedCompany(cName, company_id) {
    this.location.go('companies/' + company_id);
    this.companyDetails = cName;
  }

  refreshCompany(companyArray) {
    var target = this.companies.findIndex(
      (temp) => temp?.id == companyArray?.id
    );
    this.companies[target] = companyArray;
  }

  onScroll() {
    if (this.noOfItemsToShowInitially <= this.companies.length) {
      this.page = this.page + 1;
      this.getCompanyUser()
    } else {
      this.isFullListDisplayed = true;
    }
  }
}
