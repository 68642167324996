import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LOADIPHLPAPI } from 'dns';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  ImageCroppedEvent,
  LoadedImage,
  base64ToFile,
} from 'ngx-image-cropper';
import { JobService } from 'src/app/services/job.service';
import { AddCompanyUserComponent } from '../../common/add-company-user/add-company-user.component';
import {
  CompanySidebarComponent,
  DialogData,
} from '../../common/company-sidebar/companysidebar.component';
import { UploadImageDialogComponent } from '../../common/upload-image-dialog/upload-image-dialog.component';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
})
export class CompanyDetailComponent implements OnInit, OnChanges {
  // @Input('companyId') companyId: number;
  @Input('companyDetails') companyDetails: any;

  @Output() refreshCompany = new EventEmitter<any>();
  dataSource: any;
  isChecked: boolean;
  // companyData: any;
  @ViewChild('drawer') drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  isCompanyEdit: boolean = false;

  columnsToDisplay = [
    'name',
    'email',
    'phone',
    'gender',
    'contactuser',
    'action',
  ];
  match: any;
  companyDetailsForm: FormGroup;
  company_presentation: any;
  company_benefit: any;
  presentationDetails: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  companyForm: FormGroup;
  // companyDetails: any;
  usertype: any;
  userRole: number;
  companyUser: any;
  user_id: any;
  isSpinner: boolean = false;
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  dropdownUserSettings: IDropdownSettings;
  dropdownSingleSettings: IDropdownSettings;
  dropdownAgentList = [];
  selectedUser = [];
  selectedAgent = [];
  companyUsers = [];
  companyAgent = [];
  images: any;
  logo_png: string;
  logo_webp: string;
  gallery_png: string;
  gallery_webp: string;
  companyId: number;

  constructor(
    private jobService: JobService,
    private _formbuilder: FormBuilder,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    let userData = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    this.userRole = userData.role;
  }

  ngOnInit(): void {
    this.initForm();
    this.getCompanyUser();
    //
    this.initEditForm();
    this.getCompanyDetails();

    
    this.route.params.subscribe(params => {
      this.companyId = +params['id'];
    });

    // this.getCompanyEditUser();
  }

  ngOnChanges() {
    var str =  window.location.pathname;
    this.companyId = +str.substring(str.lastIndexOf("/") + 1, str.length);
    this.cdref.detectChanges();
    this.getCompanyUser();
    this.getCompanyPresentation();
    this.isChecked = this.companyDetails.sales;
    this.isCompanyEdit = false;
    if (this.companyId) {
      this.getCompanyDetails();
      this.getImageData('logo');
      this.getImageData('gallery');
    }
    // this.getCompanyEditUser();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  initForm() {
    this.companyDetailsForm = new FormGroup({
      // company_size: new FormControl(null),
      company_presentation: new FormControl(null),
      benefits: new FormControl(null),
    });
  }

  getCompanyUser() {
    if (this.companyId) {
      this.jobService
        .getCompanyUser({ company_id: this.companyId })
        .subscribe((res: any) => {
          this.dataSource = res;
        });
    }
  }

  edit(element) {
    this.cdref.detectChanges();
    this.isCompanyEdit = true;
    this.match = element;
    this.getCompanyPresentation();
    this.getCompanyDetails();
  }

  getCompanyPresentation() {
    if (this.companyId) {
      this.jobService
        .getCompanyPresentation(this.companyId)
        .subscribe((res: any) => {
          this.presentationDetails = res;
        });
    }
  }

  updatePresentation($event) {
    this.company_presentation = $event.company_presentation;
  }

  updateBenefit($event) {
    this.company_benefit = $event.benefits;
  }


  ///
  initEditForm() {
    this.companyForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      agent_user: new FormControl(''),
      website: new FormControl('', Validators.pattern(this.reg)),
      usertype: new FormControl('new'),
      external_name: new FormControl(''),
      phone_number: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      email: new FormControl(''),
      gender: new FormControl(''),
      about: new FormControl(''),
      sales: new FormControl(false),
      company_size: new FormControl(null),
      company_presentation: new FormControl(null),
      benefits: new FormControl(null),

    });

    if (this.userRole == 2) {
      this.companyForm.controls['agent_user'].setValidators([
        Validators.required,
      ]);
      this.companyForm.controls['agent_user'].updateValueAndValidity();
    }

    this.dropdownUserSettings = {
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.dropdownSingleSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'W�hlen Sie Alle',
      unSelectAllText: 'Alles wiederufen',
      itemsShowLimit: 6,
      enableCheckAll: false,
      allowSearchFilter: true,
    };
    this.getAgentList();
  }
  getAgentList() {
    this.dropdownAgentList = [];
    this.jobService.getAgent(false).subscribe((res: any) => {
      this.companyAgent = res;
      for (let agent of this.companyAgent) {
        this.dropdownAgentList.push({
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        });
      }
      this.dropdownAgentList = new Array(this.dropdownAgentList);
    });
  }

  // getCompanyEditUser() {
  //   if (!isNaN(this.companyId)) {
  //     this.jobService.getCompanyUser({ company_id: this.companyId }).subscribe((res: any) => {
  //       this.dataSource = res;
  //     });
  //   }
  // }

  getCompanyDetails() {
    let sesstionResult = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    let userArr = [];
    if (!isNaN(this.companyId)) {
      this.isSpinner = true;
      this.companyForm?.get('companyName').clearValidators();
      this.companyForm?.get('companyName').updateValueAndValidity();
      this.jobService
        .getCompanyDetails(this.companyId)
        .subscribe((res: any) => {
          this.companyDetails = res;
          if (this.companyDetails?.user) {
            for (let usr of this.companyDetails?.user) {
              if (sesstionResult.id !== usr?.id) {
                // this.user_id = (this.user_id != undefined) ? this.user_id + ',' : '' + usr.id
                let userObj = {
                  item_id: usr.id,
                  item_text: usr?.first_name + ' ' + usr?.last_name,
                };
                userArr.push(userObj);
              }
            }
            this.selectedUser = userArr;
            this.companyForm.patchValue({ companyName: userArr });
          }
          this.selectedAgent = [];
          if (this.companyDetails?.agent_user) {
            let agentObj = {
              item_id: this.companyDetails?.agent_user?.id,
              item_text:
                this.companyDetails?.agent_user?.first_name +
                ' ' +
                this.companyDetails?.agent_user?.last_name,
            };
            this.selectedAgent.push(agentObj);
            this.companyForm.patchValue({ agent_user: this.selectedAgent });
          }

          this.companyForm.patchValue({
            id: this.companyDetails.id,
            name: this.companyDetails.name,
            website: this.companyDetails.website,
            external_name: this.companyDetails.external_name,
            phone_number: this.companyDetails.phone_number,
            about: this.companyDetails.about,
            gender: this.companyDetails.gender,
            sales: this.companyDetails.sales,
            // usertype: 'existing',
            company_size: this.companyDetails.company_size,
          });
          this.isSpinner = false;
          this.companyDetailsForm.patchValue({
          // company_size: res.company_size,
          company_presentation: this.companyDetails.company_presentation,
          benefits:  this.companyDetails.benefits,
        });
        this.company_presentation = this.companyDetails.company_presentation,
        this.company_benefit = this.companyDetails.benefits
        });
    }
  }
  updateContactUser(user_id, $event) {
    let data = {
      company_id: +this.companyId,
      user_id: user_id,
      contact_user: $event.checked,
    };

    this.jobService.updateContactUserStatus(data).subscribe((res) => {
      this.getCompanyUser();
    });
  }

  doUpdateCompany($event) {
    if ($event) {
      $event.target.parentElement.disabled = true;
      $event.target.parentElement.classList.add('mat-button-disabled');
    }

    let loginUser = JSON.parse(localStorage.getItem('agentData'));
    const cid = !isNaN(this.companyId) ? this.companyId : loginUser.id;
    let userList = [];
    for (let user of this?.selectedUser) {
      userList.push(user.item_id);
    }

    if (this.companyForm.value.usertype == 'new') {
      const sesstionResult = JSON.parse(localStorage.getItem('agentData'));

      if (this.user_id) {
        userList.push(this.user_id);
      }

      const companyUpdateArray = [];
      if (this.userRole == 2) {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],
          user: userList,
          sales: this.companyForm.value?.sales,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
        });
      } else {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          user: userList,
          sales: false,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.refreshCompany.emit(response);
            this.companyDetails = response;

            this.isChecked = response.sales;
            this.getCompanyUser();
            this.getAgentList();
            this.getCompanyDetails();
            this.getImageData('logo');
            this.getImageData('gallery');
              this.isCompanyEdit = false;
            if ($event) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
              this._snackBar.open(
                this.translate.instant('message.company_details_update'),
                '',
                { duration: 5000 }
              );
            }
          },
          (errorRes) => {
            this._snackBar.open(
              errorRes,
              this.translate.instant('message.btn_ok'),
              { duration: 5000 }
            );
          }
        );
    } else {
      const companyUpdateArray = [];
      if (this.userRole == 2) {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          agent_user: this.companyForm.value?.agent_user[0]['item_id'],
          user: userList,
          sales: this.companyForm.value?.sales,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
        });
      } else {
        companyUpdateArray.push({
          name: this.companyForm.value.name,
          website: this.companyForm.value.website,
          phone_number: this.companyForm.value.phone_number,
          about: this.companyForm.value.about,
          external_name: this.companyForm.value.external_name,
          user: userList,
          sales: false,
          company_size: this.companyForm.value?.company_size,
          company_presentation: this.company_presentation,
          benefits: this.company_benefit,
        });
      }
      this.jobService
        .updateCompanyDetailsV2(cid, companyUpdateArray[0])
        .subscribe(
          (response: any) => {
            this.refreshCompany.emit(response);
            this.companyDetails = response;

            this.isChecked = response.sales;
            this.getCompanyUser();
            this.getAgentList();
            this.getCompanyDetails();
            this.getImageData('logo');
            this.getImageData('gallery');
            // if (isClose) {
            //   this.updateAndClose.emit(response);
            // } else {
            //   this.update.emit(response);
            // }
            // this.isCompanyEdit = false;
              this.isCompanyEdit = false;
            if ($event) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
            }
          },
          (errorRes) => {
            if ($event) {
              $event.target.parentElement.disabled = false;
              $event.target.parentElement.classList.remove(
                'mat-button-disabled'
              );
              this._snackBar.open(
                errorRes,
                this.translate.instant('message.btn_ok'),
                { duration: 5000 }
              );
            }
          }
        );
    }
  }

  // resetData() {
  //   this.close.emit();
  // }

  getImageData(type) {
    this.jobService.getCompanyImage(this.companyId, type).subscribe((res) => {
            if(type == 'logo'){ 
        if(!res['company_logo_url'].png){
        }
        [this.logo_png] = !res['company_logo_url'].png ? "" : res['company_logo_url']?.png ;
        [this.logo_webp] = !res['company_logo_url'].png ? "" : res['company_logo_url']?.webp ;
      }
      else if(type == 'gallery'){
      this.gallery_png = !res['company_gallery_url']?.png ? [] : res['company_gallery_url']?.png;
      this.gallery_webp = !res['company_gallery_url']?.png ? [] : res['company_gallery_url']?.webp;
      }
      
    });
  }

  deleteImage(imageUrl, type) {

    var imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1, imageUrl.length);
    this.jobService.deleteImage(this.companyId, imageName, type).subscribe((res) => {

      this._snackBar.open(
        'Bild erfolgreich gelöscht',
        '',
        { duration: 5000 }
      );
      if(type == 'logo'){ 
        this.getImageData('logo')
      }
      else if(type == 'gallery'){
        this.getImageData('gallery')
      }

    },
    (errorRes) => {
      this._snackBar.open(
        errorRes,
        '',
        { duration: 5000 }
      );
    })
  }

  addCompanyDialog() {
    this.cdref.detectChanges();
    const dialogRef = this.dialog.open(AddCompanyUserComponent, {
      data: {
        editMode: true,
        companyForm: this.companyForm,
        usertype: this.usertype,
        selectedUser: this.selectedUser,
        dropdownUserSettings: this.dropdownUserSettings,
        isSpinner: this.isSpinner,
        companyUsers: this.companyUsers,
        user_id: this.user_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedUser = result?.selectedUser ? result?.selectedUser : [];
        this.user_id = result?.user_id ? result?.user_id : '';
        this.doUpdateCompany('');
        this.companyForm.patchValue({
          usertype: result?.usertype,
          companyName: result?.selectedUser ? result?.selectedUser : '',
        });
        result?.usertype == 'existing'
          ? this.companyForm.controls['companyName'].setValidators([
              Validators.required,
            ])
          : this.companyForm.controls['companyName'].clearValidators();
        this.companyForm.controls['companyName'].updateValueAndValidity();
      }
    });
  }

  uploadImageDialog(type) {
    this.cdref.detectChanges();
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      data: {
        type: type,
        companyId: this.companyId,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {
        if(result){
        if(result['type'] == 'logo'){ 
          this.getImageData('logo')
        }
        else if(result['type'] == 'gallery'){
          this.getImageData('gallery')
        }
      }
    });
  }
}
























