<mat-drawer-container class="container" [hasBackdrop]="true">
  <div class="row filters-row" style="margin-top: 10px;">
    <div class="col filters-col">
      <mat-form-field appearance="legacy">
        <input matInput ngx-google-places-autocomplete [options]='options' [placeholder]="'search-job.placeholder_title' | translate" #input
          (onAddressChange)="AddressChange($event)" (change)="AddressChange($event)" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  
  <div class="example-element-detail">
    <!-- match table section -->
    <section *ngIf="innerDataSource.data.length > 0" class="match-section">
      <mat-table cdkDropList [dataSource]="innerDataSource" class="match-table" matSort
        [cdkDropListData]="innerDataSource.data" (cdkDropListDropped)="drop($event)">

        <ng-container matColumnDef="companyname">
          <mat-header-cell *matHeaderCellDef translate> home.col_companyname </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
            {{element.company.name + ' ('+element.postcode +' '+ element.city +')'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caretype">
          <mat-header-cell *matHeaderCellDef translate> home.col_caretype</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
            <tag *ngFor="let ct of element.care_type">{{ct |
              CareTypeAbbreviations}}</tag>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shift">
          <mat-header-cell *matHeaderCellDef translate>home.col_shift</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
            <tag *ngFor="let shift of element?.shift">
              {{shift|ShiftAbbreviations}}</tag>
            <tag *ngIf="element.shift_other">{{element.shift_other}}</tag>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="special_requirements">
          <mat-header-cell *matHeaderCellDef translate>home.col_special_req</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
            <tag *ngFor="let sr of element?.special_requirements">{{sr | SpecialRequirementsById}}</tag>
            <tag *ngFor="let sr of element?.specification_care_type" [color]="secondary">{{sr |
              SpecialCareTypeById}}</tag>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="note">
          <mat-header-cell *matHeaderCellDef translate> home.col_note </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div matRipple class="drag-drop-aceholder" *cdkDragPlaceholder></div>
            {{ element?.note }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="innerColumnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let element; columns: innerColumnsToDisplay;" (mouseenter)="updateColor(element.id)"
          (mouseleave)="updateColorR(element.id)" [class.hover]="hover.isSelected(element.id)" [class.inactive]="!element?.is_active"></mat-row>
      </mat-table>
    </section>

    <!-- map section -->
    <section *ngIf="innerDataSource.data.length > 0" class="map-section">
      <agm-map [zoom]="9" [latitude]="lat" [longitude]="lng">
        <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false" [iconUrl]="getMarker('28605B')">
        </agm-marker>
        <agm-marker *ngFor="let m of innerDataSource.filteredData" [latitude]="m.latitude" [longitude]="m.longitude"
          (markerClick)="selectRow(m.id)" [iconUrl]="getMarkerUrl(m.id)" (mouseOver)="updateColor(m.id)"
          (mouseOut)="updateColorR(m.id)">
        </agm-marker>
      </agm-map>
    </section>
  </div>

  <div *ngIf="innerDataSource.data.length == 0" style="width: 100%;">
    <div *ngIf="isSpinner" class="example-element-detail">
      <section class="match-section">
        <div style="margin-top:2%">
          <ngx-skeleton-loader count="5" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
        </div>
      </section>
      <section class="map-section" style="width:500px">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{'height.px': 300}"></ngx-skeleton-loader>
      </section>
    </div>
    <ngx-skeleton-loader *ngIf="isSpinner" count="1" appearance="line" [theme]="{'height.px': 50}">
    </ngx-skeleton-loader>
    <h1 *ngIf="!isSpinner && searchString != ''" style="text-align: center; margin-top: 10px;">{{ 'search-job.no_record' | translate }}</h1>
    <h1 *ngIf="!isSpinner && searchString == ''" style="text-align: center; margin-top: 10px;">{{ 'search-job.no_search' | translate }}</h1>
  </div>

</mat-drawer-container>