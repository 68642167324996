<div *ngIf="agentDetails" style="float: right" >
    <button mat-icon-button [mat-dialog-close]="agentDetails" cdkFocusInitial>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="agentDetails" style="padding: 50px;">
      <mat-dialog-content *ngIf="agentDetails" style="text-align: center; ">
        <h1>{{agentDetails?.first_name}} </h1>
          <h1>{{agentDetails?.last_name}}</h1>
      </mat-dialog-content>
      <div style="height: 50px"></div>
      <div style="border-bottom: 2px solid blue; padding-bottom: 4px;display: flex;">
        <div *ngIf="agentDetails?.phone_number" style="display: inline-block;text-align: center;margin: 0px 19px 10px 10px;align-items: center;display: flex;">
          <a class="dashboard-link" style="display: flex;align-items: center;text-decoration: none;" href="tel:{{agentDetails?.phone_number}}">
            <mat-icon style="padding-right: 5px;">phone</mat-icon>{{agentDetails?.phone_number}}</a>
          <mat-icon
            (click)="CopyDetails(agentDetails?.phone_number)"
            [cdkCopyToClipboard]="agentDetails?.phone_number"
            style="padding-left: 3px;font-size: 20px;cursor:pointer;"
            >content_copy</mat-icon
          >
        </div>
        <div *ngIf="agentDetails?.email" style="display: inline-block;text-align: center;margin: 0px 19px 10px 10px;align-items: center;display: flex;">
          <a class="dashboard-link" style="display: flex;align-items: center;text-decoration: none;" href="mailto:{{agentDetails?.email}}">
            <mat-icon style="padding-right: 5px;">mail</mat-icon>{{agentDetails?.email}}</a>
          <mat-icon
            (click)="CopyDetails(agentDetails?.email)"
            style="padding-left: 3px;font-size: 20px;cursor:pointer;"
            [cdkCopyToClipboard]="agentDetails?.email"
            >content_copy</mat-icon
          >
        </div>
      </div>
    </div>
    <div *ngIf="!agentDetails">
      <div style="float: right; margin: -23px">
        <button mat-icon-button [mat-dialog-close]="agentDetails" cdkFocusInitial>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div style="margin: 15px;font-size: 15px;">
        <mat-dialog-content>Benutzerdetails des Agenten nicht verfügbar</mat-dialog-content>
      </div>
    </div>
    