<div class="header-btn">
  <h1>
    <span class="comname">{{ companyDetails.name }}</span>
    <span *ngIf="companyDetails && !isCompanyEdit">
      <button
        mat-raised-button
        color="primary"
        class="save"
        (click)="edit(companyDetails)"
      >
        {{ "company.edit_button" | translate }}
      </button>

      <button
        mat-raised-button
        class="btn-add-user"
        color="Default"
        (click)="addCompanyDialog()"
      >
        {{ "create_job.add_user" | translate }}
      </button>
    </span>
    <span *ngIf="companyDetails && isCompanyEdit">
      <button mat-flat-button color="primary" class="save" (click)="doUpdateCompany($event)"
        [disabled]="companyForm.invalid">
        {{ "jobs.btn_save" | translate }}
      </button>
      <button mat-raised-button color="Default" class="save" (click)="isCompanyEdit = false">
        Abbrechen
      </button>
      &nbsp;
    </span>
  </h1>
</div>
<div class="content">
  <div class="company-edit">
    <!------------------------------------------------- Company Edit Mode ------------------------------------------->

    <span *ngIf="companyDetails && isCompanyEdit">
      <div *ngIf="!isSpinner" class="job-list-div">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="companyForm">
              <table class="edit-form">
                <tr>
                  <td class="label-td" translate>
                    create_job.name <span class="is-validate">*</span>
                  </td>
                  <td class="edit-td">
                    <mat-form-field appearance="outline">
                      <mat-label translate>create_job.name</mat-label>
                      <input matInput formControlName="name" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="label-td" translate>company.external_name</td>
                  <td class="edit-td">
                    <mat-form-field appearance="outline">
                      <mat-label translate>company.external_name</mat-label>
                      <input matInput formControlName="external_name" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="label-td" translate>create_job.website</td>
                  <td class="edit-td">
                    <mat-form-field appearance="outline">
                      <mat-label translate>create_job.website</mat-label>
                      <input matInput formControlName="website" />
                    </mat-form-field>
                    <mat-error *ngIf="
                        companyForm.invalid &&
                        (companyForm.get('website').invalid ||
                          companyForm.get('website').dirty ||
                          companyForm.get('website').touched)
                      ">
                      Please enter correct website url</mat-error>
                  </td>
                </tr>

                <tr>
                  <td translate>
                    create_job.agent_user <span class="is-validate">*</span>
                  </td>
                  <td>
                    <div class="auser-drpdwn">
                      <ng-multiselect-dropdown formControlName="agent_user" class="agent_user" placeholder="{{
                          'validation.select_agent' | translate
                        }}" [settings]="dropdownSingleSettings" [data]="dropdownAgentList[0]"
                        [(ngModel)]="selectedAgent" style="flex: 1">
                      </ng-multiselect-dropdown>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="label-td phone-number" translate>
                    create_job.phone_number
                  </td>
                  <td class="edit-td phone-number">
                    <mat-form-field appearance="outline">
                      <mat-label translate>create_job.phone_number</mat-label>
                      <input matInput formControlName="phone_number" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="label-td" translate>create_job.about</td>
                  <td class="edit-td">
                    <mat-form-field appearance="outline">
                      <mat-label translate>create_job.about</mat-label>
                      <input matInput formControlName="about" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td class="label-td" translate>company.company_size</td>
                  <td class="edit-td">
                    <mat-form-field appearance="outline">
                      <mat-label translate>company.company_size</mat-label>
                      <input matInput formControlName="company_size" />
                    </mat-form-field>
                  </td>
                </tr>
                <tr *ngIf="userRole == 2">
                  <td class="label-td" translate>create_job.sales</td>
                  <td class="edit-td">
                    <mat-slide-toggle formControlName="sales" color="primary" class="mt-2 w-100">
                    </mat-slide-toggle>
                  </td>
                </tr>

                <tr>
                  <td class="label-td" translate>Logo</td>
                  <td class="edit-td">
                    <div class="logo">
                      <div *ngIf="!logo_png">
                        <div class="logo-margin">
                          <button
                            mat-mini-fab
                            color="Default"
                            class="add-logo"
                            (click)="uploadImageDialog('logo')"
                          >
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="view-image" *ngIf="logo_png">
                        <div class="delete-image" >
                          <button
                            mat-mini-fab
                            color="Default"
                            (click)="deleteImage(logo_png, 'logo')"
                            style="height: 30px; width: 30px"
                          >
                            <mat-icon style="font-size: 19px;">close</mat-icon>
                          </button>
                        </div>
                        <div class="display-img">
                          <!-- <img src="{{logo_png}}" style="height: 100px; width: 100px" /> -->
                          <picture>
                            <source srcset="{{logo_webp}}" type="image/webp" >
                            <source srcset="{{logo_png}}" type="image/png">
                            <img src="{{logo_png}}" style="height: 100px; width: 100px" alt="logo">
                          </picture>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <!-- <tr>
                  <td class="label-td" translate>Images</td>
                  <td class="edit-td">
                    <button
                      mat-raised-button
                      color="Default"
                      (click)="uploadImageDialog('Images')"
                    >
                      upload
                    </button>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td class="label-td" translate>Images</td>
                  <td class="edit-td">
                    <div class="logo">
                      <div class="logo-margin">
                        <button
                          mat-mini-fab
                          color="Default"
                          class="add-logo"
                          (click)="uploadImageDialog('gallery')"
                        >
                          <mat-icon>add</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div class="view-image">
                      <div class="delete-image">
                        <button
                          mat-mini-fab
                          color="Default"
                          (click)="deleteImage(gallery_image, 'gallery')"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                      <div class="display-img">
                        <img src="{{gallery_image}}" style="height: 100px; width: 100px" />
                      </div>
                    </div>
                  </td>
                </tr> -->

                <tr>
                  <td class="label-td" translate>Images</td>
                  <td class="edit-td">
                    <div class="logo">
                      <div>
                        <div class="logo-margin">
                          <button
                            mat-mini-fab
                            color="Default"
                            class="add-logo"
                            (click)="uploadImageDialog('gallery')"
                          >
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="view-image" *ngFor="let image_png of gallery_png; let image_webp of gallery_webp;">
                        <div class="delete-image">
                          <button
                            mat-mini-fab
                            color="Default"
                            (click)="deleteImage(image_png, 'gallery')"
                            style="height: 30px; width: 30px"
                          >
                            <mat-icon style="font-size: 19px;">close</mat-icon>
                          </button>
                        </div>
                        <div class="display-img" >
                          <!-- <img src="{{image}}" style="height: 100px; width: 100px" /> -->
                          <picture>
                            <source srcset="{{image_webp}}" type="image/webp" >
                            <source srcset="{{image_png}}" type="image/png">
                            <img src="{{image_png}}" style="height: 100px; width: 100px" alt="gallery">
                          </picture>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

              </table>
            </form>
          </mat-card-content>
        </mat-card>
        <ng-template let-data> Hello, {{ data.name }} </ng-template>
      </div>
      <ng-container *ngIf="isSpinner">
        <ngx-skeleton-loader count="2" appearance="line" [theme]="{ 'height.px': 100 }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="5" appearance="line" [theme]="{ 'height.px': 50 }"></ngx-skeleton-loader>
      </ng-container>
    </span>

    <!------------------------------------------------ Company View Mode ------------------------------------------->

    <table class="table">
      <tr>
        <mat-card *ngIf="companyDetails && !isCompanyEdit">
          <mat-list>
            <mat-list-item>
              <span class="company-title">{{ "company.external_name" | translate }}:</span>
              <span class="company-value">{{
                companyDetails.external_name
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">{{ "create_job.website" | translate }}:
              </span>
              <span class="company-value">{{
                companyDetails.website
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">
                {{ "create_job.agent_user" | translate }}:
              </span>
              <span class="company-value">{{ companyDetails.agent_user?.first_name }}&nbsp;{{
                companyDetails.agent_user?.last_name
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">
                {{ "create_job.phone_number" | translate }}:</span>
              <span class="company-value">{{
                companyDetails.phone_number
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">
                {{ "create_job.about" | translate }}:</span>
              <span class="company-value">{{
                companyDetails.about
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">{{ "company.company_size" | translate }}:</span>
              <span class="company-value">{{
                companyDetails.company_size
                }}</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item><span class="company-title">{{ "create_job.sales" | translate }}:</span>
              <span class="company-value">
                <mat-slide-toggle color="primary" class="mt-2 w-100" [disabled]="true" value="isChecked"
                  [(ngModel)]="isChecked">
                </mat-slide-toggle>
              </span>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-list>
        </mat-card>

        <ng-container *ngIf="!companyDetails">
          <ngx-skeleton-loader count="6" appearance="line" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
      </tr>

      <!----------------------------------------------- Company User Table --------------------------------------------->

      <tr *ngIf="companyDetails && !isCompanyEdit">
        <mat-card>
          <mat-table
            class="mat-elevation-z8 table-margin"
            mat-table
            multiTemplateDataRows
            [dataSource]="dataSource"
          >
            <!--add the company name-->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.col_name
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name }}
              </mat-cell>
            </ng-container>

            <!--add the job title-->
            <ng-container matColumnDef="contactuser">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.col_contact_user
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon>{{element?.contactuser === true ? 'done' : 'close'}}</mat-icon>
              </mat-cell>
            </ng-container>

            <!-- show email and phone number -->

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.email
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element?.email }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="phone">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.phone_number
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element?.phone }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="gender">
              <mat-header-cell *matHeaderCellDef translate>
                create_job.gender
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element?.gender == 1 ? "männlich" : "Weiblich" }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef translate>
                action
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  class="action-icon ml-2"
                  [matMenuTriggerFor]="menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                  <!-- <button mat-menu-item>
                  <mat-icon>share</mat-icon>
                  <span translate>jobs.postings</span>
                </button> -->
                  <!-- [checked]="element?.is_active === 1" (change)="updateJobStatus(element?.id,$event)" -->
                  <mat-slide-toggle class="company-slide-toggle action-icon" style="margin: 5px 12px 5px 12px"
                    [checked]="element.contactuser" (change)="updateContactUser(element?.id,$event)" color="primary">
                    <span> {{ element?.contactuser ? 'inaktiv' : 'Aktivieren' }}</span>
                  </mat-slide-toggle>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="columnsToDisplay"
            ></mat-header-row>
            <mat-row
              *matRowDef="let element; columns: columnsToDisplay"
            ></mat-row>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="10">
                {{ "message.no_record" | translate }}
              </td>
            </tr>
          </mat-table>
        </mat-card>
      </tr>
    </table>


  </div>
  <div class="company-details">
    <!-------------------------------------------- Company Details Edit Mode ----------------------------------------->

    <div *ngIf="presentationDetails && isCompanyEdit">
      <mat-card>
        <mat-card-content>
          <form
            *ngIf="companyDetailsForm"
            [formGroup]="companyDetailsForm"
            (ngSubmit)="submitCompany()"
          >
            <table class="edit-form details-edit">
              <!-- <tr>
            <div>{{ 'company.company_size' | translate}}</div>

            <mat-form-field appearance="outline">
              <input matInput formControlName="company_size">
            </mat-form-field>

          </tr> -->
              <tr>
                <td translate>
                  {{ "company.company_presentation" | translate }}
                  <app-kolkov-editor id="present" [parentForm]="companyDetailsForm"
                    formControlName="company_presentation" ngDefaultControl (update)="updatePresentation($event)">
                  </app-kolkov-editor>
                </td>
              </tr>
              <tr>
                <td translate>
                  {{ "company.benefits" | translate }}
                  <app-kolkov-editor id="benefits" [parentForm]="companyDetailsForm" formControlName="benefits"
                    (update)="updateBenefit($event)" ngDefaultControl>
                  </app-kolkov-editor>
                </td>
              </tr>
            </table>
          </form>
        </mat-card-content>
      </mat-card>
    </div>

    <!----------------------------------------- Company Details View Mode ------------------------------------------>

    <div *ngIf="companyDetails && !isCompanyEdit" class="details-view">
      <mat-card *ngIf="companyDetails && !isCompanyEdit">
        <div class="present">
          <p>{{ "company.company_presentation" | translate }}:</p>
          <p [innerHtml]="companyDetails.company_presentation"></p>
        </div>
      </mat-card>
      <mat-card class="benefit">
        <div class="present">
          <p>{{ "company.benefits" | translate }}:</p>
          <p [innerHtml]="companyDetails.benefits"></p>
        </div>
      </mat-card>

      <ng-container *ngIf="!companyDetails">
        <ngx-skeleton-loader count="6" appearance="line" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
      </ng-container>

      <mat-card *ngIf="companyDetails && !isCompanyEdit && (logo_png || gallery_png)">
        <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
        <div class="display-img" style="margin: 20px;" *ngIf="logo_png">
          <!-- <img src="{{logo_png}}" style="height: 100px; width: 100px" /> -->
          <picture>
            <source srcset="{{logo_webp}}" type="image/webp" >
            <source srcset="{{logo_png}}" type="image/png">
            <img src="{{logo_png}}" style="height: 100px; width: 100px" alt="logo">
          </picture>
        </div>

        <div class="display-img" style="margin: 20px;" *ngFor="let image_png of gallery_png; let image_webp of gallery_webp;" >
          <!-- <img src="{{image}}" style="height: 100px; width: 100px" /> -->
          <picture>
            <source srcset="{{image_webp}}" type="image/webp" >
            <source srcset="{{image_png}}" type="image/png">
            <img src="{{image_png}}" style="height: 100px; width: 100px" alt="gallery">
          </picture>
        </div>
        </div>


      </mat-card>
    </div>

    <!-- <div *ngIf="presentationDetails && !isCompanyEdit" >



  </div> -->

    <div *ngIf="!companyDetails">
      <ng-container>
        <ngx-skeleton-loader count="6" appearance="line" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
      </ng-container>
    </div>
  </div>


</div>
