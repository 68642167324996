import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicantService } from 'src/app/services/applicant.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';
import { AbbreviationModel } from '../../models/abbreviation.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss']
})
export class SearchJobComponent implements OnInit {

  // @Input('applierId') applierId: any;
  // @Input('lat') lat: any;
  // @Input('lng') lng: any;

  INNER_ELEMENT_DATA = [];
  innerDataSource: any = new MatTableDataSource(this.INNER_ELEMENT_DATA);
  hover = new SelectionModel(true, []);
  innerColumnsToDisplay = ['companyname', 'caretype', 'shift', 'special_requirements', 'note'];
  isSpinner = false;
  searchString : string = '';
  markers = [];
  lat: any = '';
  lng: any = '';
  options = {
    types: ['geocode'],
    componentRestrictions: {
      country: ['de'],
    }
  };
  constructor(
    private applicantService: ApplicantService, private translate: TranslateService,
    private _snackBar: MatSnackBar,) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  AddressChange(address: any) {
    this.searchString = address?.target?.value;
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    if (this.lng || address.formatted_address !== undefined) {
      this.loadMatches();
    }else {
      this.lat =this.lng = '';
      this.innerDataSource = new MatTableDataSource([]);
    }
  }

  loadMatches() {
    this.isSpinner = true;
    this.innerDataSource = new MatTableDataSource();
    this.applicantService.getMatchListByLatLng(this.lat, this.lng).subscribe((response: matchResponse) => {
      this.innerDataSource = new MatTableDataSource(response?.results);
      this.isSpinner = false;
    });
  }

  getMatchStatus(status) {
    return new AbbreviationModel().getById(status,'matchStatus', 'short');
  }

  updateColor(id) {
    this.hover.clear();
    this.hover.select(id);
  }

  updateColorR(id) {
    this.hover.clear();
  }

  getMarkerUrl(id) {
    if (this.hover.isSelected(id)) {
      return this.getMarker('ff4081');
    } else {
      return this.getMarker('a7a7a7');
    }
  }

  getMarker(hash) {
    return `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${hash}`;
  }

}


export interface matchResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}
