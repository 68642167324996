import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { MatchComponent } from '../../match/match.component';
import { DialogData } from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-excel-export-dialog',
  templateUrl: './excel-export-dialog.component.html',
  styleUrls: ['./excel-export-dialog.component.scss']
})
export class ExcelExportDialogComponent implements OnInit {

  dropdown = []
  selectedType: [] = [];
  name: ['']
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dataSource: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  private dialogRef: MatDialogRef<MatchComponent>,
  private historyService: HistoryMatchService
) { 
    this.dataSource = data['dataSource']
    
  }
  
  ngOnInit(): void {
    this.dataSource['status'].length
      ? this.dropdown = [
          'Applier Name',
          'Forwarding Date',
          'Company Name',
          'City',
          'Applier Education',
          'Applier CareType',
          'Status',
          'Description Of Status',
        ]
        : this.dropdown = [
          'Applier Name',
          'Forwarding Date',
          'Applier Education',
          'Applier CareType',
          'Company Name',
          'City',
        ];
        
  
  }
  
 
  
  onSave(){
    var stringValue = "true";
    let status = this.dataSource['status'] ? this.dataSource['status'] : ''
    let company_id = this.dataSource['company_ids'] ? this.dataSource['company_ids'] : ''
    let need_action = this.dataSource['need_action'] === "true" ? "True" : "";

    this.selectedType
    let typeArray = [];
    for(let item of this.selectedType) {
      if(item === 'Applier Name') {
        typeArray.push('applier__name')
      } else
      if(item === 'Forwarding Date') {
        typeArray.push('forwarding_date')
      } else
      if(item === 'Company Name') {
        typeArray.push('company__name')
      } else
      if(item === 'City') {
        typeArray.push('job__city')
      }
      if(item === 'Status') {
        typeArray.push('status_id')
      }
      if(item === 'Description Of Status') {
        typeArray.push('description_of_status')
      }
      if(item === 'Applier Education') {
        typeArray.push('applier__education')
      }
      if(item === 'Applier CareType') {
        typeArray.push('applier__care_type')
      }



      // applier__education,applier__care_type
        // 'Applier Education',
        //   'Applier CareType',
      
      
    }


    this.historyService.getExcelExportData(typeArray, company_id , this.dataSource['start_date'], this.dataSource['end_date'], need_action, status).subscribe((res: any) => {
      const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
    const url= window.URL.createObjectURL(blob);
  window.open(url);

    })

    
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
