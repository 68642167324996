import {
  Component,
  Input,
  EventEmitter,
  ViewChild,
  OnInit,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbbreviationModel } from '../../models/abbreviation.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PostingsService } from 'src/app/services/postings.service';

@Component({
  selector: 'app-postings',
  templateUrl: './postings.component.html',
  styleUrls: ['./postings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
      state('expanded', style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PostingsComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  companies = [];
  ShowFilter: boolean = false;
  companyId: number;
  jobId: number;
  tabId: number;
  isCopy: number;
  postcode: number;
  isJobUpdated: any;
  selectedDateRange: any;
  currentUrl: string;
  previousUrl: string;
  templates: any;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isNewJob = false;
  isDublicate: boolean = false;
  parentCompanyId: 0;
  budgetsArr: any = [{ value: 0, name: "Kein Budget" }, { value: 1, name: "Hoher Klickpreis" }, { value: 2, name: "Mittlerer Klickpreis" }, { value: 3, name: "Geringer Klickpreis" },  { value: 4, name: "Prescreen" }]
  // sidebar and tab stuff
  @ViewChild('drawer') drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // add the datasource
  sorting_order: null;
  search: null;
  curentPage: 0;
  columnsToDisplay = [
    'companyname',
    'PLZ',
    'created',
    'template',
    'caretype',
    'budget',
    'action'
  ];
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.posting(request, query), { property: '' as keyof Sortable, order: 'desc' },
    {
      pincode: '',
        company_ids: '',
        care_type: '',
        posting_templates: '',
        budget: '',
        start_date: '',
        end_date: '',
    }, 2
  )
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dropdownCompanies = [];
  companiesList = [];
  dropdownShiftHoursList = [];
  selectedShiftHoursItems = [];
  dropdownCareTypeList = [];
  dropdownTemplateList = [];
  dropdownEducationAssistance = [];
  dropdownBudgetList = [];
  selectedCareTypeItems = [];
  scopeList = [];
  selectedCompanies = [];
  educationList = [];
  dropdownSpecificationJobCareTypeList = [];
  specialRequirementJobTypeList = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  selectedEducationAssistance = [];
  selectedTemplateItems = [];
  selectedBudgetItems = [];
  specificationCareItems = [];
  specialRequirementJobTypeItems = [];
  dropdownAgentList = [];
  selectedAgent = [];
  companyAgent = [];
  minDate: any = moment('2021-09-01T00:00:00-06:00');
  maxDate: any = moment();
  locale: any = {
    applyLabel: 'OK', // detault is 'Apply'
    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
  ],
  customRangeLabel: "Zeitspanne",
  format: "DD.MM.YYYY"
  }
  ranges: any = {
    'Heute': [moment(), moment()],
    'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Letze Woche': [moment().subtract(6, 'days'), moment()],
    'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
    'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
    'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  }
  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private postingsService: PostingsService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {

    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.companyId = +params['id'];
        this.jobId = +params['jobId'];
        this.tabId = +params['tabId'];
        this.isCopy = +params['isCopy'];
        this.postcode = + (params['postcode']?.length == 4) ? '0' + params['postcode'] : params['postcode'];
        this.isNewCompany = false;
        this.isNewJob = false;
      })
      );
      this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };
    this.getCompanyUser();
    this.initDropDownData();
    this.getAgentList();
    this.getTemplate();
    this.getBudget();
  }

  initDropDownData() {

    const ShiftHoursList = new AbbreviationModel().getList('shiftHours');
    let hoursArr = [];
    for (let hours of ShiftHoursList) {
      let hoursObj = {
        item_id: hours.id,
        item_text: hours.fields.short_name,
      };
      hoursArr.push(hoursObj);
    }
    this.dropdownShiftHoursList = hoursArr;

    const careTypeList = new AbbreviationModel().getList('careType');
    let careArr = [];
    for (let care of careTypeList) {
      let careObj = {
        item_id: care?.id,
        item_text: care?.fields?.short_name,
      };
      careArr.push(careObj);
    }
    this.dropdownCareTypeList = careArr;

    const educationTypeList = new AbbreviationModel().getList('educationType');
    let eduArr = [];
    for (let edu of educationTypeList) {
      let eduObj = {
        item_id: edu.id,
        item_text: edu.fields.short_name,
      };
      eduArr.push(eduObj);
    }
    this.educationList = eduArr;

    const scpList = new AbbreviationModel().getList('scope');
    let scpArr = [];
    for (let scp of scpList) {
      let scpObj = {
        item_id: scp.id,
        item_text: scp.fields.short_name,
      };
      scpArr.push(scpObj);
    }
    this.scopeList = scpArr;

    const specificationList =
      new AbbreviationModel().getList('specification');
    let speciArr = [];
    for (let speci of specificationList) {
      let speciObj = {
        item_id: speci.id,
        item_text: speci.fields.short_name,
      };
      speciArr.push(speciObj);
    }
    this.dropdownSpecificationJobCareTypeList = speciArr;

    const educationAssistanct =
      new AbbreviationModel().getList('eduAssistance');
    let eaArr = [];
    for (let ea of educationAssistanct) {
      let eaObj = {
        item_id: Number(ea.id),
        item_text: ea.fields.short_name,
      };
      eaArr.push(eaObj);
    }
    this.dropdownEducationAssistance = eaArr;

    const spReqList =
      new AbbreviationModel().getList('specialJobType');
    let spReqArr = [];
    for (let spReq of spReqList) {
      let spReqObj = {
        item_id: spReq.id,
        item_text: spReq.fields.short_name,
      };
      spReqArr.push(spReqObj);
    }
    this.specialRequirementJobTypeList = spReqArr;
  }

  getAgentList() {
    this.dropdownAgentList = [];
    this.jobService.getAgent(false).subscribe((res: any) => {
      this.companyAgent = res;
      for (let agent of this.companyAgent) {
        this.dropdownAgentList.push({
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        });
      }
      this.dropdownAgentList = new Array(this.dropdownAgentList);
    });
  }

  searchFilterData(val) {
    const companyId = val.map((x) => x.item_id);
    const searchVal = this.search !== undefined ? this.search : '';
    const shift:string =
      this.selectedShiftHoursItems.length > 0
        ? String(this.selectedShiftHoursItems.map((x1) => x1.item_id))
        : '';
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.posting(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        pincode: '',
        company_ids: '',
        care_type: '',
        posting_templates: '',
        budget: '',
        start_date: '',
        end_date: '',

      },
      2
    );
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }

  getCompanyUser() {
    this.jobService.getCompanies().subscribe((res: any) => {
      let Companies = [];
      res.forEach((company:any) =>
        Companies.push({ item_id: company.id, item_text: company.name })
      );
      this.dropdownCompanies = Companies;
      this.companiesList = Companies;
    });
  }

  ngAfterViewInit() {
    if (
      (this.tabId == 0 || this.tabId) &&
      this.jobId &&
      this.companyId &&
      this.isCopy == 0
    ) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    } else if (this.isCopy == 1) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    }
    this.cdr.detectChanges();

  }

  startClose() {
    this.location.go('/postings');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getCompanyUser();
  }

  // add job create and edit stuff
  addNewJob(companyid) {
    this.selectedTab.select(0);
    this.companiesList = this.dropdownCompanies;
    this.drawer.open();
    this.isDublicate = false;
    this.isNewJob = true;
    this.isNewCompany = false;
  }

  // add company create and edit stuff
  addNewCompany() {
    this.drawer.open();
    this.selectedTab.select(1);
    this.isDublicate = false;
    this.isNewCompany = true;
    this.isNewJob = false;
  }

  //edit sidebar stuff
  edit(companyid, jobid, tabid, isCopy, postcode) {
    this.selectedTab.select(tabid);
    this.isDublicate = (isCopy == 0) ? false : true;
    this.companyId = companyid;
    this.postcode = postcode;
    this.jobId = jobid;
    this.isNewJob = false;
    this.isNewCompany = false;
    this.location.go('/postings/' + companyid + '/' + jobid + '/' + tabid + '/' + isCopy + '/' + postcode)
    this.drawer.open();
  }

  checkIsJobUpdated($event) {
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  getBudgetName(element){    
    let budget =  this.budgetsArr.find(item => item.value == element?.budget);
    return budget?.name;
    
  }

  /* set job status active or inactive */
  updateJobStatus(jobId, status) {
    let statusData = { is_active: status.checked };
    this.jobService.updateJobStatus(statusData, jobId).subscribe(
      (res: any) => {
        this.dataSource.fetch(this.paginator.pageIndex);
      },
      (error) => {
        this._snackBar.open(error, 'OK', { duration: 5000 });
      }
    );
  }

  queryBy(by, queryNgModel) {   
    var query = {}
    if(by != 'date_range'){
      query[by] = (queryNgModel.length > 0 ? queryNgModel.map(x1 => x1.item_id) : '');
    }

    this.dataSource.queryBy(query);



    const searchVal = this.search !== undefined ? this.search : '';
    const company_ids =
      this.selectedCompanies.length > 0
        ? this.selectedCompanies.map((x1) => x1.item_id)
        : '';
    const posting_templates = this.selectedTemplateItems.length > 0
    ? this.selectedTemplateItems.map((x1) => x1.item_id)
    : '';
    var startdt = new Date(this.selectedDateRange?.startDate?._d);
      var enddt = new Date(this.selectedDateRange?.endDate?._d);

    const care_type =
      this.selectedCareTypeItems.length > 0
        ? this.selectedCareTypeItems.map((x1) => x1.item_id)
        : '';

    const budget = this.selectedBudgetItems.length > 0
    ? this.selectedBudgetItems.map((x1) => x1.item_id) : '';  
    
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.posting(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        pincode: searchVal,
        company_ids: company_ids,
        care_type: care_type,
        posting_templates: posting_templates,
        budget: budget,
        start_date: this.selectedDateRange?.startDate?._d === undefined ? "" : startdt.getFullYear() + '-' + ((startdt || new Date()).getMonth() + 1) + '-' + startdt.getDate(),
        end_date: this.selectedDateRange?.endDate?._d === undefined ? "" : enddt.getFullYear() + '-' + ((enddt || new Date()).getMonth() + 1) + '-' + enddt.getDate(),

      },
      2
    );
    
  }

  getTemplate() {
      const careType = []
      this.postingsService.getTemplateData(careType).subscribe((response: any) => {
        let tempArr = [];
    for (let tem of response) {
      let tempObj = {
        item_id: Number(tem.id),
        item_text: tem.name,
      };
      tempArr.push(tempObj);
    }
    this.dropdownTemplateList = tempArr;

      }, (errorRes) => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      });

  }

  getBudget(){
    let budgetArr = [];
    for (let tem of this.budgetsArr) {
      let tempObj = {
        item_id: Number(tem.value),
        item_text: tem.name,
      };
      budgetArr.push(tempObj);
    }
    this.dropdownBudgetList = budgetArr;

  }

}

export interface companyResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}

export interface Sortable {
  posting_date: Date;
  name: any;
  location: any;
}


