import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'healthcare-front';
  isLoginPage = false;
  isMobile = false

  constructor(public router: Router, private deviceService: DeviceDetectorService) {
    this.router.events.subscribe((event) => {
      // this.isLoginPage = this.router.routerState.snapshot.url.includes('login');
    });
  }


  checkIsLogin($event) {
    this.isLoginPage = false;
  }

  ngOnInit() {
    this.isLoginPage = true;

    this.isMobile = this.deviceService.isMobile();      
      if(this.isMobile === true){        
        this.router.navigate(['mobile']);
       }else
    if (window.location.pathname.includes('login')) {
      this.isLoginPage = false;
      // this.isLoginPage = true;
    }
    
  }

}
