<div style="float: right">
    <button mat-icon-button (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div
    class="job-list-div"
    style="width: auto; height: auto; padding: 0px 50px 50px 50px;"
  >
    <div *ngIf="returnValue" style="margin-top: 20px">
      <textarea
        matInput
        placeholder="Ex. It makes me feel..."
        extarea
        id="clipboard-example-textarea"
        cols="{{cols}}"
        rows="{{rows}}"
        [(ngModel)]="returnValue"
      >
      </textarea>
      <button
        mat-raised-button
        color="primary"
        [cdkCopyToClipboard]="returnValue"
        style="margin-top: 40px"
         >
         {{ 'home.btn_copy_to_clipboard' | translate }}
      </button>
      <button
        mat-raised-button
        color="basic"
        style="margin: 40px 0px 0px 10px"
        >
     <a href="https://wa.me/{{applier_phone?.replaceAll(' ', '')}}?text={{encode(returnValue)}}" target="_blank" >Direkt zu Whatsapp</a> 
      </button>
    </div>
  </div>
  
