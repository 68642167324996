export var NumberChart = [
  {
    company: "jklö",
    data: [{
      "name": "Weiterleitungen",
      "value": 15
    },
    {
      "name": "Einstellungen",
      "value": 3
    },
    {
      "name": "Einstellungsquote",
      "value": 20
    }]
  },
  {
    company: "asdf",
    data: [{
      "name": "Weiterleitungen",
      "value": 15
    },
    {
      "name": "Einstellungen",
      "value": 3
    },
    {
      "name": "Einstellungsquote",
      "value": 20
    }]
  }
];