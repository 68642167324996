<div class="register" *ngIf="isRegister">
    <mat-card class="register-card">
        <mat-card-header>
            <mat-card-title translate>register.title</mat-card-title>
        </mat-card-header>
        <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>
        <p class="error-message" ngif="errorMessage">{{ errorMessage }}</p>
        <p class="success-message" ngif="successMessage">{{ successMessage }}</p>
        <form class="example-form" [formGroup]="registerForm" (ngSubmit)="register()">
            <mat-card-content>
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-radio-group aria-labelledby="gender" formControlName="gender"
                                class="gender-radio example-full-width">
                                <div class="gender-div">
                                    <mat-label>Anrede</mat-label>
                                </div>
                                <mat-radio-button value="1"> {{'profile.male' | translate}} </mat-radio-button>
                                <mat-radio-button [checked]="true" value="2" style="margin-left: -75px;" translate>
                                    {{'profile.female' | translate}}</mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{ 'register.first_name' | translate }}"
                                    formControlName="first_name" required>
                                <mat-error
                                    *ngIf="registerForm.invalid && (registerForm.get('first_name').invalid || registerForm.get('first_name').dirty || registerForm.get('first_name').touched)">
                                    Please enter first name</mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{ 'register.last_name' | translate }}"
                                    formControlName="last_name" required>
                                <mat-error
                                    *ngIf="registerForm.invalid  && (registerForm.get('last_name').invalid || registerForm.get('last_name').dirty || registerForm.get('last_name').touched)">
                                    Please enter last name</mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{ 'register.email' | translate }}" formControlName="email"
                                    required>
                                <mat-error
                                    *ngIf="registerForm.invalid && (registerForm.get('email').invalid || registerForm.get('email').dirty || registerForm.get('email').touched)">
                                    Please enter email</mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width phone_number">
                                <span matPrefix>+49&nbsp;</span>
                                <input (keyup)="phoneNumber($event)" matInput
                                    placeholder="{{ 'register.phone_number' | translate }}"
                                    formControlName="phone_number" required>
                                <mat-error
                                    *ngIf="registerForm.invalid && (registerForm.get('phone_number').invalid || registerForm.get('phone_number').dirty || registerForm.get('phone_number').touched)">
                                    Please enter phone number</mat-error>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{ 'register.company_name' | translate }}"
                                    formControlName="company_name">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{ 'register.website' | translate }}" formControlName="website">
                                <mat-error *ngIf="registerForm.invalid && (registerForm.get('website').invalid || registerForm.get('website').dirty || registerForm.get('website').touched)">
                                    Please enter correct website url</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-checkbox class="acceptTerms" color="primary"
                                formControlName="acceptTerms" id="acceptTerms" for="acceptTerms">
                                Mit der Anmeldung akzeptiere ich die
                                <a href="https://www.pflegepiraten.net/agbs/" target="_blank">AGBs</a>
                                und <a href="https://www.pflegepiraten.net/datenschutz/"
                                    target="_blank">Datenschutzerklärung</a>
                            </mat-checkbox>
                            <mat-error class="terms-error" *ngIf="!registerForm.value.acceptTerms"> Bitte akzeptiere die AGB</mat-error>
                        </td>
                    </tr>
                </table>
            </mat-card-content>

            <mat-card-actions>
                <a class="text-center" routerLink="/login">Schon einen Account?</a>
            </mat-card-actions>
            <!-- [disabled]="registerForm.invalid"  -->
            <mat-card-actions>
                <button mat-raised-button type="submit" color="primary">{{ 'register.btn_register' | translate
                    }}</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
<div class="register">
    <mat-card class="" *ngIf="!isRegister">
        <div class="company-list">
            <mat-card-header>
                <mat-card-title>Registrierung erfolgreich abgeschlossen.</mat-card-title>
            </mat-card-header>
            <p class="text-center">
                Bitte überprüfen Sie Ihre E-Mail und klicken Sie auf die Schaltfläche, um sich einzuloggen. Ihre E-Mail
                läuft nach 5 Minuten ab.
            </p>
            <p class="text-center">
                <mat-card-actions>
                    <a class="text-center" routerLink="/login">Klicken Sie hier, um sich erneut anzumelden!</a>
                </mat-card-actions>
            </p>
        </div>
    </mat-card>
</div>