<mat-drawer-container class="container" [hasBackdrop]="true">
  <!-- Sidenav start -->
  <mat-drawer #drawer class="sidenav" position="end" [mode]="'side'" (closedStart)="startClose()">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab.selected"
      (selectedIndexChange)="selectedTab.select($event)">
      <mat-tab *ngIf="isNewJob || (!isNewJob && !isNewCompany)" label="Job">
        <app-job-sidebar *ngIf="drawer.opened" (updateAndClose)="updateAndClose($event)" jobId="{{jobId}}" (update)="update($event)"
          [dropdownCompanies]="dropdownCompanies" companyId="{{companyId}}" isDublicate="{{isDublicate}}"
          isNewJob="{{isNewJob}}" (isJobUpdated)="checkIsJobUpdated($event)" (close)="close($event)">
        </app-job-sidebar>
      </mat-tab>
      <mat-tab *ngIf="isNewCompany || (!isNewJob && !isNewCompany)" label="Unternehmen">
        <app-companysidebar *ngIf="drawer.opened" (updateAndClose)="updateAndClose($event)" (update)="update($event)"
          companyId="{{companyId}}" isNewCompany="{{isNewCompany}}">
        </app-companysidebar>
      </mat-tab>
    </mat-tab-group>
  </mat-drawer>
  <div class="row filters-row" style="margin-top: 10px;">
     <!-- Filter Start  -->
    <div class="col filters-col">
      <ng-multiselect-dropdown (ngModelChange)="searchFilterData($event)" class="multiselect-dropdown"
        placeholder="Unternehmen" [settings]="dropdownSettings" [data]="dropdownCompanies"
        [(ngModel)]="selectedCompanies" [ngModelOptions]="{standalone: true}">
      </ng-multiselect-dropdown>
    </div>
    <div class="col filters-col" style="padding-left: 3%;">
      <mat-form-field>
        <mat-label translate>jobs.search_placeholder</mat-label>
        <input matInput [(ngModel)]="search" (input)="dataSource.queryBy({search: input.value})"
          placeholder="{{ 'jobs.search_placeholder_value' | translate}}" #input>
      </mat-form-field>
    </div>
    <div class="col filters-col" style="width:100%;text-align:right">
      <button mat-flat-button class="btn" style="margin-bottom: 15px;" color="primary" (click)="addNewCompany()">
        <mat-icon>+</mat-icon> <span>Firma</span>
      </button>
      <button mat-flat-button class="btn" style="margin-bottom: 15px;" color="primary" (click)="addNewJob(companyId)">
        <mat-icon>+</mat-icon> <span>Job</span>
      </button>
    </div>
  </div>

  <!--Start with table-->
  <mat-table class="mat-elevation-z8" mat-table multiTemplateDataRows matSortDisableClear [dataSource]="dataSource"
    matSort (matSortChange)="sortBy($event)" matSortActive="created_at" matSortStart="asc">

    <!--add the company name-->
    <ng-container matColumnDef="companyname">
      <mat-header-cell *matHeaderCellDef mat-sort-header="company">
        {{ 'home.col_companyname' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element?.company.name}}
        <mat-icon class="company-edit" (click)="edit(element?.company.id,element?.id,1,0)">edit</mat-icon>
      </mat-cell>
    </ng-container>

    <!--add the job title-->
    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef mat-sort-header="city" translate>
        {{ 'home.col_city' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{element?.postcode}} {{element?.city}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="education">
      <mat-header-cell *matHeaderCellDef translate> home.col_education </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.education_type.length > 0">
          <tag *ngFor="let edu of element?.education_type">
            {{edu|EducationForJob}}
          </tag>
        </ng-container>
        <ng-container *ngIf="element?.education_type?.length == 0">
          <tag>{{ 'message.not_available' | translate }}</tag>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="caretype">
      <mat-header-cell *matHeaderCellDef translate> home.col_care_type </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.care_type?.length > 0">
          <tag *ngFor="let ct of element?.care_type">
            {{ct | CareTypeAbbreviations}}
          </tag>
        </ng-container>
        <ng-container *ngIf="element?.care_type?.length == 0">
          <tag>{{ 'message.not_available' | translate }}</tag>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shifthour">
      <mat-header-cell *matHeaderCellDef translate> home.col_shift_hour </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.shift.length > 0">
          <tag *ngFor="let shift of element?.shift" [color]="secondary">
            {{shift|ShiftAbbreviations}}
          </tag>
        </ng-container>
        <ng-container *ngIf="element.shift_other != null && element.shift_other !=''">
          <tag>{{element?.shift_other}}</tag>
        </ng-container>
        <ng-container *ngIf="element?.shift.length == 0 && element?.shift_others ==''">
          <tag>{{ 'message.not_available' | translate }}</tag>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scope">
      <mat-header-cell *matHeaderCellDef mat-sort-header="working_type"> {{ 'home.col_scope' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <tag *ngFor="let wt of element?.working_type" [color]="'secondary'">
          {{wt|ScopeAbbreviations}}
        </tag>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header="created_at"> {{ 'home.col_created_date' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element?.created_at | date}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element" style="text-align: end;">
        <mat-slide-toggle class="action-icon" [checked]="element?.is_active" (change)="updateJobStatus(element?.id,$event)"
          color="primary">
          </mat-slide-toggle>
          
        <button mat-icon-button class="action-icon ml-2" (click)="showDashboard(element?.company?.agent_user)"> <mat-icon>tty</mat-icon> </button>
        

        <button mat-icon-button class="action-icon ml-2" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
          <button mat-menu-item (click)="edit(element?.company.id,element?.id,0,1)"
          routerLink="/dashboard/{{element?.company.id}}/{{element?.id}}/0/1">
            <mat-icon>content_copy</mat-icon>
            <span>Duplizieren</span>
          </button>
          <button mat-menu-item (click)="delete(element?.id)">
            <mat-icon class="delete-icon">delete</mat-icon>
            <span class="delete-icon">Job löschen</span>
          </button>
        </mat-menu>
        <mat-icon class="action-icon" (click)="edit(element?.company.id,element?.id,0,0)"
        routerLink="/dashboard/{{element?.company.id}}/{{element?.id}}/0/0">edit</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let element; columns: columnsToDisplay;" id="{{ element.id}}_match"
      [class.inactive]="!element?.is_active"></mat-row>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">{{ 'message.no_record' | translate }} </td>
    </tr>
  </mat-table>
  <div class="mat-elevation-z8 paginator-row" *ngIf="dataSource.page$  | async">
    <div class="progress-bar">
      <mat-progress-bar mode="buffer" *ngIf="dataSource.loading$ | async">
      </mat-progress-bar>
    </div>
  <mat-paginator *ngIf="dataSource.page$ | async as page" [length]="page.totalElements" [pageSize]="15"
    [showFirstLastButtons]="true" [pageIndex]="page.number" [hidePageSize]="true"
    (page)="pageEvent = handlePage($event)" (page)="dataSource.fetch($event.pageIndex)" >
  </mat-paginator>
  </div>
  <div *ngIf="((dataSource.loading$ | async) !== false ) && !curentPage" class="skeleton-loader">
    <ngx-skeleton-loader count="10" appearance="line" [theme]="{'height.px':35}"></ngx-skeleton-loader>
  </div>
</mat-drawer-container>
