import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from 'src/app/services/auth.service';
// import { sign } from 'crypto';
// import Web3 from 'web3';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  returnUrl: string;
  constructor(
    private msalService: MsalService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    if (localStorage.getItem("agentData") !== null) {
      this.router.navigate(['applicants']);
    } else if (localStorage.getItem("companyData") !== null) {
      this.router.navigate(['dashboard']);
    }
    // this.msalService.instance.handleRedirectPromise().then(res=>{
    //   if(res != null && res.account != null){
    //     this.msalService.instance.setActiveAccount(res.account);
    //     this.router.navigate(['home']);
    //   }
    // })
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  login() {
    // this.msalService.loginRedirect();
    this.msalService.loginPopup().subscribe((response: AuthenticationResult) => {
      console.log("------------ Inside Login----------------");
      console.log(response);
      localStorage.setItem('msalAccount', JSON.stringify(response.account));
      this.router.navigate(['applicants']);
      let nm = response?.account?.name.split(" ");

      let postData = {
        first_name: nm[0],
        last_name: nm[1],
        // first_name : response.account.name ? response.account.name : "",
        // last_name : "TEST",
        email: response.account.username,
        token: response.accessToken,
        social_account: 1,
        role: 2
      }

      console.log("----- POST DATA -----");
      console.log(postData);

      this.authService.doSignIn(postData).subscribe(res => {
        console.log("------ BE RESPONSE ------");
        console.log(res);
        console.log("returnUrl",this.returnUrl);
        
        if (this.returnUrl && this.returnUrl !== '/') {
          window.location.href = this.returnUrl;
        } else {
          window.location.href = `/applicants`;
        }
        console.log("window 1234",window.location.href);        
      }, errorMessage => {
        console.log("----- BE ERROR -----");
        console.log(errorMessage);
      })

    });
  }

}
