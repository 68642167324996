import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthenticationResult } from '@azure/msal-browser';
import { CompanyAuthService } from 'src/app/services/company-auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent implements OnInit {
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  showSpinner = false;
  isRegister = true;
  errorMessage: string;
  successMessage: string;
  registerForm: FormGroup;
  loginResult: any;
  isChecked: boolean;
  constructor(
    private router: Router,private _snackBar: MatSnackBar,
    private CompanyAuthService: CompanyAuthService, private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initForm();

    const sesstionResult = JSON.parse(localStorage?.getItem("companyData"));
    // if (sesstionResult) {
    //   this.router.navigate(['/dashboard']);
    // }
  }

  initForm() {
    this.registerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      //  // phone_number: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^(\+\d{1,3}[- ]?)?\d{10}$")])),
      phone_number: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(17), Validators.pattern("^[0-9]*$")]),
      company_name: new FormControl(''),
      gender: new FormControl(2),
      website: new FormControl('', Validators.pattern(this.reg)),
      acceptTerms: new FormControl(null, [Validators.requiredTrue])
    });
  }

  // checkTerms(event) {
  //   console.log("event.checked", event.checked)
  //   if (event.checked === false) {
  //     this.registerForm.controls['acceptTerms'].setErrors({ 'incorrect': true });
  //   }
  // }

  phoneNumber(event) {
    this.registerForm.patchValue({
      phone_number: event.target.value.replace(/^0+/, '')
    });
  }

  async register() {
    this.errorMessage = this.successMessage = '';
    // return false
    if (this.registerForm.valid) {
      this.showSpinner = true;
      this.registerForm.value.phone_number = '+49'+this.registerForm.value.phone_number;
      this.registerForm.value.gender = Number(this.registerForm.value.gender);
      const postData = this.registerForm.value;
      console.log("this.registerForm.value", this.registerForm.value);
      // return false;
      await this.CompanyAuthService.doCompanySignUp(postData).subscribe((response: any) => {
        console.log("------ BE RESPONSE ------", response);
        this.loginResult = response;
        this.successMessage = this.loginResult.message;
        this.registerForm.controls['email'].setErrors({ 'incorrect': true });
        this.showSpinner = false;
        this.isRegister = false;
      }, (errorRes) => {
        this.showSpinner = false;
        this.isRegister = true;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        // this.errorMessage = errorRes;
      });
    }
  }
}
