import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { AuthService } from './auth.service';
import { Subject } from 'rxjs';
import { environment } from '../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryMatchService {

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  put(url, data, httpOptions = {}) {
    return this.http.put(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  delete(url, httpOptions = {}) {
    return this.http.delete(`${environment.BASE_URL}${environment.PATH}${url}`, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getPdf(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }


  public handleError(errorRes: HttpErrorResponse) {
    console.log("errorRes", errorRes?.statusText)
    if (Object.keys(errorRes?.error)?.length > 0) {
      if(Object.keys(errorRes?.error)[0]?.length > 1 && errorRes?.statusText !== 'Unknown Error'){
        return throwError(Object.keys(errorRes.error)[0] + " " + Object.values(errorRes.error)[0]);
      }
    }
    let message = (errorRes?.statusText) ? errorRes?.statusText : 'etwas ist schief gelaufen!'
    return throwError(message);
  }

  addHistoryMatchData(data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.post(`match/history-match-item/`, data, httpOptions);
  }

  getHistoryMatchData(applierId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`match/history-match-item/?match_ids=${applierId}`,  httpOptions);
  }

  getHistoryMatchStatus() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`match/status-match-item/`,  httpOptions);
  }

  deleteMatchHistory(historyMatchId) {
    let httpOptions = this.authService.getHTTPOption();
    return this.delete(`match/history-match-item/${historyMatchId}/`,  httpOptions);
  }

  getExcelExportData(fields, company_ids, start_date, end_date, need_action , status) {
  let token = this.authService.getAuthToken();
    let httpOptions = new HttpHeaders({
      'Authorization': token
    });
    return this.getPdf(`match/export/excel/?fields=${fields}&company_ids=${company_ids}&start_date=${start_date}&end_date=${end_date}&need_action=${need_action}&status_id=${status}`, { responseType: 'blob' as 'json', headers: httpOptions });
  
  }

  getHistoryMatchLabel() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`match/label-match-item`,  httpOptions);
  }

  updateHistoryMatchlabel(id,data) {
    let httpOptions = this.authService.getHTTPOption();
    return this.put(`match/matching/${id}/`, data, httpOptions);
  }



}
