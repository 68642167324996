<!-- Job Company Details -->
<div *ngIf="!isSpinner" class="job-list-div">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="companyForm">
        <table class="edit-form">
          <tr>
            <td class="label-td" translate> create_job.name <span *ngIf="editMode" class="is-validate">*</span> </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>create_job.name</mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{companyForm.controls['name'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate> company.external_name</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>company.external_name</mat-label>
                <input matInput formControlName="external_name">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{companyForm.controls['external_name'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate> create_job.website</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>create_job.website</mat-label>
                <input matInput formControlName="website">
              </mat-form-field>
              <mat-error
                *ngIf="companyForm.invalid && (companyForm.get('website').invalid || companyForm.get('website').dirty || companyForm.get('website').touched)">
                Please enter correct website url</mat-error>
            </td>
            <td *ngIf="!editMode">
              {{companyForm.controls['website'].value}}</td>
          </tr>

          <!-- company User Only -->
          <tr *ngIf="userRole == 2">
            <td style="padding-top: 15px !important;" translate>create_job.agent_user <span *ngIf="editMode"
                class="is-validate">*</span>
            </td>
            <td style="padding-top: 15px !important;" *ngIf="editMode">
              <div style="display: flex;align-items: center;">
                <ng-multiselect-dropdown formControlName="agent_user" class="agent_user"
                  placeholder="{{ 'validation.select_agent' | translate }}" [settings]="dropdownSingleSettings"
                  [data]="dropdownAgentList[0]" [(ngModel)]="selectedAgent" style="flex:1;">
                </ng-multiselect-dropdown>
              </div>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedAgent">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate> create_job.phone_number</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>create_job.phone_number</mat-label>
                <input matInput formControlName="phone_number">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{companyForm.controls['phone_number'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate> create_job.about</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>create_job.about</mat-label>
                <input matInput formControlName="about">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{companyForm.controls['about'].value}}</td>
          </tr>
          <tr *ngIf="userRole == 2">
            <td class="label-td" translate> create_job.sales</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-slide-toggle formControlName="sales" color="primary" class="mt-2 w-100">
              </mat-slide-toggle>
            </td>
            <td class="edit-td" *ngIf="!editMode">
              <mat-slide-toggle formControlName="sales" color="primary" class="mt-2 w-100" [disabled]="true">
              </mat-slide-toggle>
            </td>
          </tr>
          <tr style="text-align: center;" *ngIf="editMode">
            <td colspan="2"><button mat-raised-button style="margin-top: 10px; margin-bottom: 10px;" color="primary"
                (click)="addCompanyDialog()">
                {{ 'create_job.add_user' | translate }}
              </button></td>
          </tr>
        </table>
      </form>
      <!-- User Table Start -->
      
      <mat-table class="mat-elevation-z8" style="margin-bottom: 50px;" *ngIf="isNewCompany == 'false'" mat-table multiTemplateDataRows [dataSource]="dataSource">
        <!--add the company name-->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef translate>
            create_job.col_name
          </mat-header-cell>
          <mat-cell *matCellDef="let element;"> {{element.name}}
          </mat-cell>
        </ng-container>

        <!--add the job title-->
        <ng-container matColumnDef="contactuser">
          <mat-header-cell *matHeaderCellDef translate>
            create_job.col_contact_user
          </mat-header-cell>
          <mat-cell *matCellDef="let element;"> <mat-icon >{{element?.contactuser === true ? 'done' : 'close'}}</mat-icon></mat-cell>
        </ng-container>

        <!-- show email and phone number -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" style="text-align: end;margin-right: 10px;">
            <a *ngIf="element?.email" class="dashboard-link"  href="mailto:{{element?.email}}">
              <mat-icon style="padding-right: 5px;" matTooltip="{{element?.email}}">mail</mat-icon></a>
            <a *ngIf="element?.phone" class="dashboard-link" href="tel:{{element?.phone}}">
              <mat-icon style="padding-right: 5px;" matTooltip="{{element?.phone}}">phone number</mat-icon></a>
         
            <button mat-icon-button class="action-icon ml-2" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
              <!-- <button mat-menu-item>
              <mat-icon>share</mat-icon>
              <span translate>jobs.postings</span>
            </button> -->
            <!-- [checked]="element?.is_active === 1" (change)="updateJobStatus(element?.id,$event)" -->
            <mat-slide-toggle class="action-icon" style="margin: 5px 12px 5px 12px"  [checked]="element.contactuser" (change)="updateContactUser(element?.id,$event)"
            color="primary"><span> {{ element?.contactuser ? 'inaktiv' : 'aktiv' }}</span>
            </mat-slide-toggle>
          </mat-menu>
            </mat-cell
          >
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let element; columns: columnsToDisplay;" ></mat-row>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="10">{{ 'message.no_record' | translate }} </td>
        </tr>
      </mat-table>

    </mat-card-content>
    
    <div class="footer" #footer translate>
      <button mat-flat-button color="primary" (click)="doCreateCompany(isClose = true,$event)"
        *ngIf="isNewCompany == 'true' &&  editMode" [disabled]="companyForm.invalid" [translate]="'create_job.btn_create'">{{
        'jobs.btn_save_and_close' | translate}}</button>
      <button mat-flat-button color="primary" *ngIf="isNewCompany == 'false' && editMode"
        (click)="doUpdateCompany(isClose = true,$event)" [disabled]="companyForm.invalid">{{ 'jobs.btn_save_and_close' |
        translate}}</button>&nbsp;
      <button mat-flat-button color="primary" *ngIf="isNewCompany == 'false' && editMode"
        (click)="doUpdateCompany(isClose = false,$event)" [disabled]="companyForm.invalid">{{ 'jobs.btn_save' |
        translate}}</button> &nbsp;
      <button mat-flat-button *ngIf="showUser" (click)="resetData()"><span translate> jobs.btn_reset </span></button>
    </div>

  </mat-card>
  <ng-template let-data>
    Hello, {{data.name}}
  </ng-template>
</div>
<ng-container *ngIf="isSpinner">
  <ngx-skeleton-loader count="2" appearance="line" [theme]="{'height.px': 100}"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="5" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
</ng-container>
