import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { JobService } from 'src/app/services/job.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbbreviationModel } from '../../../models/abbreviation.model';
import { TranslateService } from '@ngx-translate/core';
import { ValidationAtLeastOneFieldService } from '../../../services/validation-at-least-one-field.service';
import { Options } from 'ng5-slider';
import { not } from '@angular/compiler/src/output/output_ast';

const codes = require('german-postal-codes');

@Component({
  selector: 'app-job-sidebar',
  templateUrl: './job-sidebar.component.html',
  styleUrls: ['./job-sidebar.component.scss'],
})
export class JobSidebarComponent implements OnInit {
  @Input('jobId') jobId: number;
  @Input('isNewJob') isNewJob: any = 'false';
  @Input('isDublicate') isDublicate: string;
  @Input() editMode: boolean = true;
  @Input('dropdownCompanies') dropdownCompanies: any[];
  @Input('companyId') companyId: number;
  @Output() isJobUpdated = new EventEmitter<any>();
  @Output() updateAndClose = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  jobForm: FormGroup;
  jobDetails: any;
  dublicateDetails: any;
  dropdownSettings: IDropdownSettings;
  singleSelectiondropdownSettings: IDropdownSettings;
  dropdownShiftHoursList = [];
  selectedShiftHoursItems = [];
  dropdownCareTypeList = [];
  dropdownEducationAssistance = [];
  selectedCareTypeItems = [];
  scopeList = [];
  selectedCompanies = [];
  continuingEducation = [];
  selectedContinuingEducation = [];
  educationList = [];
  dropdownSpecificationJobCareTypeList = [];
  specialRequirementJobTypeList = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  selectedEducationAssistance = [];
  specificationCareItems = [];
  specialRequirementJobTypeItems = [];
  street: string;
  streetNumber: string;
  city: string;
  isSpinner: boolean = false;
  address: string;
  errorMessage: string;
  isValueChange: boolean;
  userRole: number;
  isEduAssistantShow: boolean = false;
  //Local Variable defined
  formattedAddress = ' ';
  options = {
    componentRestrictions: {
      country: ['de'],
    },
  };
  isPostcodeValid = false;
  isShowSalary = false;
  minSalary: number = 2550;
  maxSalary: number = 3200;
  Options: Options = {
    floor: 1500,
    ceil: 5500,
  };

  constructor(
    private jobService: JobService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('agentData'))
      ? JSON.parse(localStorage.getItem('agentData'))
      : JSON.parse(localStorage.getItem('companyData'));
    this.userRole = userData.role;
    this.jobForm = this._formBuilder.group(
      {
        id: [],
        title: [],
        street: [],
        city: ['', Validators.required],
        postcode: ['', Validators.required],
        isJobActive: ['', Validators.required],
        companyName: ['', Validators.required],
        careTypes: ['', Validators.required],
        shifts: [''],
        workingTypes: ['', Validators.required],
        educationTypes: ['', Validators.required],
        educationAssistance: [],
        selectedScopeItems: [],
        selectedEducationItems: [],
        specificationCareItems: [],
        specialRequirementJobTypeItems: [],
        shift_other: [],
        continuing_education: [],
        note: [],
        internal_note: [],
        // sales:  [],
        url: [],
        max_salary: [],
        min_salary: [],
      },
      {
        validator: ValidationAtLeastOneFieldService(Validators.required, [
          'shift_other',
          'shifts',
        ]),
      }
    );

    if (this.jobId && this.isNewJob !== 'true') {
      this.getJobDetails();
    }
    this.inItSelect2List('');
    if (this.isNewJob == 'true') {
      this.jobForm.patchValue({ isJobActive: 1 });
    }
    this.cdr.detectChanges();

    if (this.jobId) {
      if (this.isNewJob == 'true') {
        this.initNewForm();
      } else {
        this.getJobDetails();
      }
    }
    this.educationAssistantFromModel();
    this.isValueChange = true;
    if (this.isDublicate === 'true') {
      this.isValueChange = false;
    }
  }

  AddressChange(address: any) {
    for (let addressComponent of address.address_components) {
      this.jobForm.patchValue({
        postcode: '',
        street: '',
        streetNumber: '',
        city: '',
      });
      if (addressComponent.types[0] == 'route') {
        this.street = addressComponent.long_name;
      }

      if (addressComponent.types[0] == 'street_number') {
        this.streetNumber = addressComponent.long_name;
      }

      if (addressComponent.types[0] == 'postal_code') {
        this.jobForm.value.postcode = addressComponent.long_name;
        this.jobForm.patchValue({
          postcode: addressComponent.long_name,
        });
      }

      if (addressComponent.types[0] == 'locality') {
        this.city = addressComponent.long_name;
        this.jobForm.patchValue({
          city: addressComponent.long_name,
        });
      }
    }

    if (this.street || this.streetNumber) {
      this.jobForm.value.street = this.street + ', ' + this.streetNumber;
      if (this.streetNumber) {
        this.jobForm.patchValue({
          street: this.street + ', ' + this.streetNumber,
        });
      } else {
        this.jobForm.patchValue({
          street: this.street,
        });
      }
    }
  }

  getJobDetails() {
    if (!isNaN(this.jobId)) {
      this.isSpinner = true;
      this.jobForm?.get('companyName').clearValidators();
      this.jobForm?.get('companyName').updateValueAndValidity();
      this.jobService.getJobDetails(this.jobId).subscribe((res: any) => {
        this.jobDetails = res;
        if (
          this.jobDetails.postcode !== 'undefined' &&
          this.jobDetails.postcode
        ) {
          var target = codes.find((temp) => temp == this.jobDetails.postcode);
          if (target) this.isPostcodeValid = false;
          else this.isPostcodeValid = true;
        }

        this.inItSelect2List(this.jobDetails);
        if (this.jobDetails.street) {
          if (this.jobDetails.street.length > 0) {
            this.address =
              this.jobDetails.street +
              ', ' +
              this.jobDetails.postcode +
              ', ' +
              this.jobDetails.city;
          } else {
            this.address =
              this.jobDetails.postcode + ', ' + this.jobDetails.city;
          }
        } else {
          this.address = this.jobDetails.postcode + ', ' + this.jobDetails.city;
        }
        if (this.jobDetails.min_salary && this.jobDetails.max_salary) {
          this.isShowSalary = true;
          this.minSalary = this.jobDetails.min_salary;
          this.maxSalary = this.jobDetails.max_salary;
        }

        this.jobForm.patchValue({
          id: this.jobDetails.id,
          title: this.jobDetails.title,
          street: this.jobDetails.street,
          city: this.jobDetails.city,
          postcode: this.jobDetails.postcode,
          shift_other: this.jobDetails.shift_other,
          note: this.jobDetails.note,
          internal_note: this.jobDetails.internal_note,
          isJobActive: this.jobDetails.is_active == 1 ? true : false,
          url: this.jobDetails.url,
          min_salary: this.jobDetails.min_salary,
          max_salary: this.jobDetails.max_salary,
        });

        this.checkSalary(this.jobDetails.min_salary, this.jobDetails.max_salary)

        if (this.isDublicate === 'true') {
          let dublicateArray = {
            company_id:
              this.isNewJob == 'true'
                ? this.selectedCompanies[0].item_id
                : this.isDublicate === 'true'
                  ? this.jobDetails?.company?.id
                  : '',
            city:
              this.jobDetails.city === null || this.jobDetails.city == ''
                ? ''
                : this.jobDetails.city?.trim(),
            postcode:
              this.jobDetails.postcode === null ||
                this.jobDetails.postcode == ''
                ? ''
                : this.jobDetails.postcode?.trim(),
            street:
              this.jobDetails.street === null || this.jobDetails.street == ''
                ? ''
                : this.jobDetails.street?.trim(),
            is_active: this.jobDetails.is_active,
            shift_other:
              this.jobDetails.shift_other === null ||
                this.jobDetails.shift_other == ''
                ? ''
                : this.jobDetails.shift_other?.trim(),
            note: this.jobDetails.note,
            internal_note:
              this.jobDetails.internal_note === null ||
                this.jobDetails.internal_note == ''
                ? ''
                : this.jobDetails.internal_note?.trim(),
            care_type: this.jobDetails.care_type,
            shift: this.jobDetails.shift,
            education_type: this.jobDetails.education_type,
            working_type: this.jobDetails.working_type,
            specification_care_type: this.jobDetails.specification_care_type,
            special_requirements: this.jobDetails.special_requirements,
            education_assistant:
              this.jobDetails.education_assistant === null ||
                this.jobDetails.education_assistant == ''
                ? null
                : this.jobDetails.education_assistant,
            url:
              this.jobDetails.url === null || this.jobDetails.url == ''
                ? ''
                : this.jobDetails.url,
            min_salary: this.isShowSalary ? this.minSalary : null,
            max_salary: this.isShowSalary ? this.maxSalary : null,
            continuing_education: this.jobDetails.continuing_education,
          };
          this.dublicateDetails = dublicateArray;
        }
        this.isSpinner = false;
      });
    }
  }

  showSalary(e: any) {
    this.isShowSalary = e;
  }

  checkSalary(min, max) {
    this.minSalary = min;
    this.maxSalary = max;

    if (this.minSalary > this.maxSalary) {
      this.jobForm.controls['min_salary'].setErrors({ 'invalid': true });
    } else {
      this.jobForm.controls['min_salary'].setErrors(null);
    }
  }
  educationAssistantFromModel() {
    const educationAssistanct =
      new AbbreviationModel().getList('eduAssistance');
    let eaArr = [];
    for (let ea of educationAssistanct) {
      let eaObj = {
        item_id: ea.id,
        item_text: ea.fields.short_name,
      };
      eaArr.push(eaObj);
    }
    this.dropdownEducationAssistance = eaArr;
  }

  initNewForm() {
    this.address = '';
    this.selectedShiftHoursItems = [];
    this.selectedCareTypeItems = [];
    this.selectedScopeItems = [];
    this.selectedEducationItems = [];
    this.selectedEducationAssistance = [];
    this.specificationCareItems = [];
    this.selectedCompanies = [];
    this.specialRequirementJobTypeItems = [];
    if (this.jobForm) {
      this.jobForm.patchValue({
        title: '',
        street: '',
        city: '',
        postcode: '',
        isJobActive: 1,
        selectedScopeItems: '',
        selectedEducationItems: '',
        selectedEducationAssistance: '',
        specificationCareItems: '',
        specialRequirementJobTypeItems: '',
        note: '',
        url: '',
        internal_note: '',
      });
    } else {
      this.jobForm = new FormGroup({
        id: new FormControl(''),
        title: new FormControl(''),
        street: new FormControl(''),
        city: new FormControl(''),
        postcode: new FormControl(''),
        isJobActive: new FormControl(''),
        companyName: new FormControl(''),
        careTypes: new FormControl(''),
        shifts: new FormControl(''),
        workingTypes: new FormControl(''),
        educationTypes: new FormControl(''),
        educationAssistance: new FormControl(''),
      });
      this.jobForm.patchValue({
        title: '',
        street: '',
        city: '',
        postcode: '',
        isJobActive: 1,
        selectedScopeItems: '',
        selectedEducationItems: '',
        selectedEducationAssistance: '',
        specificationCareItems: '',
        specialRequirementJobTypeItems: '',
        note: '',
        url: '',
        internal_note: '',
        educationAssistance: [],
      });
    }
  }

  inItSelect2List(data = null) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      searchPlaceholderText: 'Suche',
      enableCheckAll: false,
      allowSearchFilter: false,
    };
    this.singleSelectiondropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };

    const careTypeList = new AbbreviationModel().getList('careType');
    let careArr = [];
    for (let care of careTypeList) {
      let careObj = {
        item_id: care.id,
        item_text: care.fields.short_name,
      };
      careArr.push(careObj);
    }
    this.dropdownCareTypeList = careArr;

    let careTypeArr = [];
    if (data?.care_type) {
      for (let care_type of data.care_type) {
        let careTypeObj = {
          item_id: Number(care_type),
          item_text: new AbbreviationModel().getById(care_type,'careType', 'short'),
        };
        careTypeArr.push(careTypeObj);
      }
      this.selectedCareTypeItems = careTypeArr;
    }

    let userArr = [];
    if (data?.company) {
      let userObj = {
        item_id: data?.company.id,
        item_text: data?.company.name,
      };
      userArr.push(userObj);
      this.selectedCompanies = userArr;
    }

    if (data?.continuing_education) {
      let ceArr = [];
      for (let ce of data?.continuing_education) {
        let shiftHourObj = {
          item_id: Number(ce),
          item_text: new AbbreviationModel().getById(ce,'continuingEdu.', 'short'),
        };
        ceArr.push(shiftHourObj);
      }
      this.selectedContinuingEducation = ceArr;
    }

    const ShiftHoursList = new AbbreviationModel().getList('shiftHours');
    let hoursArr = [];
    for (let hours of ShiftHoursList) {
      let hoursObj = {
        item_id: hours.id,
        item_text: hours.fields.short_name,
      };
      hoursArr.push(hoursObj);
    }
    this.dropdownShiftHoursList = hoursArr;

    if (data?.shift) {
      let shiftHourArr = [];
      for (let shift_hour of data.shift) {
        let shiftHourObj = {
          item_id: Number(shift_hour),
          item_text: new AbbreviationModel().getById(shift_hour,'shiftHours', 'short'),
        };
        shiftHourArr.push(shiftHourObj);
      }
      this.selectedShiftHoursItems = shiftHourArr;
    }

    const educationTypeList = new AbbreviationModel().getList('educationType');
    let eduArr = [];
    let notShowEdu = [8]
    for (let edu of educationTypeList) {
      let eduObj = {
        item_id: edu.id,
        item_text: edu.fields.short_name,
      };
      if (!notShowEdu.includes(edu.id)) {
        eduArr.push(eduObj);
      }
    }
    this.educationList = eduArr;

    let eduTypeArr = [];
    if (data?.education_type) {
      for (let edu_type of data.education_type) {
        let eduTypeObj = {
          item_id: Number(edu_type),
          item_text: new AbbreviationModel().getById(edu_type,'educationType', 'short'),
        };
        eduTypeArr.push(eduTypeObj);
        this.jobForm.controls['educationAssistance'].clearValidators();

        if (edu_type == 4) {
          this.jobForm.controls['educationAssistance'].setValidators([
            Validators.required,
          ]);
          this.isEduAssistantShow = true;
        }
        this.jobForm.controls['educationAssistance'].updateValueAndValidity();
      }
      this.selectedEducationItems = eduTypeArr;
    }

    const scpList = new AbbreviationModel().getList('scope');
    let scpArr = [];
    for (let scp of scpList) {
      let scpObj = {
        item_id: scp.id,
        item_text: scp.fields.short_name,
      };
      scpArr.push(scpObj);
    }
    this.scopeList = scpArr;

    let scopeArr = [];
    if (data?.working_type) {
      for (let scope of data.working_type) {
        let workingTypeObj = {
          item_id: Number(scope),
          item_text: new AbbreviationModel().getById(scope,'scope', 'short'),
        };
        scopeArr.push(workingTypeObj);
      }
      this.selectedScopeItems = scopeArr;
    }

    const specificationList =
      new AbbreviationModel().getList('specification');
    let speciArr = [];
    for (let speci of specificationList) {
      let speciObj = {
        item_id: speci.id,
        item_text: speci.fields.short_name,
      };
      speciArr.push(speciObj);
    }
    this.dropdownSpecificationJobCareTypeList = speciArr;

    let specificationArr = [];
    if (data?.specification_care_type) {
      for (let scope of data.specification_care_type) {
        let shiftHourObj = {
          item_id: Number(scope),
          item_text: new AbbreviationModel().getById(scope,'specification', 'short'),
        };
        specificationArr.push(shiftHourObj);
      }
      this.specificationCareItems = specificationArr;
    }

    const spReqList =
      new AbbreviationModel().getList('specialJobType');
    let spReqArr = [];
    for (let spReq of spReqList) {
      let spReqObj = {
        item_id: spReq.id,
        item_text: spReq.fields.short_name,
      };
      spReqArr.push(spReqObj);
    }
    this.specialRequirementJobTypeList = spReqArr;

    const ceList = new AbbreviationModel().getList('continuingEdu.');
    let ceArr = [];
    let notShow = [14, 15];
    for (let ce of ceList) {
      let ceObj = {
        item_id: ce.id,
        item_text: ce.fields.short_name,
      };
      if (!notShow.includes(ce.id)) {
        ceArr.push(ceObj);
      }
    }
    this.continuingEducation = ceArr;

    let specialRequirementArr = [];
    if (data?.special_requirements) {
      for (let specialReq of data.special_requirements) {
        let specialRequirementObj = {
          item_id: Number(specialReq),
          item_text: new AbbreviationModel().getById(specialReq,'specialJobType', 'short'),
        };
        specialRequirementArr.push(specialRequirementObj);
      }
      this.specialRequirementJobTypeItems = specialRequirementArr;
    }
    const assistantArr = [];
    if (data?.education_assistant != null) {
      let assitantObj = {
        item_id: data?.education_assistant,
        item_text: new AbbreviationModel().getById(data?.education_assistant,'eduAssistance', 'short'),
      };
      assistantArr.push(assitantObj);
      this.selectedEducationAssistance = assistantArr;
    }
  }

  checkPostCode(event: any) {
    if (
      this.jobForm.value.postcode !== 'undefined' &&
      this.jobForm.value.postcode
    ) {
      var target = codes.find((temp) => temp == this.jobForm.value.postcode);
      if (target) this.isPostcodeValid = false;
      else this.isPostcodeValid = true;
    }
  }

  async doUpdateJob(isClose = false, $event) {
    this.errorMessage = '';
    let id = this.isNewJob == 'true' ? '' : this.jobForm.value.id;
    if (this.selectedCompanies.length == 0) {
      this._snackBar.open(
        this.translate.instant('message.select_user_message'),
        'OK'
      );
      return false;
    }
    let companyId =
      this.isNewJob == 'true'
        ? this.selectedCompanies[0].item_id
        : this.isDublicate === 'true'
          ? this.jobDetails?.company?.id
          : '';
    $event.target.parentElement.disabled = true;
    $event.target.parentElement.classList.add('mat-button-disabled');
    if (!companyId && this.isDublicate === 'false') {
      let data = this.checkValueChange();
      await this.jobService.updateJobDetails(id, data).subscribe(
        (response: any) => {
          this.getJobDetails();
          this.isJobUpdated.emit('update ' + new Date().getTime());
          this._snackBar.open(
            this.translate.instant('message.change_changed'),
            'OK',
            { duration: 9000 }
          );
          if (isClose) {
            this.updateAndClose.emit(response);
          } else {
            this.update.emit(response);
          }
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
        },
        (errorRes) => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          this.errorMessage = errorRes;
        }
      );
    } else {
      let data = this.checkValueChange();
      await this.jobService.addJobDetails(data).subscribe(
        (response: any) => {
          let snackBarRef = this._snackBar.open(
            this.translate.instant('message.change_added'),
            'OK',
            { duration: 9000 }
          );
          if (isClose) {
            this.updateAndClose.emit(response);
          } else {
            this.update.emit(response);
          }
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
        },
        (errorRes) => {
          $event.target.parentElement.disabled = false;
          $event.target.parentElement.classList.remove('mat-button-disabled');
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          this.errorMessage = errorRes;
        }
      );
    }
  }

  resetData() {
    this.close.emit();
  }

  checkValueChange() {
    let continuingEducation = [];
    for (let ce of this.jobForm.value.continuing_education) {
      continuingEducation.push(ce.item_id);
    }

    let id = this.isNewJob == 'true' ? '' : this.jobForm.value.id;
    let companyId =
      this.isNewJob == 'true'
        ? this.selectedCompanies[0]?.item_id
        : this.isDublicate === 'true'
          ? this.jobDetails?.company?.id
          : '';
    let careType = [];
    for (let care of this.selectedCareTypeItems) {
      careType.push(care.item_id);
    }

    let shiftHours = [];
    for (let shift of this.selectedShiftHoursItems) {
      shiftHours.push(shift.item_id);
    }

    let educationType = [];
    this.isEduAssistantShow = false;
    for (let education of this.selectedEducationItems) {
      this.jobForm.controls['educationAssistance'].clearValidators();
      if (education.item_id == 4) {
        this.jobForm.controls['educationAssistance'].setValidators([
          Validators.required,
        ]);
        this.isEduAssistantShow = true;
      }
      this.jobForm.controls['educationAssistance'].updateValueAndValidity();
      educationType.push(education.item_id);
    }

    let workingType = [];
    for (let work of this.selectedScopeItems) {
      workingType.push(work.item_id);
    }

    let specificationCareType = [];
    for (let specification of this.specificationCareItems) {
      specificationCareType.push(specification.item_id);
    }

    let specialRequirementJobType = [];
    for (let specialRequirement of this.specialRequirementJobTypeItems) {
      specialRequirementJobType.push(specialRequirement.item_id);
    }

    if (!companyId && this.isDublicate === 'false') {
      let data = {
        id: id,
        title: this.jobForm.value.title,
        city:
          this.jobForm.value.city === null || this.jobForm.value.city == ''
            ? ''
            : this.jobForm.value.city.trim(),
        postcode:
          this.jobForm.value.postcode === null ||
            this.jobForm.value.postcode == ''
            ? ''
            : this.jobForm.value.postcode.trim(),
        street:
          this.jobForm.value.street === null || this.jobForm.value.street == ''
            ? ''
            : this.jobForm.value.street.trim(),
        is_active: this.jobForm.value.isJobActive ? 1 : 2,
        shift_other:
          this.jobForm.value.shift_other === null ||
            this.jobForm.value.shift_other == ''
            ? ''
            : this.jobForm.value.shift_other.trim(),
        note:
          this.jobForm.value.note === null || this.jobForm.value.note == ''
            ? ''
            : this.jobForm.value.note.trim(),
        internal_note:
          this.jobForm.value.internal_note == undefined ||
            this.jobForm.value.internal_note === null ||
            this.jobForm.value.internal_note == '' ||
            this.userRole === 3
            ? ''
            : this.jobForm.value.internal_note.trim(),
        care_type: careType,
        shift: shiftHours,
        education_type: educationType,
        working_type: workingType,
        specification_care_type: specificationCareType,
        special_requirements: specialRequirementJobType,
        education_assistant:
          !this.isEduAssistantShow ||
            this.jobForm.value?.educationAssistance === null ||
            this.jobForm.value?.educationAssistance[0]?.item_id === undefined
            ? null
            : this.jobForm.value?.educationAssistance[0]?.item_id,
        url:
          this.jobForm.value.url === null || this.jobForm.value.url == ''
            ? ''
            : this.jobForm.value.url.trim(),
        min_salary: this.isShowSalary ? this.minSalary : null,
        max_salary: this.isShowSalary ? this.maxSalary : null,
        continuing_education: continuingEducation,
      };
      return data;
    } else {
      let data = {
        company_id: companyId,
        title: this.jobForm.value.title,
        city:
          this.jobForm.value.city === null || this.jobForm.value.city == ''
            ? ''
            : this.jobForm.value.city.trim(),
        postcode:
          this.jobForm.value.postcode === null ||
            this.jobForm.value.postcode == ''
            ? ''
            : this.jobForm.value.postcode.trim(),
        street:
          this.jobForm.value.street === null || this.jobForm.value.street == ''
            ? ''
            : this.jobForm.value.street.trim(),
        is_active: this.jobForm.value.isJobActive ? 1 : 2,
        shift_other:
          this.jobForm.value.shift_other === null ||
            this.jobForm.value.shift_other == ''
            ? ''
            : this.jobForm.value.shift_other.trim(),
        note:
          this.jobForm.value.note === null || this.jobForm.value.note == ''
            ? ''
            : this.jobForm.value.note.trim(),
        internal_note:
          this.jobForm.value.internal_note === null ||
            this.jobForm.value.internal_note == '' ||
            this.jobForm.value.internal_note === undefined
            ? ''
            : this.jobForm.value.internal_note.trim(),
        care_type: careType,
        shift: shiftHours,
        education_type: educationType,
        working_type: workingType,
        specification_care_type: specificationCareType,
        special_requirements: specialRequirementJobType,
        education_assistant:
          !this.isEduAssistantShow ||
            this.jobForm.value?.educationAssistance === null ||
            this.jobForm.value?.educationAssistance[0]?.item_id === undefined
            ? null
            : this.jobForm.value?.educationAssistance[0]?.item_id,
        url:
          this.jobForm.value.url === null || this.jobForm.value.url == ''
            ? ''
            : this.jobForm.value.url.trim(),
        min_salary: this.isShowSalary ? this.minSalary : null,
        max_salary: this.isShowSalary ? this.maxSalary : null,
        continuing_education: continuingEducation,
      };
      const compareRes =
        JSON.stringify(data) == JSON.stringify(this.dublicateDetails);
      // this.isValueChange = false;
      if (compareRes === true) {
        this.isValueChange = false;
      } else {
        this.isValueChange = true;
      }
      return data;
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
