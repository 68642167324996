import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  returnUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router) { }


  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError),
      tap(resData => {
        if (resData) {
          this.handleAuthentication(resData);
        }
      })
    );
  }

  postv2(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${environment.PATH}${url}`, data, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${environment.PATH}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  doSignIn(postData) {
    return this.post('accounts/api-token-auth/', postData);
  }

  doCompanySignIn(postData) {
    return this.postv2('accounts/company-login/', postData);
  }

  getSampleRes() {
    let httpOptions = this.getHTTPOption();
    return this.get(`accounts/applier/`, httpOptions);
  }

  getLoginCompanyData(postData) {
    return this.post(`accounts/company-user-api-token/`, postData);
    // return this.post(`accounts/user/`,postData);
  }

  public handleAuthentication(resData) {
    let expiresIn = 3600;
    //We are configuring expiration time to 1 day
    let expirationTime = new Date().getTime() + 24 * expiresIn * 1000;

    if (resData.user) {
      let applicantData;
      applicantData = {
        id: resData?.user.id,
        email: resData?.user.email,
        first_name: resData?.user.first_name,
        last_name: resData?.user.last_name,
        completed_profile: resData?.user.completed_profile,
        role: resData?.user.role,
        token: 'Token ' + resData?.token,
        social_account: resData?.social_account ? resData?.social_account[0] : null
      };

      applicantData = { ...applicantData, expiresIn: expirationTime }
      this.autoLogout(24 * expiresIn * 1000);

      if (resData?.user.role == 3)
        localStorage.setItem('companyData', JSON.stringify(applicantData));
      else
        localStorage.setItem('agentData', JSON.stringify(applicantData));
    }
  }


  public handleError(errorRes: HttpErrorResponse) {
    return throwError(errorRes);
  }



  getHTTPOption(isMultiPartForm = false) {
    const token = this.getAuthToken();
    let headers_object;
    if (token) {
      if (isMultiPartForm) {
        headers_object = new HttpHeaders({
          'Authorization': token,
          "Accept": "application/json"
          // "Content-Type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
        });
      } else {
        headers_object = new HttpHeaders({
          'Authorization': token
        });
      }

      return {
        headers: headers_object
      };
    }

    return null;

  }

  getAuthToken() {
    let localData = this.getUserData();
    if (localData) {
      return localData.token;
    }
    this.router.navigate(['/alogin']);
  }


  getUserData() {
    // let userData = JSON.parse(localStorage.getItem('agentData'));
    let userData = (JSON.parse(localStorage.getItem('agentData'))) ? JSON.parse(localStorage.getItem('agentData')) : JSON.parse(localStorage.getItem('companyData'));
    // let companyData = JSON.parse(localStorage.getItem('companyData'));
    if (userData) {
      let expireTime = userData?.expiresIn;
      if (new Date().getTime() > expireTime) {
        localStorage.removeItem('companyData');
        localStorage.removeItem('agentData');
        this.router.navigate(['/login']);
      }
      return userData;
    }
    let url = window.location.href;

    //TODO:replace below flow with authGuard.
    let isRegister = url.substring(url.lastIndexOf('/') + 1) === 'register';
    // let isResetPasswordRequest = url.substring(url.lastIndexOf('/') + 1) === 'reset-password';
    let isResetPasswordUrl = url.includes('reset-password')
    let isPublicUrl = url.includes('public')
    let isCompanyLogin = url.includes('company-login')
    if (!isRegister && !isResetPasswordUrl && !isPublicUrl && isCompanyLogin) {
      this.router.navigate(['/login']);
    }
  }

  autoLogout(expirationDuration: number) {
    setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  logout() {
    localStorage.removeItem('agentData');
    this.router.navigate(['/login']);
    setTimeout(() => {
      // window.location.reload();
      this.router.navigate(['/login']);
    }, 1000);
    // this.router.navigate(['/login']);
  }


}
