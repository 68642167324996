import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { JobService } from 'src/app/services/job.service';
import { CompanyDetailComponent } from '../../company-home/company-detail/company-detail.component';
import { DialogData } from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  type: string;
  companyId: any;
  imagePath: any;
  companyProfileForm: FormGroup;
  isImgError = false;
  imgErrorMessage = '';
  imgSrc = '';
  imagePreview: string;
  chatFormData: any;

  constructor(
    private dialogRef: MatDialogRef<CompanyDetailComponent>,
    private _sanitizer: DomSanitizer,
    private jobService: JobService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    (this.type = data['type']), (this.companyId = data['companyId']);
  }

  ngOnInit() {
    // this.uploadForm = new FormGroup({
    //   company_id: new FormControl(this.companyId),
    //   image: new FormControl(null),
    //   picture_type: new FormControl(this.type),
    // });

    this.companyProfileForm = this.formBuilder.group({
      company_id: [this.companyId],
      image: [],
      picture_type: [this.type],
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name
    );
    if (fileToReturn.size <= 5000000) {
      this.isImgError = false;

      const reader = new FileReader();
      this.companyProfileForm.get('image').setValue(fileToReturn);
      reader.onload = () => {
        this.imagePreview = reader.result as string;
        this.imgSrc = this.imagePreview;
      };
      reader.readAsDataURL(fileToReturn);
    } else {
      this.isImgError = true;
      this._snackBar.open('', 'Die Logo-Größe sollte weniger als 5 MB betragen', { duration: 5000 });
    }
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded(image: LoadedImage) {
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  imageCroped(image) {
  }

  afterClosed($event): void {
    this.chatFormData = new FormData();
    let logoType = typeof this.companyProfileForm.get('image').value;
    if (logoType == 'object') {
      this.chatFormData.append(
        'image',
        this.companyProfileForm.get('image').value
      );
    }
    this.chatFormData.append(
      'picture_type',
      this.companyProfileForm.get('picture_type').value
    );
    this.chatFormData.append(
      'company_id',
      this.companyProfileForm.get('company_id').value
    );

    this.jobService.addImages(this.chatFormData).subscribe(
      (response: any) => {
        this.onNoClick();
        this.dialogRef.close({
          type: this.type
        });
      },
      (error) => {}
    );
  }

  // afterClosed($event): void {
  //   this.type
  // }

  onNoClick() {
    this.dialogRef.close();
  }

}
