import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthenticationResult } from '@azure/msal-browser';
import { CompanyAuthService } from 'src/app/services/company-auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { sign } from 'crypto';
// import Web3 from 'web3';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  @ViewChild(MatAccordion) accordion: MatAccordion;
  showSpinner = false;
  isGetUserApiCall = false;
  isLogin = true;
  linkexpire = false;
  checkEmailTemplate = false;
  errorMessage: string;
  successMessage: string;
  userDetails: string;
  companyList = [];
  loginForm: FormGroup;
  loginResult: any;
  constructor(
    private msalService: MsalService, private router: Router,private _snackBar: MatSnackBar,
    private CompanyAuthService: CompanyAuthService, private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    // this.msalService.instance.handleRedirectPromise().then(res=>{
    //   if(res != null && res.account != null){
    //     this.msalService.instance.setActiveAccount(res.account);
    //     this.router.navigate(['home']);
    //   }
    // })
    if (localStorage.getItem("agentData") !== null) {
      this.router.navigate(['applicants']);
    } else if (localStorage.getItem("companyData") !== null) {
      this.router.navigate(['dashboard']);
    }
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // console.log("this.isGetUserApiCall   ", this.isGetUserApiCall)
      // return false;
      if (params?.id !== undefined && this.isGetUserApiCall === false && params?.id.length > 0) {
        console.log(params?.id, " param ids ");
        this.isGetUserApiCall = true;
        this.getLoginUser(params?.id, params?.company_id);
      }
      console.log("params?.urlexipre", params?.urlexipre)
      if (params?.urlexipre !== undefined && params?.urlexipre === "true") {
        console.log(params?.urlexipre, " urlexipre ");
        this.isLogin = false;
        this.linkexpire = true;
        this.checkEmailTemplate = false;
      }
    });

    this.initForm();
  }

  initForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      // email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      // email: new FormControl('', [Validators.required,Validators.email]),
    });
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  async login() {
    this.errorMessage = this.successMessage = '';
    if (this.loginForm.valid) {
      this.showSpinner = true;
      const postData = this.loginForm.value;
      await this.CompanyAuthService.doCompanySignIn(postData).subscribe((response: any) => {
        console.log("------ BE RESPONSE ------", response);
        this.loginResult = response;
        this.successMessage = this.loginResult.message;
        this.loginForm.controls['email'].setErrors({ 'incorrect': true });
        // this.loginForm.invalid = true;
        this.isLogin = false;
        this.linkexpire = false;
        this.checkEmailTemplate = true;
        this.showSpinner = false;
      }, (errorRes) => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        // this.errorMessage = errorRes; 
      });
    }
  }

  gotoLogin() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['login']);
  }

  async getLoginUser(userId, company_id) {
    this.errorMessage = this.successMessage = '';
    this.showSpinner = true;
    if (userId) {
      const data = { user_id: userId, company_id: company_id }
      await this.CompanyAuthService.getLoginCompanyData(data).subscribe((response: any) => {
        console.log("------ BE RESPONSE ------", response.status);
        console.log(response);
        // this.isLogin = false;
        this.showSpinner = false;
        // if (response.status == 200) {
        this.userDetails = response;
        this.loginForm.controls['email'].setErrors({ 'incorrect': true });
        this.router.navigate(['dashboard']);
        // } else {
        //   this.errorMessage = (response?.statusText) ? response?.statusText : response?.message;
        // }
      }, (errorRes) => {
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        // this.errorMessage = errorRes;
        // this.errorMessage = 'An unknown error occurred!';
        this.showSpinner = false;
      });
    }
  }

}
