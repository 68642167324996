

<button mat-icon-button style="float: right; margin-bottom: 15px;" cdkFocusInitial>
  <mat-icon (click)="onNoClick()">close</mat-icon>
</button>
<div >
  <mat-dialog-content  style= "height: 350px; width: 700px; ">
    <ng-multiselect-dropdown
      
      class="multiselect-dropdown"
      placeholder="Select Columns"
      [(ngModel)]="selectedType"
      [settings]="dropdownSettings"
      [data]="dropdown"
      
      >
    </ng-multiselect-dropdown>
  </mat-dialog-content>

  <button mat-flat-button class="btn" color="primary" style="margin: 15px; float: right;" (click)="onSave()" [disabled]="!selectedType">
    Excel Export
  </button>
</div>
