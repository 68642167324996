import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApplicantService } from '../../../services/applicant.service';
import { AbbreviationModel } from '../../../models/abbreviation.model';
import { JobService } from 'src/app/services/job.service';
import { ValidationAtLeastOneFieldService } from 'src/app/services/validation-at-least-one-field.service';
const codes = require('german-postal-codes');

@Component({
  selector: 'app-applier-sidebar',
  templateUrl: './applier-sidebar.component.html',
  styleUrls: ['./applier-sidebar.component.scss'],
})
export class ApplierSidebarComponent implements OnInit {
  @Input() applierId: any;
  @Input() editMode: boolean = true;
  @Output() updateAndClose = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() handleError = new EventEmitter<any>();
  @ViewChild('drawer') drawer: MatSidenav;

  applicantForm: FormGroup;
  dropdownSettings: IDropdownSettings;
  dropdownEducationSettings: IDropdownSettings;
  dropdownEducationList = [];
  additionalQuestionList = [];
  selectedEducationItems = [];
  selectedQuestionItems = [];
  dropdownCareTypeList = [];
  selectedCareTypeItems = [];
  dropdownShiftHoursList = [];
  dropdownVaccinationTypeList = [];
  selectedVaccinationTypeList = [];
  selectedDrivingLicanse = [];
  selectedShiftHoursItems = [];
  scopeList = [];
  referencrList = [];
  selectedScopeItems = [];
  selectedReferencrByItems = [];
  hideOther = false;
  token = '';
  extID = '';
  dropdownAgents = [];
  continuingEducation = [];
  licenseList = [];
  selectedAgent = [];
  selectedContinuingEducation = [];
  applierData: any;
  showOtherRef = false;
  isEduAssistantShow: boolean = false;
  isEduOtherShow: boolean = false;
  isPostcodeValid = false;
  isShiftHoursItem = false;
  isShiftOtherItem = false;
  isContiEduShow = false;
  ShiftOthersItems = '';
  jobId: number;
  companyId: number;

  constructor(
    private applicantService: ApplicantService,
    private jobService: JobService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.initApplicantForm();
    this.getAgentList();
    this.getApplierDetails();
  }

  initApplicantForm() {
    this.applicantForm = this._formBuilder.group(
      {
        id: [],
        name: ['', Validators.required],
        date: [],
        education: ['', Validators.required],
        care_type: ['', Validators.required],
        shift_hour: [],
        scope: ['', Validators.required],
        postcode: ['', Validators.required],
        start_date: ['', Validators.required],
        email: ['', Validators.required],
        last_2_employers: [],
        phone_number: ['', Validators.required],
        shift_other: [],
        reference_by: [],
        others: [],
        education_assistant: [],
        token: [],
        extID: [],
        agent_user: [],
        continuing_education: [],
        continous_education_other: [],
        driving_license: [],
        education_other: [],
        vaccination_status: ['', Validators.required],
        vaccination_date: [],
      },
      {
        validator: ValidationAtLeastOneFieldService(Validators.required, [
          'shift_other',
          'shift_hour',
        ]),
      }
    );
  }

  getApplierDetails() {
    this.jobService.getApplierDetails(this.applierId).subscribe((res: any) => {
      this.applierData = res;
      this.jobId = res.jobId;
      this.companyId = res.company_id;
      if (
        this.applierData.postcode !== 'undefined' &&
        this.applierData.postcode
      ) {
        var target = codes.find((temp) => temp == this.applierData.postcode);
        if (target) this.isPostcodeValid = false;
        else this.isPostcodeValid = true;
      }

      if (this.applierData.shift_hour.length) {
        this.isShiftHoursItem = false;
      } else {
        this.isShiftHoursItem = true;
      }

      if (!this.applierData.driving_license) {
        this.applierData.driving_license = 1;
      }

      if (this.applierData.shift_other) {
        this.isShiftOtherItem = false;
      } else {
        this.isShiftOtherItem = true;
      }
      this.ShiftOthersItems = this.applierData.shift_other;
      this.patchForm();
      this.inItSelect2List(this.applierData);
    });
  }

  patchForm() {
    this.applicantForm.patchValue({
      id: this.applierData.id,
      name: this.applierData.name,
      date: this.applierData.date,
      education: this.applierData.education,
      care_type: this.applierData.care_type,
      shift_hour: this.applierData.shift_hour,
      scope: this.applierData.scope,
      postcode: this.applierData.postcode,
      start_date: this.applierData.start_work_date,
      email: this.applierData.email,
      last_2_employers: this.applierData.last_2_employers,
      phone_number: this.applierData.phone_number,
      others: this.applierData.others,
      shift_other: this.applierData.shift_other,
      reference_by: this.applierData.reference_by,
      education_assistant: this.applierData.education_assistant,
      education_other: this.applierData.education_other,
      vaccination_status: this.applierData.vaccination_status.toString(),
      vaccination_date: this.applierData.vaccination_date,
      driving_license: this.applierData.driving_license.toString(),
      continous_education_other: this.applierData.continuing_education_other,
    });
    this.token = this.applierData.token;
    this.extID = this.applierData.extID;
  }

  inItSelect2List(rowData) {
    this.selectedAgent = [];
    if (rowData?.agent_user) {
      this.selectedAgent.push({
        item_id: rowData.agent_user.id,
        item_text:
          rowData.agent_user.first_name + ' ' + rowData.agent_user.last_name,
      });
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownEducationSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    const educationTypeList = new AbbreviationModel().getList('educationType');
    let eduArr = [];
    for (let edu of educationTypeList) {
      let eduObj = {
        item_id: edu.id,
        item_text: edu.fields.short_name,
      };
      eduArr.push(eduObj);
    }
    this.dropdownEducationList = eduArr;

    if (rowData.education) {
      let educationArr = [];
      for (let edu of rowData.education) {
        let educationObj = {
          item_id: parseInt(edu),
          item_text: new AbbreviationModel().getById(edu,'educationType', 'short'),
        };
        educationArr.push(educationObj);
        this.applicantForm.controls['education_assistant'].clearValidators();
        if (edu == 4) {
          this.applicantForm.controls['education_assistant'].setValidators([
            Validators.required,
          ]);
          this.isEduAssistantShow = true;
        }
        if (edu == 7) {
          this.isEduOtherShow = true;
        }
        this.applicantForm.controls[
          'education_assistant'
        ].updateValueAndValidity();
      }
      this.selectedEducationItems = educationArr;
    }

    const queList = new AbbreviationModel().getList('additionalQue.');
    let queArr = [];
    for (let que of queList) {
      let queObj = {
        item_id: que.id,
        item_text: que.fields.short_name,
      };
      queArr.push(queObj);
    }
    this.additionalQuestionList = queArr;

    if (rowData.education_assistant) {
      let qArr = [];
      let qObj = {
        item_id: parseInt(rowData.education_assistant),
        item_text: new AbbreviationModel().getById(rowData.education_assistant,'eduAssistance', 'short'),
      };
      qArr.push(qObj);
      this.selectedQuestionItems = qArr;
    }

    const careTypeList = new AbbreviationModel().getList('careType');
    let careArr = [];
    for (let care of careTypeList) {
      let careObj = {
        item_id: care.id,
        item_text: care.fields.short_name,
      };
      careArr.push(careObj);
    }
    this.dropdownCareTypeList = careArr;

    const vaccinationTypeList = new AbbreviationModel().getList('vaccinationType');
    let vaccinArr = [];
    for (let vaccin of vaccinationTypeList) {
      let vaccinObj = {
        item_id: vaccin.id,
        item_text: vaccin.fields.short_name,
      };
      vaccinArr.push(vaccinObj);
    }
    this.dropdownVaccinationTypeList = vaccinArr;

    if (rowData.vaccination_status) {
      let vaccionatArr = [];
      let vaccionatObj = {
        item_id: Number(rowData.vaccination_status),
        item_text: new AbbreviationModel().getById(rowData.vaccination_status,'vaccinationType', 'short'),
      };
      vaccionatArr.push(vaccionatObj);
      this.selectedVaccinationTypeList = vaccionatArr;
    }

    if (rowData.driving_license) {
      let dlArr = [];
      let dlObj = {
        item_id: Number(rowData.driving_license),
        item_text: new AbbreviationModel().getById(rowData.driving_license,'licenseType', 'short'),
      };
      dlArr.push(dlObj);
      this.selectedDrivingLicanse = dlArr;
    }

    if (rowData.care_type) {
      let careTypeArr = [];
      for (let care_type of rowData.care_type) {
        let careTypeObj = {
          item_id: Number(care_type),
          item_text: new AbbreviationModel().getById(care_type,'careType', 'short'),
        };
        careTypeArr.push(careTypeObj);
      }
      this.selectedCareTypeItems = careTypeArr;
    }

    const ShiftHoursList = new AbbreviationModel().getList('shiftHours');
    let hoursArr = [];
    for (let hours of ShiftHoursList) {
      let hoursObj = {
        item_id: hours.id,
        item_text: hours.fields.short_name,
      };
      hoursArr.push(hoursObj);
    }
    this.dropdownShiftHoursList = hoursArr;

    if (rowData.shift_hour) {
      let shiftHourArr = [];
      for (let shift_hour of rowData.shift_hour) {
        let shiftHourObj = {
          item_id: Number(shift_hour),
          item_text: new AbbreviationModel().getById(shift_hour,'shiftHours', 'short'),
        };
        shiftHourArr.push(shiftHourObj);
      }
      this.selectedShiftHoursItems = shiftHourArr;
    }

    const scpList = new AbbreviationModel().getList('scope');
    let scpArr = [];
    for (let scp of scpList) {
      let scpObj = {
        item_id: scp.id,
        item_text: scp.fields.short_name,
      };
      scpArr.push(scpObj);
    }
    this.scopeList = scpArr;

    if (rowData.working_type) {
      let scopeArr = [];
      for (let scope of rowData.working_type) {
        let shiftHourObj = {
          item_id: Number(scope),
          item_text: new AbbreviationModel().getById(scope,'scope', 'short'),
        };
        scopeArr.push(shiftHourObj);
      }
      this.selectedScopeItems = scopeArr;
    }

    const refenceList = new AbbreviationModel().getList('reference');
    let refenceArr = [];
    for (let refence of refenceList) {
      let refenceObj = {
        item_id: refence.id,
        item_text: refence.fields.short_name,
      };
      refenceArr.push(refenceObj);
    }
    this.referencrList = refenceArr;

    const ceList = new AbbreviationModel().getList('continuingEdu.');
    let ceArr = [];
    for (let ce of ceList) {
      let ceObj = {
        item_id: ce.id,
        item_text: ce.fields.short_name,
      };
      ceArr.push(ceObj);
    }
    this.continuingEducation = ceArr;

    if (rowData.continuing_education) {
      let ceArr = [];
      for (let ce of rowData.continuing_education) {
        let shiftHourObj = {};
        shiftHourObj = {
          item_id: Number(ce),
          item_text: new AbbreviationModel().getById(ce,'continuingEdu.', 'short'),
        };
        ceArr.push(shiftHourObj);
      }
      if (rowData.continuing_education.includes(15) == true || (rowData.continuing_education_other != null && rowData.continuing_education_other != "")) {
          this.isContiEduShow = true;
          if (!rowData.continuing_education.includes(15)) {
            ceArr.push({
              item_id: Number(15),
              item_text: new AbbreviationModel().getById(15,'continuingEdu.', 'short'),
            });
          }
        }        
      this.selectedContinuingEducation = ceArr;
    }

    if (rowData.reference_by) {
      let refArr = [];
      for (let rfnce of rowData.reference_by) {
        if (rfnce == 6) {
          this.showOtherRef = true;
        }
        let refObj = {
          item_id: Number(rfnce),
          item_text: new AbbreviationModel().getById(rfnce,'reference', 'short'),
        };
        if (rfnce != 'None') {
          refArr.push(refObj);
        }
      }
      this.selectedReferencrByItems = refArr;
    }

    const licenseList = new AbbreviationModel().getList('licenseType');
    let licenseArr = [];
    for (let license of licenseList) {
      let licenseObj = {
        item_id: license.id,
        item_text: license.fields.short_name,
      };
      licenseArr.push(licenseObj);
    }
    this.licenseList = licenseArr;
  }

  checkShiftHour() {
    if (this.selectedShiftHoursItems.length) {
      this.isShiftHoursItem = false;
    } else {
      this.isShiftHoursItem = true;
    }
  }

  checkShiftOthers(e: any) {
    if (e) {
      this.isShiftOtherItem = false;
    } else {
      this.isShiftOtherItem = true;
    }
  }

  getAgentList() {
    this.jobService.getAgent(true).subscribe((res: any) => {
      let agents = [];
      res.forEach((agent) =>
        agents.push({
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        })
      );
      this.dropdownAgents = agents;
    });
  }

  checkShowReferenceOther() {
    this.showOtherRef = false;
    const res = this.selectedReferencrByItems.find((s) => s.item_id == '6');
    if (res?.item_id == 6) {
      this.showOtherRef = true;
    }
  }

  checkEducationList() {
    this.isEduAssistantShow = false;
    this.isEduOtherShow = false;
    this.applicantForm.controls['education_assistant'].clearValidators();
    this.applicantForm.controls['education_other'].clearValidators();
    for (let education of this.selectedEducationItems) {
      if (education.item_id == 4) {
        this.applicantForm.controls['education_assistant'].setValidators([
          Validators.required,
        ]);
        this.isEduAssistantShow = true;
      }
      if (education.item_id == 7) {
        this.applicantForm.controls['education_other'].setValidators([
          Validators.required,
        ]);
        this.isEduOtherShow = true;
      }
      this.applicantForm.controls[
        'education_assistant'
      ].updateValueAndValidity();
      this.applicantForm.controls['education_other'].updateValueAndValidity();
    }
  }

  checkContiEducationList() {
    this.isContiEduShow = false;
    for (let education of this.selectedContinuingEducation) {
      if (education.item_id == 15) {
        this.isContiEduShow = true;
      }
    }
  }

  checkPostCode(event: any) {
    if (
      this.applicantForm.value.postcode !== 'undefined' &&
      this.applicantForm.value.postcode
    ) {
      var target = codes.find(
        (temp) => temp == this.applicantForm.value.postcode
      );
      if (target) this.isPostcodeValid = false;
      else this.isPostcodeValid = true;
    }
  }

  updateData(isClose = false) {
    let continuingEducation = [];
    for (let ce of this.applicantForm.value.continuing_education) {
      continuingEducation.push(ce.item_id);
    }
    let id = this.applicantForm.value.id;
    let careType = [];
    for (let care of this.selectedCareTypeItems) {
      careType.push(care.item_id);
    }

    let referenceBy = [];
    for (let ref of this.selectedReferencrByItems) {
      referenceBy.push(ref.item_id);
    }

    let shiftHours = [];
    for (let shift of this.selectedShiftHoursItems) {
      shiftHours.push(shift.item_id);
    }

    let workingType = [];
    for (let work of this.selectedScopeItems) {
      workingType.push(work.item_id);
    }

    let questionAnswer = [];
    for (let work of this.selectedQuestionItems) {
      questionAnswer.push(work.item_id);
    }

    let education = [];
    for (let edu of this.selectedEducationItems) {
      education.push(edu.item_id.toString());
    }

    let dt;
    if (typeof this.applicantForm.value.start_date == 'object') {
      var newdate = new Date(this.applicantForm.value.start_date);
      dt =
        newdate.getFullYear() +
        '-' +
        ((newdate || new Date()).getMonth() + 1) +
        '-' +
        newdate.getDate();
    } else {
      dt = this.applicantForm.value.start_date;
    }

    let vacc_dt;
    if (typeof this.applicantForm.value.vaccination_date == 'object') {
      var newdate = new Date(this.applicantForm.value.vaccination_date);
      vacc_dt =
        newdate.getFullYear() +
        '-' +
        ((newdate || new Date()).getMonth() + 1) +
        '-' +
        newdate.getDate();
    } else {
      vacc_dt = this.applicantForm.value.vaccination_date;
    }

    let data = {
      id: id,
      name: this.applicantForm.value.name,
      care_type: careType,
      shift_hour: shiftHours,
      working_type: workingType,
      postcode: this.applicantForm.value.postcode,
      start_work_date: dt,
      education: education,
      email: this.applicantForm.value.email,
      last_2_employers: this.applicantForm.value.last_2_employers,
      phone_number: this.applicantForm.value.phone_number,
      others: this.showOtherRef === true ? this.applicantForm.value.others : '',
      shift_other: this.applicantForm.value.shift_other,
      driving_license: this.applicantForm.value.driving_license
        ? this.applicantForm.value.driving_license
        : null,
      agent_user:
        this.applicantForm.value.agent_user?.length > 0
          ? this.applicantForm.value.agent_user[0]?.item_id
          : null,
      reference_by: referenceBy,
      education_assistant:
        !this.isEduAssistantShow ||
        this.selectedQuestionItems[0]?.item_id === undefined
          ? null
          : this.selectedQuestionItems[0]?.item_id,
      continuing_education: continuingEducation,
      continuing_education_other: 
      !this.isContiEduShow ||
      this.applicantForm.value.continous_education_other === ''
        ? null
        : this.applicantForm.value.continous_education_other,
      education_other:
        !this.isEduOtherShow || this.applicantForm.value.education_other === ''
          ? null
          : this.applicantForm.value.education_other,
      vaccination_status: this.applicantForm.value.vaccination_status
        ? this.applicantForm.value.vaccination_status
        : null,
      vaccination_date:
        this.applicantForm.value.vaccination_status == 3 ? vacc_dt : null,
    };

    this.applicantService.updateApplierDetails(id, data).subscribe(
      (response: any) => {
        if (isClose) {
          this.updateAndClose.emit(response);
        } else {
          this.update.emit(response);
        }
      },
      (error) => {
        this.handleError.emit();
      }
    );
  }

  encode(name) {
    return encodeURIComponent('Hallo ' + name);
  }

  close() {
    this.doClose.emit();
  }
}
