export interface HistoryInterface {
    content: string;
    applier_id: number;
    status_id: number;
    channel_id: number;
}
export class History implements HistoryInterface {
    constructor(
        public content: string,
        public applier_id: number,
        public status_id: number,
        public channel_id: number,
        // public costum_date: Date
    ) { }
}

