<div *ngIf="!isSpinner" class="job-list-div">
  <mat-card>
    <mat-card-content>
      <form [formGroup]="jobForm">
        <table class="edit-form">
          <tr>
            <td class="label-td" translate> job_details.title </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>job_details.title </mat-label>
                <input matInput formControlName="title" (change)="checkValueChange()" maxlength="50">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['title'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.street </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <mat-label translate>job_details.street</mat-label>
                <input matInput formControlName="street" (change)="checkValueChange()">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['street'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.city <span *ngIf="editMode" class="is-validate">*</span></td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input matInput formControlName="city" (change)="checkValueChange()">
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['city'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.postcode <span *ngIf="editMode" class="is-validate">*</span></td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input matInput formControlName="postcode" (change)="checkValueChange()" (keypress)="numberOnly($event)"
                  (ngModelChange)="checkPostCode($event)" (focus)="checkPostCode($event)"
                  (change)="checkPostCode($event)">
                  <mat-hint class="postcode-invalid" *ngIf="(isPostcodeValid && jobForm.controls['postcode'].value !== '')">
                    <p translate>message.postcode_invalid</p>
                  </mat-hint>
              </mat-form-field>
              <!-- <mat-error *ngIf="(isPostcodeValid && jobForm.controls['postcode'].value !== '')" style="margin-top: -10px;">
                <p>Die Postleitzahl gibt es in Deutschland nicht</p>
              </mat-error> -->
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['postcode'].value}}</td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.website</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input matInput formControlName="url" (change)="checkValueChange()" />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['url'].value}}</td>
          </tr>
          <tr *ngIf="isNewJob === 'true'">
            <td class="label-td" translate>job_details.company <span *ngIf="editMode" class="is-validate">*</span> </td>
            <td class="edit-td" *ngIf="editMode">
              <div class="col filters-col">
                <ng-multiselect-dropdown #multiSelect (onSelect)="checkValueChange()" (onDeSelect)="checkValueChange()"
                  formControlName="companyName" class="multiselect-dropdown" placeholder="{{ 'job_details.not_selected' | translate }}"
                  [settings]="singleSelectiondropdownSettings" [data]="dropdownCompanies"
                  [(ngModel)]="selectedCompanies">
                </ng-multiselect-dropdown>
              </div>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedCompanies">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.shift <span *ngIf="editMode" class="is-validate">*</span></td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown #multiSelect formControlName="shifts" (onDeSelect)="checkValueChange()"
                (onSelect)="checkValueChange()" placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="dropdownShiftHoursList" [(ngModel)]="selectedShiftHoursItems">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedShiftHoursItems">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.shift_other <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy" class="drawer-mat-field">
                <input matInput type="text" (change)="checkValueChange()" formControlName="shift_other" />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['shift_other'].value}}</td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.salary_range
              <!-- <span *ngIf="editMode" class="is-validate">*</span> -->
            </td>
            <!-- <td></td> -->
            <td class="edit-td" *ngIf="editMode">
              <!-- <ng5-slider [(value)]="minSalary" [(highValue)]="maxSalary" [options]="Options"
              (click)="checkSalary(minSalary, maxSalary)" (click)="checkValueChange()" *ngIf="isShowSalary" style="width: 85%; margin-top: 20px;  padding-top: 2px; padding-bottom: 2px;"></ng5-slider> -->
              
              <mat-form-field appearance="legacy" class="drawer-mat-field" style="width: 35%;" *ngIf="isShowSalary" >
                <input matInput class="ng-invalid" type="number" max="maxSalary" [(ngModel)]="minSalary" [ngModelOptions]="{standalone: true}" (change)="checkSalary(minSalary, maxSalary)" (keyup)="checkSalary(minSalary, maxSalary)" />
              </mat-form-field>
              <mat-icon *ngIf="isShowSalary" style="margin-left: 20px; margin-right: 20px;" >settings_ethernet</mat-icon>
              <mat-form-field appearance="legacy" class="drawer-mat-field" style="width: 35%;" *ngIf="isShowSalary" >
                <input matInput  type="number" min="1" [(ngModel)]="maxSalary" [ngModelOptions]="{standalone: true}" (change)="checkSalary(minSalary, maxSalary)" (keyup)="checkSalary(minSalary, maxSalary)" />
              </mat-form-field>
              <button mat-icon-button color="primary" style="width: 7%; float: right;" (click)="showSalary(!isShowSalary)"><mat-icon>{{isShowSalary ? "delete" : "add"}}</mat-icon></button>
            </td>
          </tr>
          <tr style="height: unset;" *ngIf="jobForm.controls['min_salary'].errors">
            <td></td>
            <td>
          <mat-hint class="postcode-invalid" >
            <p style="margin: -10px 0 12px" translate> Das Mindestgehalt sollte nicht höher sein als das Maximum</p>
          </mat-hint>
          </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.care_type <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown #multiSelect formControlName="careTypes" (onDeSelect)="checkValueChange()"
                (onSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="dropdownCareTypeList" [(ngModel)]="selectedCareTypeItems">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedCareTypeItems">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.working_type <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown #multiSelect formControlName="workingTypes" (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="scopeList" [(ngModel)]="selectedScopeItems">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedScopeItems">{{i.item_text}}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.education_type <span *ngIf="editMode"
                class="is-validate">*</span></td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown #multiSelect formControlName="educationTypes" (onSelect)="checkValueChange()"
                (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="educationList" [(ngModel)]="selectedEducationItems">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedEducationItems">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr *ngIf="isEduAssistantShow">
            <td class="label-td" translate>job_details.education_assistant <span *ngIf="editMode"
                class="is-validate">*</span></td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown #multiSelect formControlName="educationAssistance"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="singleSelectiondropdownSettings" [data]="dropdownEducationAssistance"
                [(ngModel)]="selectedEducationAssistance">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedEducationAssistance">{{i.item_text}}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.continuing_education</td>
            <td *ngIf="editMode">
              <ng-multiselect-dropdown formControlName="continuing_education"  (onSelect)="checkValueChange()"
              (onDeSelect)="checkValueChange()" class="multiselect-dropdown"
                placeholder="{{ 'job_details.not_selected' | translate }}" [settings]="dropdownSettings" [data]="continuingEducation"
                [(ngModel)]="selectedContinuingEducation" [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of selectedContinuingEducation">{{i.item_text}}</tag>
            </td>
          </tr>

          <tr>
            <td class="label-td" translate>job_details.specification_care_items</td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown (onSelect)="checkValueChange()" (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="dropdownSpecificationJobCareTypeList"
                [(ngModel)]="specificationCareItems" [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of specificationCareItems">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.special_requirement_job_type</td>
            <td class="edit-td" *ngIf="editMode">
              <ng-multiselect-dropdown (onSelect)="checkValueChange()" (onDeSelect)="checkValueChange()"
                placeholder="{{ 'job_details.not_selected' | translate }}"
                [settings]="dropdownSettings" [data]="specialRequirementJobTypeList"
                [(ngModel)]="specialRequirementJobTypeItems" [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </td>
            <td *ngIf="!editMode">
              <tag *ngFor="let i of specialRequirementJobTypeItems">{{i.item_text}}</tag>
            </td>
          </tr>
          <tr>
            <td class="label-td" translate>job_details.note</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input matInput formControlName="note" (change)="checkValueChange()" />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['note'].value}}</td>
          </tr>
          <tr *ngIf="userRole == 2">
            <td class="label-td" translate>job_details.internal_note</td>
            <td class="edit-td" *ngIf="editMode">
              <mat-form-field appearance="legacy">
                <input matInput formControlName="internal_note" (change)="checkValueChange()" />
              </mat-form-field>
            </td>
            <td *ngIf="!editMode">
              {{jobForm.controls['internal_note'].value}}</td>
          </tr>
          <tr class="btn_active">
            <td class="label-td" translate>job_details.job_status <span *ngIf="editMode" class="is-validate">*</span>
            </td>
            <td class="edit-td" *ngIf="editMode">
              <mat-slide-toggle formControlName="isJobActive" color="primary" class="mt-2 w-100"
                (change)="checkValueChange()">
              </mat-slide-toggle>
            </td>
            <td class="edit-td" *ngIf="!editMode">
              <mat-slide-toggle formControlName="isJobActive" color="primary" class="mt-2 w-100" [disabled]="true"
                (change)="checkValueChange()">
              </mat-slide-toggle>
            </td>
            <br>
          </tr>
        </table>
      </form>
    </mat-card-content>

    <div class="footer" #footer translate>
      <button [disabled]="jobForm.invalid ||  !isValueChange" mat-flat-button color="primary"
        *ngIf="isNewJob == 'true' || isNewJob == 'false'" (click)="doUpdateJob(isClose = true,$event)">
        {{'jobs.btn_save_and_close' | translate}}</button> &nbsp;
      <button [disabled]="jobForm.invalid " mat-flat-button *ngIf="isNewJob == 'true'" color="primary"
        (click)="doUpdateJob(isClose = true,$event)">{{'jobs.btn_save'|translate}}</button>&nbsp;
      <button [disabled]="jobForm.invalid || !isValueChange" mat-flat-button color="primary" *ngIf="isNewJob == 'false'"
        (click)="doUpdateJob(isClose = false,$event)">{{'jobs.btn_save'|translate}}</button>
      <button mat-flat-button (click)="resetData()"><span translate> jobs.btn_reset </span></button>
    </div>
  </mat-card>
</div>
<ng-container *ngIf="isSpinner">
  <ngx-skeleton-loader count="2" appearance="line" [theme]="{'height.px': 100}"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="5" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
</ng-container>