import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HistoryMatchService } from 'src/app/services/history-match.service';
import { HistoryMatch } from 'src/app/models/history-match.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { AbbreviationModel } from '../../../models/abbreviation.model';
import { SendWhatsappDialogComponent } from '../../common/send-whatsapp-dialog/send-whatsapp-dialog.component';
import { ApplicantService } from 'src/app/services/applicant.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-match-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class MatchHistoryComponent implements OnInit {
  @Input() applierId: number;
  @Input() matchId: number;
  // @Input() data: any;
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() refreshApplier = new EventEmitter<any>();
  icn: string;
  chnlnm: string;
  showSpinner = false;
  historyForm: FormGroup;
  statis: any;
  now: any = new Date();
  status: any = [];
  channels: any = [];
  isDueDate = true;
  returnValue: string;

  historyData: any = [];
  constructor(
    private router: Router,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private historyMatchService: HistoryMatchService,
    public dialog: MatDialog,
    private applicantService: ApplicantService,
    private clipboard: Clipboard,

  ) {}

  /**
   * DEFAULT METHOD FOR ANGULAR AUTOLOADED MATHOD
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * INITIALIZE FORM WHEN PAGE ARE LOADED
   */
  initForm() {
    this.channels = new AbbreviationModel().getHistoryMatchChannel();
    Number(this.matchId);
    this.historyForm = this._formBuilder.group({
      match_id: [this.matchId],
      content: [''],
      channel: ['', Validators.required],
      status_id: ['', Validators.required],
      due_date: [''],
      costum_Date: [''],
    });
    this.getHistory();
    this.getStatus();
  }

  /**
   *
   * @param days ADD DAYS IN TODAY DATE AND SET INTO NEW DUE_DATE
   */
  updateDate(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    this.historyForm.controls['due_date'].clearValidators();
    if (days) {
      this.historyForm.patchValue({
        due_date: date,
      });
      this.isDueDate = true;
      this.historyForm.controls['due_date'].setValidators([
        Validators.required,
      ]);
    } else {
      this.historyForm.patchValue({
        due_date: null,
      });
      this.isDueDate = false;
      this.historyForm.controls['due_date'].clearValidators();
    }
  }

  /**
   * ADD MATCH HISTORY
   */
  submitHistory() {
    var newdate = new Date(this.historyForm.value.due_date);
    const due_date =
      newdate.getFullYear() +
      '-' +
      ((newdate || new Date()).getMonth() + 1) +
      '-' +
      newdate.getDate();

    var costum_dt = this.historyForm.value.costum_Date
      ? new Date(this.historyForm.value.costum_Date)
      : null;
    const costum_date = costum_dt
      ? costum_dt.getFullYear() +
        '-' +
        ((costum_dt || new Date()).getMonth() + 1) +
        '-' +
        costum_dt.getDate()
      : null;

    if (this.historyForm.valid == true) {
      const postData = new HistoryMatch(
        this.historyForm.value.content,
        Number(this.matchId),
        this.historyForm.value.status_id,
        this.historyForm.value.channel,
        this.isDueDate ? due_date : null,
        costum_date ? costum_date : null
      );
      this.showSpinner = true;
      this.historyMatchService.addHistoryMatchData(postData).subscribe(
        (response: any) => {
          this.getHistory();
          this.refreshApplier.emit();
          this.historyForm.reset();
          this.update.emit(response);
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  /**
   * GET MATCH HISTORY RESULT
   */
  getHistory() {
    if (
      !isNaN(this.matchId) &&
      this?.matchId &&
      typeof this?.matchId !== 'undefined'
    ) {
      this.showSpinner = true;
      this.historyMatchService.getHistoryMatchData(this.matchId).subscribe(
        (response: any) => {
          this.historyData = response;
          this.showSpinner = false;
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  /**
   * GET STATUS USING API
   */
  getStatus() {
    if (this.status?.length == 0) {
      this.showSpinner = true;
      this.historyMatchService.getHistoryMatchStatus().subscribe(
        (response: any) => {
          this.status = response?.results;
        },
        (errorRes) => {
          this._snackBar.open(errorRes, 'OK', { duration: 5000 });
        }
      );
    }
  }

  /**
   *
   * @param icon SET
   * @param name
   */
  changeIconName(icon, name) {
    this.icn = icon;
    this.chnlnm = name;
  }

  /**
   *
   * @param id  DELETE SELECETED HISTORY RECORD bY ID
   */
  delete(id) {
    // delete the posting
    // refresh match history
    this.showSpinner = true;
    this.historyMatchService.deleteMatchHistory(id).subscribe(
      (response: any) => {
        this.getHistory();
        this._snackBar.open(
          this.translate.instant('match-history.delete_record'),
          'OK',
          { duration: 5000 }
        );
        this.showSpinner = false;
      },
      (errorRes) => {
        this.showSpinner = false;
        this._snackBar.open(errorRes, 'OK', { duration: 5000 });
      }
    );
  }

  sendMatches(
    channel_id: number,
    applier_id: number,
    applier_phone: string,
    status_id: number
    ) {
    let postData = {
      match_ids: [],
      status: null,
      note: '',
      note_bool: false,
      applier_id: applier_id,
      channel: channel_id === 7 ? 'whatsapp' : channel_id === 8 ? 'mail' : '',
      status_id: status_id,
      model_name:"StatusMatchItem"
    };

    this.applicantService.sendMatches(applier_id, postData).subscribe(
      (res) => {
        this.returnValue = '';
        if (channel_id === 7 && res['output']) {
          this.returnValue = res['output'];
          this.addCompanyDialog(channel_id, applier_phone);
          this.clipboard.copy(this.returnValue);
        }
        const snakMessage =
          channel_id === 7 && res['output']
            ? this.translate.instant('message.copied_to_clipboard')
            : channel_id === 8
            ? this.translate.instant('message.email_sent')
            : 'enthalten nicht verfügbar';
        this._snackBar.open(snakMessage, '', {
          duration: 5000,
        });
      },
      (error) => {
        if (error !== null && error !== 'undefined') {
          this._snackBar.open(error.replace('message', ''), '', {
            duration: 5000,
          });
        } else {
          this._snackBar.open(
            this.translate.instant('message.something_wrong'),
            '',
            { duration: 5000 }
          );
        }
        // this.buttonDisable = false;
      }
    );
  }
  addCompanyDialog(channel_id: number, applier_phone: string) {
    const dialogRef = this.dialog.open(SendWhatsappDialogComponent, {
      data: {
        returnValue: this.returnValue,
        applier_phone: applier_phone,
        checkStatus: channel_id,
      },
      panelClass: 'myClass',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  clearDate(event) {
    event.stopPropagation();
    // this.historyForm.value.costum_Date = null;
    this.historyForm.patchValue({ costum_Date: null });
  }
}
