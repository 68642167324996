import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpandableRowComponent } from '../../home/expandable-row/expandable-row.component';
import { DialogData } from '../company-sidebar/companysidebar.component';

@Component({
  selector: 'app-send-whatsapp-dialog',
  templateUrl: './send-whatsapp-dialog.component.html',
  styleUrls: ['./send-whatsapp-dialog.component.scss']
})
export class SendWhatsappDialogComponent implements OnInit {
  returnValue: string;
  applier_phone: any;
  encoded_value: any;
  cols: number;
  rows: number;
  checkStatus: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ExpandableRowComponent>
  ) {
    this.returnValue = data['returnValue'];
    this.applier_phone = data['applier_phone']
    this.checkStatus = data['checkStatus']
  }
  
  ngOnInit() {
    if(+this.checkStatus === 7){
    this.cols = 15;
    this.rows = 10;
    }
    else{
      this.cols = 90;
      this.rows = 25;
    }


  }

  afterClosed($event): void {}

  onNoClick() {
    this.dialogRef.close();
  }
  encode(name) {
    return encodeURIComponent(name);
  }
}
