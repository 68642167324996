import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HistoryMatchService } from 'src/app/services/history-match.service';

@Component({
  selector: 'app-add-label',
  templateUrl: './add-label.component.html',
  styleUrls: ['./add-label.component.scss'],
})
export class AddLabelComponent implements OnInit {
  labelData = [];
  selectLable: any;
  selectMatchLable: [];
  selectedLable: [];
  select: [];
  checkValueChange: boolean = false;
  checkLabelChange: boolean = true;
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  @Input() matchId: number;

  constructor(private historyMatchService: HistoryMatchService) {}

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };


    this.getHistoryMatchLabel();
  }

  getHistoryMatchLabel() {
    this.historyMatchService.getHistoryMatchLabel().subscribe((res: any) => {
      let labels = [];
      res.results.forEach((label) =>
        labels.push({
          item_id: label.id,
          item_text: label.label,
        })
      );
      this.labelData = labels;
    });
  }

  queryBy(selectLable) {
    

 this.selectedLable = selectLable ? selectLable.map((x1) => x1.item_id) : '';
 this.checkLabelChange = false;
 if (this.selectLable) {
   if(selectLable !== this.select){
     this.checkLabelChange = true;
    }
    else if(selectLable === this.select){
      this.checkLabelChange = false;
      }
    }
    this.select = selectLable
  }

  updatelabel() {
    this.checkValueChange = true;
    var data = {
      label: this.selectedLable,
    };
    this.historyMatchService
      .updateHistoryMatchlabel(this.matchId, data)
      .subscribe((res: any) => {
        if (res) {
          this.checkValueChange = false;
          this.checkLabelChange = false;
        } else {
          this.checkValueChange = true;
        }
      });
  }
}
