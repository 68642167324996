import {
  Component,
  Input,
  EventEmitter,
  ViewChild,
  OnInit,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { JobService } from 'src/app/services/job.service';
import { Subscription } from 'rxjs';
import {
  ActivatedRoute,
  Router,
  RouterEvent,
  NavigationEnd,
} from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSidenav } from '@angular/material/sidenav';
import { PaginationDataSource } from 'ngx-pagination-data-source';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbbreviationModel } from '../../models/abbreviation.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PostingsService } from 'src/app/services/postings.service';
import { TranslateService } from '@ngx-translate/core';
import { JobDeleteDialogComponent } from '../common/job-delete-dialog/job-delete-dialog.component';

@Component({
  selector: 'app-job-home',
  templateUrl: './job-home.component.html',
  styleUrls: ['./job-home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state(
        'expanded',
        style({ height: '*', overflow: 'visible', padding: '0px 0px 10px' })
      ),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class JobHomeComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  companies = [];
  ShowFilter: boolean = false;
  companyId: number;
  jobId: number;
  tabId: number;
  isCopy: number;
  postcode: number;
  isJobUpdated: any;
  currentUrl: string;
  previousUrl: string;
  jobCounts = 0;
  isCompanyView = true;
  isJobView = false;
  isNewCompany = false;
  isNewJob = false;
  isDublicate: boolean = false;
  parentCompanyId: 0;
  isActiveJobs = false;
  isActiveproximity = false;
  isAddressActive = false;
  isEduAsstShow = false;
  statusData: any;
  lat: any = '';
  lng: any = '';
  options = {
    types: ['geocode'],
    componentRestrictions: {
      country: ['de'],
    },
  };
  // sidebar and tab stuff
  @ViewChild('drawer') drawer: MatSidenav;
  selectedTab = new SelectionModel(false, [0]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // add the datasource
  sorting_order: null;
  search: null;
  curentPage: 0;
  columnsAll = [
    { key: 'companyname', name: 'Unternehmensname' },
    { key: 'city', name: 'Stadt' },
    { key: 'education', name: 'Ausbildung' },
    { key: 'continuing_education', name: 'Weiterbildungen' },
    { key: 'caretype', name: 'Pflegeart' },
    { key: 'shifthour', name: 'Schichten' },
    { key: 'scope', name: 'Arbeitsumfang' },
    { key: 'created', name: 'Erstellt' },
    { key: 'updated', name: 'Aktualisiert' },
    { key: 'action', name: 'Actionsarea' },
  ];
  columnsToDisplay = [
    'companyname',
    'city',
    'education',
    'continuing_education',
    'caretype',
    'shifthour',
    'scope',
    'created',
    'updated',
    'action',
  ];
  dataSource = new PaginationDataSource(
    (request, query) => this.jobService.pageV2(request, query),
    { property: '' as keyof Sortable, order: 'desc' },
    {
      companies: '',
      search: '',
      shift: '',
      care_type: '',
      agent_user: '',
      working_type: '',
      education_assistant: '',
      education_type: '',
      continuing_education: '',
      active: '',
      radius_search: '',
      latitude: '',
      longitude: '',
    },
    2
  );
  dropdownSettings: IDropdownSettings;
  singleSelectionDropdownSettings: IDropdownSettings;
  dropdownCompanies = [];
  companiesList = [];
  dropdownShiftHoursList = [];
  selectedShiftHoursItems = [];
  dropdownCareTypeList = [];
  dropdownEducationAssistance = [];
  selectedCareTypeItems = [];
  scopeList = [];
  selectedCompanies = [];
  educationList = [];
  contiEducationList = [];
  dropdownSpecificationJobCareTypeList = [];
  specialRequirementJobTypeList = [];
  selectedScopeItems = [];
  selectedEducationItems = [];
  selectedContiEduItems = [];
  selectedEducationAssistance = [];
  specificationCareItems = [];
  specialRequirementJobTypeItems = [];
  dropdownAgentList = [];
  selectedAgent = [];
  companyAgent = [];
  postings = [];

  constructor(
    private jobService: JobService,
    private _snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private postingsService: PostingsService,
    private translate: TranslateService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // sorting the material table
  sortBy({ active, direction }: Sort) {
    this.dataSource.sortBy({
      property: active as keyof Sortable,
      order: direction || 'asc',
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((params) => {
        this.companyId = +params['id'];
        this.jobId = +params['jobId'];
        this.tabId = +params['tabId'];
        this.isCopy = +params['isCopy'];
        this.postcode = +(params['postcode']?.length == 4)
          ? '0' + params['postcode']
          : params['postcode'];
        this.isNewCompany = false;
        this.isNewJob = false;
      })
    );
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      searchPlaceholderText: 'Suche',
      selectAllText: 'Alle ausw�hlen',
      unSelectAllText: 'kein Filter',
      itemsShowLimit: 20,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
    };

    this.singleSelectionDropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
    };
    this.getCompanyUser();
    this.initDropDownData();
    this.getAgentList();
    // this.getPostingDataForJob();
  }

  initDropDownData() {
    const ShiftHoursList = new AbbreviationModel().getList('shiftHours');
    let hoursArr = [];
    for (let hours of ShiftHoursList) {
      let hoursObj = {
        item_id: hours.id,
        item_text: hours.fields.short_name,
      };
      hoursArr.push(hoursObj);
    }
    this.dropdownShiftHoursList = hoursArr;

    const careTypeList = new AbbreviationModel().getList('careType');
    let careArr = [];
    for (let care of careTypeList) {
      let careObj = {
        item_id: care.id,
        item_text: care.fields.short_name,
      };
      careArr.push(careObj);
    }
    this.dropdownCareTypeList = careArr;

    const educationTypeList = new AbbreviationModel().getList('educationType');
    let eduArr = [];
    for (let edu of educationTypeList) {
      let eduObj = {
        item_id: edu.id,
        item_text: edu.fields.short_name,
      };
      eduArr.push(eduObj);
    }
    this.educationList = eduArr;

    const conttEduTypeList = new AbbreviationModel().getList('continuingEdu.');
    let contiArr = [];
    for (let ce of conttEduTypeList) {
      if(ce.id <= 13){
      let ceObj = {
        item_id: ce.id,
        item_text: ce.fields.short_name,
      };
      contiArr.push(ceObj);
    }
    }
    this.contiEducationList = contiArr;

    const scpList = new AbbreviationModel().getList('scope');
    let scpArr = [];
    for (let scp of scpList) {
      let scpObj = {
        item_id: scp.id,
        item_text: scp.fields.short_name,
      };
      scpArr.push(scpObj);
    }
    this.scopeList = scpArr;

    const specificationList =
      new AbbreviationModel().getList('specification');
    let speciArr = [];
    for (let speci of specificationList) {
      let speciObj = {
        item_id: speci.id,
        item_text: speci.fields.short_name,
      };
      speciArr.push(speciObj);
    }
    this.dropdownSpecificationJobCareTypeList = speciArr;

    const educationAssistanct =
      new AbbreviationModel().getList('eduAssistance');
    let eaArr = [];
    for (let ea of educationAssistanct) {
      let eaObj = {
        item_id: Number(ea.id),
        item_text: ea.fields.short_name,
      };
      eaArr.push(eaObj);
    }
    this.dropdownEducationAssistance = eaArr;

    const spReqList =
      new AbbreviationModel().getList('specialJobType');
    let spReqArr = [];
    for (let spReq of spReqList) {
      let spReqObj = {
        item_id: spReq.id,
        item_text: spReq.fields.short_name,
      };
      spReqArr.push(spReqObj);
    }
    this.specialRequirementJobTypeList = spReqArr;
  }

  getAgentList() {
    this.dropdownAgentList = [];
    this.jobService.getAgent(false).subscribe((res: any) => {
      this.companyAgent = res;
      for (let agent of this.companyAgent) {
        this.dropdownAgentList.push({
          item_id: agent.id,
          item_text: agent.first_name + ' ' + agent.last_name,
        });
      }
      this.dropdownAgentList = new Array(this.dropdownAgentList);
    });
  }

  searchFilterData(val) {
    const companyId = val.map((x) => x.item_id);
    const searchVal = this.search !== undefined ? this.search : '';
    const shift =
      this.selectedShiftHoursItems.length > 0
        ? this.selectedShiftHoursItems.map((x1) => x1.item_id).toString()
        : '';
    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.pageV2(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        companies: companyId,
        search: searchVal,
        shift: shift,
        care_type: '',
        agent_user: '',
        working_type: '',
        education_assistant: '',
        education_type: '',
        continuing_education: '',
        active: '',
        radius_search: '',
        latitude: '',
        longitude: '',
      },
      2
    );
  }

  filterJob() {
    const searchVal = this.search !== undefined ? this.search : '';
    const companyId =
      this.selectedCompanies.length > 0
        ? this.selectedCompanies.map((x1) => x1.item_id)
        : '';
    const shift =
      this.selectedShiftHoursItems.length > 0
        ? this.selectedShiftHoursItems.map((x1) => x1.item_id)
        : '';
    const education =
      this.selectedEducationItems.length > 0
        ? this.selectedEducationItems.map((x1) => x1.item_id)
        : '';
    const working_type =
      this.selectedScopeItems.length > 0
        ? this.selectedScopeItems.map((x1) => x1.item_id)
        : '';

    const education_assistant =
      this.selectedEducationAssistance.length > 0 &&
      this.selectedEducationItems[0]?.item_id == 4
        ? this.selectedEducationAssistance.map((x1) => x1.item_id)
        : '';
    const care_type =
      this.selectedCareTypeItems.length > 0
        ? this.selectedCareTypeItems.map((x1) => x1.item_id)
        : '';
    const agent_user =
      this.selectedAgent.length > 0
        ? this.selectedAgent.map((x1) => x1.item_id)
        : '';

    const continuing_education =
      this.selectedAgent.length > 0
        ? this.selectedContiEduItems.map((x1) => x1.item_id)
        : '';


    this.dataSource = new PaginationDataSource(
      (request, query) => this.jobService.pageV2(request, query),
      { property: '' as keyof Sortable, order: 'desc' },
      {
        companies: companyId,
        search: searchVal,
        shift: shift,
        care_type: care_type,
        agent_user: agent_user,
        working_type: working_type,
        education_assistant: education_assistant,
        education_type: education,
        continuing_education: continuing_education,
        active: '',
        radius_search: '',
        latitude: '',
        longitude: '',
      },
      2
    );
  }

  handlePage(event) {
    this.curentPage = event.pageIndex == 0 ? 1 : event.pageIndex;
  }

  getCompanyUser() {
    this.jobService.getCompanies().subscribe((res: any) => {
      let Companies = [];
      res.forEach((company) =>
        Companies.push({ item_id: company.id, item_text: company.name })
      );
      this.dropdownCompanies = Companies;
      this.companiesList = Companies;
    });
  }

  getActiveJobs() {
    if (!this.isActiveJobs) {
      this.dataSource.queryBy({ active: '' });
    } else {
      this.dataSource.queryBy({ active: 'true' });
    }
  }

  getActiveProximity() {
    if (!this.isActiveproximity) {
      this.dataSource.queryBy({ radius_search: '' });
      this.dataSource.queryBy({ latitude: '', longitude: '' });
      this.isAddressActive = false;
    } else {
      this.dataSource.queryBy({ radius_search: 'true' });
      this.isAddressActive = true;
    }
  }

  AddressChange(address: any) {
    // this.searchString = address?.target?.value;
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    if (!this.isAddressActive) {
      this.dataSource.queryBy({ latitude: '', longitude: '' });
    } else {
      this.dataSource.queryBy({ latitude: this.lat, longitude: this.lng });
    }
  }

  ngAfterViewInit() {
    if (
      (this.tabId == 0 || this.tabId) &&
      this.jobId &&
      this.companyId
      // && this.isCopy == 0
    ) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    } else if (this.isCopy == 1) {
      this.edit(
        this.companyId,
        this.jobId,
        this.tabId,
        this.isCopy,
        this.postcode
      );
    }
    this.cdr.detectChanges();
  }

  startClose() {
    this.location.go('/jobs');
  }

  update($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    // this.drawer.close();
  }

  close($event) {
    this.drawer.close();
  }

  updateAndClose($event) {
    this.dataSource.fetch(this.paginator.pageIndex);
    this.drawer.close();
    this.getCompanyUser();
  }

  // add job create and edit stuff
  addNewJob(companyid) {
    this.selectedTab.select(0);
    this.companiesList = this.dropdownCompanies;
    this.drawer.open();
    this.isDublicate = false;
    this.isNewJob = true;
    this.isNewCompany = false;
  }

  // add company create and edit stuff
  addNewCompany() {
    this.drawer.open();
    this.selectedTab.select(1);
    this.isDublicate = false;
    this.isNewCompany = true;
    this.isNewJob = false;
  }

  //edit sidebar stuff
  edit(companyid, jobid, tabid, isCopy, postcode) {
    this.selectedTab.select(tabid);
    this.isDublicate = isCopy == 0 ? false : true;
    this.companyId = companyid;
    this.postcode = postcode;
    this.jobId = jobid;
    this.isNewJob = false;
    this.isNewCompany = false;
    this.location.go('/jobs/' + companyid + '/' + jobid + '/' + tabid);
    this.drawer.open();
  }

  checkIsJobUpdated($event) {
    this.getCompanyUser();
    this.isJobUpdated = $event;
  }

  /* set job status active or inactive */
  updateJobStatus(jobId, status) {
    this.statusData = null;
    if (status === 'delete') {
      this.statusData = 3;
    } else if (status.checked) {
      this.statusData = 1;
    } else {
      this.statusData = 2;
    }

    if (this.statusData) {
      this.jobService
        .updateJobStatus({ is_active: this.statusData }, jobId)
        .subscribe(
          (res: any) => {
            this.dataSource.fetch(this.paginator.pageIndex);
          },
          (error) => {
            this._snackBar.open(error, 'OK', { duration: 5000 });
          }
        );
    }
  }

  queryBy(by, queryNgModel) {
    var query = {}
    // this.isEduAsstShow = false;
    var x = this.selectedEducationItems.map((x1) => x1.item_id);
    if (by == 'education_type' && !x.includes(4)) {
      this.selectedEducationAssistance = [];
      this.isEduAsstShow = false;
      query['education_assistant'] = "";
    }
    else if ((by == 'education_type' && x.includes(4)) || by == 'education_assistant'){
      this.isEduAsstShow = true;
    }
    
    query[by] = (queryNgModel.length > 0 ? queryNgModel.map(x1 => x1.item_id) : '');
    this.dataSource.queryBy(query);
    
  }

  delete(jobId) {
    const dialogRef = this.dialog.open(JobDeleteDialogComponent, {
      data: { messageType: 'delete-job' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == 'you confirmed') {
        this.jobService.getJobDelete(jobId).subscribe(
          (res: any) => {
            this.dataSource.fetch(this.paginator.pageIndex);
            this._snackBar.open(
              this.translate.instant('message.job_deleted'),
              'OK',
              {
                duration: 5000,
              }
            );
            // this.statusData = 3;
            this.updateJobStatus(jobId, 'delete');
          },
          (errorRes) => {
            this._snackBar.open(errorRes, 'OK', { duration: 5000 });
          }
        );
        // this.cdr.detectChanges();
      } else {
        console.log(' cencel button ');
      }
    });
  }
}

export interface companyResponse {
  count: any;
  next: any;
  previous: any;
  results: any;
}

export interface Sortable {
  created_at: Date;
  name: any;
  location: any;
}




