<div *ngIf="historyData" class="bar">
    <h1 class="drawer-title">{{historyData.name}}</h1>
    <form [formGroup]="historyForm" (ngSubmit)="submitHistory()">
        <!-- <mat-form-field class="width100">
            <mat-label translate>history.costum_date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="costum_Date" />
           <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
        <mat-form-field class="width100">
            <mat-label translate>history.description</mat-label>
            <input matInput formControlName="content">
        </mat-form-field>
        <mat-form-field>
            <mat-label translate>history.status</mat-label>
            <mat-select formControlName="status_id">
                <mat-option *ngFor="let status of status" [value]="status.id">
                    {{status?.status_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label translate>history.channel</mat-label>
            <mat-select formControlName="channel_id" class="channel_id">
                <mat-select-trigger>
                    <mat-icon class="selected-icon">{{icn}}</mat-icon>&nbsp;{{chnlnm}}
                </mat-select-trigger>
                <mat-option (onSelectionChange)="changeIconNname(channel.icon, channel?.channel_name)"
                    *ngFor="let channel of channels" [value]="channel.id">
                    <mat-icon>{{channel.icon}}</mat-icon>
                    {{channel?.channel_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary">{{ 'history.btn_send' | translate}}</button>
    </form>
    <mat-divider></mat-divider>
    <div style="height:8px"></div>
    <mat-card *ngFor="let item of historyData; first as isFirst" [class.active]="isFirst && compare(item.due_date)">
        <div class="card-image" >
            <mat-icon>{{ item?.channel?.icon }}</mat-icon>
        </div>
        <div class="content">
            <mat-card-subtitle class="no-margin">{{ item?.agent?.first_name }} {{ item?.agent?.last_name }} | {{
                item.created_at | date:'dd.MM.yyyy hh:mm' }}</mat-card-subtitle>
            <mat-card-content class="{{ item?.content ? 'no-margin' : 'm3-margin'}}">{{ item?.status?.status_name }}
            </mat-card-content>
            <mat-card-subtitle class="no-margin">
                {{ item?.content }}
            </mat-card-subtitle>

        </div>

        <div class="card-image right content">
            <div style="display: flex;">
            <mat-icon style="margin-right: 0px;" matTooltip="{{item?.status?.whatsapp_tooltip}}" 
            (click)="sendMatches(7, item.applier.id, item?.applier?.phone_number, item?.status?.id)">whatsapp</mat-icon>
            <mat-icon style="margin-right: 0px;" matTooltip="{{item?.status?.mail_tooltip}}" 
            (click)="sendMatches(8, item.applier.id, item?.applier?.phone_number , item?.status?.id)">email</mat-icon>

            <mat-icon [matMenuTriggerFor]="menu">delete</mat-icon>
            </div>
            <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
                <!--add delete functionality-->

                <button (click)="delete(item.id)" class="btn-delete" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Löschen</span>
                </button>
            </mat-menu>
        </div>
    </mat-card>
    <ng-container *ngIf="historyData?.length == 0">
        <p class="text-center"><b>{{ 'message.no_record' | translate }}</b></p>
    </ng-container>
</div>
<ng-container *ngIf="!historyData">
    <ngx-skeleton-loader count="2" appearance="line" [theme]="{'height.px': 100}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="5" appearance="line" [theme]="{'height.px': 50}"></ngx-skeleton-loader>
  </ng-container>
